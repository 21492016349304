import React from 'react';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: "0",
	background: "transparent",
	borderRadius: "16px",
	display: "block",
	position: "relative",
	overflow: "hidden"
}));

export const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ExpandMoreIcon sx={{ fontSize: '2.5rem', fontWeight: "400"}}/>}
		{...props}
	/>
))(({theme, expanded}) => ({
	overflow: "hidden",
	padding: "0.6rem 1.5rem",
	background: "var(--theme-octonary)",
	borderTopLeftRadius: "16px",
	borderTopRightRadius: "16px",
	borderBottomLeftRadius: expanded ? 0 : "16px",
	borderBottomRightRadius: expanded ? 0 : "16px",
	transition: expanded ? "none" : "border-radius 0.2s ease-in-out 0.2s",
}));

export const AccordionDetails = styled((props) => (
	<MuiAccordionDetails {...props} />
))(({ theme }) => ({
	padding: "1rem 2.3rem",
	background: "white",
	borderBottom: '1px solid rgba(0, 0, 0, .125)',
	'&:last-child': {
		borderBottom: 0,
	},
}));