import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { isEmpty } from "../helpers/Functions";
import LoginInit from "./LoginInit";
import ReviewPage from "./ReviewPage";
import GPTLogin from "./GPTLogin";
import EventPortalPage from "./EventPortalPage";
import CustomEmailApproval from "./CustomEmailApproval";

const SplashScreen = lazy(() => import("./SplashScreen"));
const QuickAccessPage = lazy(() => import("./QuickAccessPage"));

const Login = lazy(() => import("./Login"));
const ErrorPage = lazy(() => import("./ErrorPage"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const LibraryAccessApproval = lazy(() => import("./LibraryAccessApproval"));
const DashboardRoutes = lazy(() => import("./dashboard/DashboardRoutes"));
const AdminPanelRoutes = lazy(() => import("./admin-panel/AdminPanelRoutes"));
const CertificatePage = lazy(() => import("./CertificatePage"));
const WaitingArea = lazy(() => import("./WaitingArea"));
const ZoomRoom = lazy(() => import("./ZoomRoom"));

export const AppRoutes = ({ ...props }) => {
	const isAuthorized = useSelector(({auth}) => !isEmpty(auth.token));
	const isSuperAdmin = useSelector(({auth}) => auth.is_super_admin === 1);

	let payload = {
		meetingNumber : "",
		role : 0,
		sdkKey : "",
		sdkSecret : "",
		passWord : "",
		userName : "Testing",
		userEmail : "",
		leaveURL : '/me'
	}

	return (
		<Suspense fallback={<SplashScreen/>}>
			<Routes>
				<Route path="/" element={ <Navigate to="/dashboard" /> } />
				<Route path="/error" element={ <ErrorPage /> } />
				<Route path="/login" element={ <Login /> } />
				<Route path="/gpt-login/:library_code" element={ <GPTLogin /> } />
				<Route path="/reset-password" element={ <ResetPassword /> } />
				<Route path="/reset-password/:email/:email_token" element={ <ResetPassword /> } />
				<Route path="/approve-library-access-from-email/:account_party_id/:library_party_id/:credit_request" element={ <LibraryAccessApproval /> } />
				<Route path="/me" element={ <QuickAccessPage /> } />
				<Route path="/feedback" element={ <ReviewPage /> } />
				<Route path="/events/:library_code/:access_code" element={ <WaitingArea /> } />
				<Route path="/events/" element={ <EventPortalPage /> } />
				<Route path="/me/:access_code" element={ <QuickAccessPage /> } />
				<Route path='/certificate/:user_certificate_id/' element={ <CertificatePage/> } />
				<Route path='/zoom_room/:role' element={ <ZoomRoom/> } />
				<Route path='/custom_email/:custom_email_id' element={ <CustomEmailApproval/> } />

				{
					!isAuthorized && (
						['dashboard/*', 'admin-panel/*'].map((path, i) =>
							<Route key={i} path={ path } element={ <Navigate to="/login" /> } />
						)
					)
				}

				<Route path="/init" element={ <LoginInit /> } />

				<Route element={<props.Layout />}>
					<Route path="/dashboard/*" element={ <DashboardRoutes /> } />
					{
						isSuperAdmin && (
							<Route path="/admin-panel/*" element={ <AdminPanelRoutes /> } />
						)
					}
				</Route>

				<Route path="*" element={ <Navigate to="/error" /> } />
			</Routes>
		</Suspense>
	)
}