import React, { useEffect, useState } from "react";

import { setAI } from "../../../redux/slices/dashboardSlice";
import { connect } from "react-redux";
import AIVariation from "./text_types/AIVariation";
import TextVariation from "./text_types/TextVariation";
import BaseCheckbox from "../../../../theme/partials/BaseCheckbox";
import BaseTextField from "../../../../theme/partials/BaseTextField";
import { extractValidNumber } from "../../../../helpers/Functions";

const TextForm = ({ setAI, ...props }) => {
	function numberToBool(value) {
		return value == 1 ? true : false;
	}
	
	function changeStatus(value) {
		return value == 1 ? 0 : 1;
	}

	return(<>
		<BaseTextField
			title="Points"
			value={props.values?.points}
			onChange={(e) => {
				const value = e.target.value;
				const point = extractValidNumber(value);

				props.setFieldValue("points", point);
			}}
		/>

		{props.values.text_type == "text_ai" && (
			<AIVariation {...props } />
		)}

		{props.values.text_type == "text_variation" && (
			<TextVariation 
				setFieldValue={props.setFieldValue}
				values={props.values}
			/>
		)}
		<div className="d-block">
			<BaseCheckbox
				title="Student can Email the Text"
				name="text_mailable"
				value={numberToBool(props.values.text_mailable)}
				checked={numberToBool(props.values.text_mailable)}
				onChange={
					(e) => {
						props.setFieldValue("text_mailable", changeStatus(props.values.text_mailable))
					}
				}
				onBlur={props.handleBlur}
				color="primary"
			/>
		</div>
	</>)
}

const mapStateToProps = (state) => {
	return {
		modules: state.dashboard?.libraries?.selected_library?.modules,
		ai: state.dashboard?.ai
	}
}

const mapDispatchToProps = {
	setAI
}

export default connect(mapStateToProps, mapDispatchToProps)(TextForm);