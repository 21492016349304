import React, { useRef, useState } from "react";
import io from "socket.io-client";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import { connect } from "react-redux";
import Tooltip from '@mui/material/Tooltip';

import LessonAccordion from "./LessonAccordion";
import TextView from "../../Typography";
import { Doughnut } from 'react-chartjs-2';
import BaseTextField from "../../BaseTextField";
import BaseButton from "../../BaseButton";
import BaseCheckbox from "../../BaseCheckbox";
import { isNotStudent, useLoading } from "../../../../helpers/Utils";
import { getSurveyExplanations, getSurveyResults, quickSubmitElementResponse, submitStudentElementResponse } from "../../../../app/crud/element.crud";
import displayToast from "../../DisplayToast";
import { generateRandomId, isEmpty, isPageQuickAccess } from "../../../../helpers/Functions";
import { Box, IconButton, InputLabel, Toolbar } from "@mui/material";
import BaseProgressBar from "../../BaseProgressBar"
import { useEffect } from "react";

import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SortIcon from '@mui/icons-material/Sort';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { useLocation } from 'react-router-dom';
import { ConversationStarterCard } from "./ReflectionElement";

const ContentSurvey = ({answerSent, isQuickAccess = false, submitLoading = false, ...props}) => {
	const INITIAL_EXPLANATIONS_COUNT = 5;

	const [resultData, setResultData] = useState([]);
	const [studentExplanations, setStudentExplanations] = useState([]);
	const [showAllExplanations, setShowAllExplanations] = useState(true);
	const [requireExplanation, setRequireExplanation] = useState(false);
	const [showExplanationForm, setShowExplanationForm] = useState(false);
	const [showNext, setShowNext] = useState(false);
	const [showSubmit, setShowSubmit] = useState(false);
	const [totalExplanations, setTotalExplanations] = useState(0);

	const enableAddInitialValues = props?.element?.context.initial_value ?? false;
	const multiTypeInitialValues = props?.element?.context?.initial_values ?? [];

	useEffect(() => {
		let reqExplanation = props.element?.context?.require_explanation;

		setRequireExplanation(reqExplanation);
		setShowSubmit(!reqExplanation);

		if(!isNotStudent(props.currentCapabilities.role) && reqExplanation && answerSent) {
			loadExplanations(INITIAL_EXPLANATIONS_COUNT);
		}
	}, [props.element, answerSent]);

	useEffect(() => {
		let answerLength = props?.answer?.length ?? 0;
		let requiredAnswerLength = props.element?.context?.choice_limit ?? 0;
		
		setShowNext(
			!isEmpty(props.answer) && 
			(
				(props.element.context?.survey_type == 'multi' && answerLength >= requiredAnswerLength) || 
				props.element.context?.survey_type == 'single'
			)
		);
	}, [props.answer, answerSent]);

	useEffect(() => {
		if(!isEmpty(props?.surveyResult)) {
			calculatePercentages(props?.surveyResult?.attachments[0].attachment_additionals, props.dummyRespondents, "responses", props?.surveyResult?.attachments[0].context);
		}
	}, [props.surveyResult, answerSent]);

	const checkIfAnswer = (value) => {	
		const matches = props.answer?.map(item => item.id == value);
		const isMatch = matches.includes(true);

		return isMatch
	}

	function calculatePercentages(data, initialrespondent, propertyToSum, context) {
		let percentages = []
		// respondendts count for single type survey
		let respondents = enableAddInitialValues ? 
			initialrespondent + context.number_of_respondent 
			: context.number_of_respondent;

		if(context.survey_type == "multi"){
			// respondents count for multi type is from each choice
			respondents = data?.reduce((accumulator, currVal) => accumulator + (currVal[propertyToSum] ?? 0), 0);

			if(enableAddInitialValues) {
				respondents += initialrespondent;
			}

			percentages = data.map((item, i) => {
				let percentage = 0;
				let itemRespondentCount = parseInt(item[propertyToSum] ?? 0);
				let itemInitialCount = parseInt(multiTypeInitialValues[i] ?? 0);

				if(enableAddInitialValues) {
					itemRespondentCount += itemInitialCount;
				}

				if(itemRespondentCount > 0 && respondents > 0) {
					percentage = (itemRespondentCount / respondents) * 100;
					percentage = Math.round(percentage);
				}

				return {
					...item,
					name: item?.name,
					percentage: percentage,
					initial_count: itemInitialCount,
				}
			});

			setResultData(percentages);
		}
		else{
			const total = enableAddInitialValues ? 
			data.reduce((acc, obj) => acc + obj[propertyToSum], 0) + initialrespondent :
			data.reduce((acc, obj) => acc + obj[propertyToSum], 0);

			let percentages = data.map((item) => {
				let valueToSum = enableAddInitialValues
					? parseInt(item[propertyToSum], 10) + parseInt(item.context, 10)
					: parseInt(item[propertyToSum], 10);
				
				// Calculate the exact percentage
				return {
					...item,
					name: item.name,
					percentage: (valueToSum / total) * 100,
					initial_count: parseInt(item.context) ?? 0,
				};
			});

			setResultData(percentages);
		}
	}

	const loadExplanations = (pageSize=5) => {
		getSurveyExplanations(props?.element?.element_id, {
			page_size: pageSize,
		}).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				let newStudentExplanations = response.data?.data ?? [];
				let totalData = response.data?.init?.[0]?.pagination?.total;

				setStudentExplanations(newStudentExplanations);
				setTotalExplanations(totalData);
			}
		});
	}

	const targetItems = answerSent ? resultData : props.element?.additional_attachments;

	return (
		<div className="mt-2 d-flex flex-column">
			<TextView fontsize="primary" fontweight="regular" customclasses="mb-4">
				{` 
					${props.element?.description} 
					${(props.element?.context?.survey_type == "multi") ? 
						`- Select at least ${props.element.context.choice_limit} choice(s)` : ""
					}
				`} 
			</TextView>
			{answerSent && (
				<div className="d-flex align-items-center survey-shrink">
					{(!isNotStudent(props?.currentCapabilities?.role)) && (
						<div className="d-flex">
							<TextView customclasses="ms-2">Thanks for answering the survey</TextView>
						</div>
					)}
					<div className="ms-auto d-block font-size-small">
						<Tooltip title="Sort Alphabetically" placement="top">
							<IconButton 
								color="primary"
								onClick={(e) => {
									const sortedData = resultData?.slice().sort((a, b) => {
										return a.description.localeCompare(b.description, undefined, { sensitivity: 'base' });
									});
							
									setResultData(sortedData);
								}}
							>
								<SortByAlphaIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Sort by Points" placement="top">
							<IconButton 
								color="primary"
								onClick={(e) => {
									const sortedData = resultData?.slice().sort((a, b) => {
										return (b.responses + b.initial_count) - (a.responses + a.initial_count);
									});
									
									setResultData(sortedData);
								}}
							>
								<TrendingUpIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Answers on Top" placement="top">
							<IconButton 
								color="primary"
								onClick={(e) => {
									const sort = resultData?.slice().sort((a, b) => {
										const aInArray = props.answer.some(item => item.id == a.id);
										const bInArray = props.answer.some(item => item.id == b.id);

										if (aInArray && !bInArray) {
											return -1;
										} 
										else if (!aInArray && bInArray) {
											return 1; 
										} 
										else {
											return 0;
										}
									});
									setResultData(sort)
								}}
							>
								<SortIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Refresh" placement="top">
							<IconButton 
								className="color-green"
								onClick={(e) => {
									props.refreshSurveyResults(e);
								}}
							>
								<RefreshIcon />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			)}
			<>
				{(props.element.context?.survey_type == "single") && (
					<RadioGroup
						name="radio-buttons-group"
						onChange={(e) => {
							const choiceRadio = {
								id : e.target.value,
								value : props.element?.additional_attachments.find((value) => value.id == parseInt(e.target.value)).description
							}

							props.setAnswer([choiceRadio])
						}}
						style={{
							rowGap: 8
						}}
					>
						{
							targetItems?.map((item, i) => {
								return (
									<div className={`survey-result-item ${answerSent ? 'disable' : ''}`} key={i}>
										{answerSent && (
											<div className="survey-result-item-progress-container">
												<div 
													className={`survey-result-item-progress ${isNotStudent(props?.currentCapabilities?.role) ? '' : (checkIfAnswer(item?.id) ? 'selected' : '')}`} 
													style={{ width: `${item?.percentage ?? 0}%` }} 
												/>
											</div>
										)}
										<FormControlLabel
											value={ item.id }
											control={(
												<Radio 
													checkedIcon={<CheckCircleIcon className="color-green" />}
												/>
											)}
											label={`${item?.description} ${(!isNotStudent(props?.currentCapabilities?.role) && checkIfAnswer(item?.id)) ? '(Your Answer)' : ''}`}
											style={{ zIndex: 10 }}
											disabled={(isNotStudent(props?.currentCapabilities?.role) && !isQuickAccess) || answerSent || (requireExplanation && showSubmit)}
											checked={checkIfAnswer(item?.id)}
										/>
										{answerSent && (
											<div className="ms-auto">
												<TextView fontsize="secondary" fontweight="regular">{parseInt(item?.percentage)}%</TextView>
												{(isNotStudent(props?.currentCapabilities?.role) && !isQuickAccess) && (
													<TextView fontsize="secondary" fontweight="regular" customclasses="ms-1 text-decoration-underline">
														({enableAddInitialValues ? item?.responses + item?.initial_count : item?.responses}) votes
													</TextView>
												)}
											</div>
										)}
									</div>
								)
							})
						}
					</RadioGroup>
				)}
				{(props.element.context?.survey_type == "multi") && (
					targetItems?.map((item, i) => {
						return (
							<div className={`survey-result-item ${answerSent ? 'disable' : ''}`} key={i} style={{ marginBottom: '8px' }}>
								{answerSent && (
									<div className="survey-result-item-progress-container">
										<div 
											className={`survey-result-item-progress ${isNotStudent(props?.currentCapabilities?.role) ? '' : (checkIfAnswer(item?.id) ? 'selected' : '')}`} 
											style={{ width: `${item?.percentage ?? 0}%` }} 
										/>
									</div>
								)}
								<BaseCheckbox 
									key={i}
									customclasses="quiz-selection font-size-medium color-green"
									className="d-block p-0 mx-2 my-2"
									title={`${item?.description} ${(!isNotStudent(props?.currentCapabilities?.role) && checkIfAnswer(item?.id)) ? '(Your Answer)' : ''}`}
									onChange={(e) => {
										if(e.target.checked) {
											const newAnswer = [...props.answer, {
												id : item.id,
												value : item.description 	
											}];
											props.setAnswer(newAnswer)
										}
										else {
											if(!isEmpty(props.answer)) {
												const newAnswer = props.answer?.filter((str) => str.id !== item.id);
												props.setAnswer(newAnswer)
											}
										}
									}}
									checkedIcon={<CheckBoxIcon className="color-green" />}
									disabled={(isNotStudent(props?.currentCapabilities?.role) && !isQuickAccess) || answerSent || (requireExplanation && showSubmit)}
									checked={checkIfAnswer(item?.id)}
								/>
								{answerSent && (
									<div className="ms-auto">
										<TextView fontsize="secondary" fontweight="regular">{parseInt(item?.percentage)}%</TextView>
										{(isNotStudent(props?.currentCapabilities?.role) && !isQuickAccess) && (
											<TextView fontsize="secondary" fontweight="regular" customclasses="ms-1 text-decoration-underline">
												({
													enableAddInitialValues ? 
													item?.responses + (multiTypeInitialValues?.[i] ?? 0) : 
													item?.responses
												}) votes
											</TextView>
										)}
									</div>
								)}
							</div>
						)
					})
				)}

				{(answerSent && props?.explanation) && (
					<div className="mt-2 d-flex flex-column">
						<TextView fontsize="xm" fontweight="medium" customclasses="ms-2 mb-1" fontcolor="secondary">Your Explanation:</TextView>
						<TextView fontsize="xm" fontweight="medium" customclasses="ms-2">{props?.explanation}</TextView>
					</div>
				)}
				{(!answerSent && showExplanationForm) &&  (
					<BaseTextField
						customclasses="my-3"
						title={
							props.element?.context?.explanation_description ? props.element?.context?.explanation_description : "Please explain your answer"
						}
						placeholder="Please explain your answer"
						onChange={(e) => {
							props.setExplanation(e.target.value)
						}}
					/>
				)}
				{(!answerSent && requireExplanation && showNext) && (
					<BaseButton 
						variant={isEmpty(props.quickAccess) ? (isNotStudent(props.currentCapabilities.role) ? "green" : "primary") : "primary"}
						customclasses="ms-auto my-2"
						onClick={() => {
							setShowSubmit(true);
							setShowExplanationForm(true);
							setShowNext(false);
						}}
					>
						Next
					</BaseButton>
				)}
				{(!answerSent && (!isNotStudent(props.currentCapabilities.role) || isQuickAccess) && showSubmit) && (
					<BaseButton 
						issubmitting={submitLoading}
						variant={isEmpty(props.quickAccess) ? (isNotStudent(props.currentCapabilities.role) ? "green" : "primary") : "primary"}
						customclasses="ms-auto my-2"
						onClick={() => {
							if(requireExplanation && isEmpty(props?.explanation)) {
								displayToast('error', 'Explanation is required.');
								return;
							}

							props.submitStudentResponse()
						}}
					>
						Submit
					</BaseButton>
				)}
				
				{((!isNotStudent(props.currentCapabilities.role) || isQuickAccess) && requireExplanation && answerSent) && (
					studentExplanations?.length > 0 ? (
						<div className="w-100 mt-4 d-flex flex-column">
							<TextView fontsize="xm" fontweight="medium" customclasses="ms-2 mb-2" fontcolor="secondary">Here's what your peers said:</TextView>
							{studentExplanations?.slice(0, showAllExplanations ? INITIAL_EXPLANATIONS_COUNT : 999)?.map((studentExplanation) => (
								<div className="mb-2" key={studentExplanation?.id}>
									<ConversationStarterCard 
										firstname={studentExplanation.firstname}
										lastname={studentExplanation.lastname}
										imagefile={studentExplanation.image_file}
										content={studentExplanation.explanation}
										withmenu={false}
									/>
								</div>
							))}
							{totalExplanations > INITIAL_EXPLANATIONS_COUNT && (
								<BaseButton
									customclasses="mx-auto mt-2"
									variant="text"
									onClick={() => {
										if(studentExplanations?.length <= INITIAL_EXPLANATIONS_COUNT) {
											loadExplanations(999);
										}

										setShowAllExplanations(!showAllExplanations);
									}}
								>
									{showAllExplanations ? 'View All' : 'Show Less'}
								</BaseButton>
							)}
						</div>
					) : (
						<TextView fontsize="xm" fontweight="medium" customclasses="ms-2 mb-1 mt-4" fontcolor="secondary">No other responses yet.</TextView>
					)
				)}

			</>
			{
				(!isNotStudent(props?.userCapabilities?.role) && props.element.last_element && answerSent) && (
					<div className="d-flex ms-2 mt-2">
						<BaseButton 
							customclasses="ms-auto"
							onClick={() => {
								props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, props.studentProgress);
							}}
						>
							Next Element
						</BaseButton>
					</div>
				)
			}
		</div>
	)
}

const SurveyElement = ({ ...props }) => {
	const [answer, setAnswer] = useState([])
	const [explanation, setExplanation] = useState("")
	const [answerSent, setAnswerSent] = useState(false)
	const [surveyResult, setSurveyResult] = useState({})
	const [dummyRespondents, setDummyRespondents] = useState({})

	const location = useLocation();
  	const isQuickAccess = isPageQuickAccess(location);

	const socket = useRef(null);
	const {
		isLoading,
		addLoading,
		finishLoading,
	} = useLoading();

	useEffect(() => {
		if(!isQuickAccess && isNotStudent(props.currentCapabilities.role) || props?.answerSent) {
			refreshSurveyResults();
		}
	}, []);

	useEffect(() => {
		let dummyRespondentsCount;

		if(props.element.context?.survey_type == "multi") {
			let initialValues = props.element?.context?.initial_values ?? [];
			dummyRespondentsCount = initialValues.reduce((accumulator, currVal) => accumulator + parseInt(currVal), 0);
		}
		else {
			dummyRespondentsCount = props.element?.additional_attachments?.reduce((accumulator, attachment) => accumulator + parseInt(attachment.context), 0);
		}

    	setDummyRespondents(dummyRespondentsCount);

		if(!isNotStudent(props.currentCapabilities.role)) {
			const responseAttachments = props.element?.attachments?.[0].student_attachment_response;

			if(!isEmpty(responseAttachments)) {
				setStudentAnswer(responseAttachments);
			}
		}
	}, [props.element])

	useEffect(() => {
		const responseAttachments = props.element?.attachments?.[0].student_attachment_response;

		if(answerSent && !isEmpty(answer) && !isEmpty(responseAttachments)) {
			refreshSurveyResults();
		}
	}, [answer, answerSent])

	useEffect(() => { 
		if (!socket.current) {
			socket.current = io(process.env.REACT_APP_WEBSOCKET, {
				withCredentials: true,
				transports: ["websocket", "polling"],
			});
	 
			socket.current.on("connect", () => {
				socket.current.emit("joinSurveyRoom", { "element": props.element, "id": generateRandomId(8) });
			});

			socket.current.on("surveyUpdate", (updatedElement) => {
				if (updatedElement?.element_id === props.element?.element_id) {
					setSurveyResult({ attachments: [updatedElement] });
				}
			});
		}
	 
		return () => {
			if (socket.current) {
				socket.current.disconnect();
				socket.current = null;
			}
		};
	 }, [props.element.element_id])

	const setStudentAnswer = (student_response) => {
		const content = JSON.parse(student_response.content)
		const remarks = JSON.parse(student_response.remarks)

		if(remarks.require_explanation){
			setExplanation(remarks.explanation_description)
		}

		setAnswer(content)
		setAnswerSent(true)
	}
	
	const [studentProgress, setStudentProgress] = useState(0)

	const submitStudentResponse = () => {
		if(
			props.element.context?.survey_type == "multi" && 
			answer.length < parseInt(props.element.context.choice_limit) && 
			(!isNotStudent(props.currentCapabilities.role) || isEmpty(props.userDetails.email))
		){
			displayToast("error", `Please select minimum of ${parseInt(props.element.context.choice_limit)} items before you submit.`)
			return
		}

		const student_response = {
			context : {
				points : props.element.context.points,
				require_explanation : props.element?.context?.require_explanation,
				explanation_description : explanation
			},
			answers: answer
		}

		let params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element?.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props?.element?.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			student_response: student_response
		}

		if(isQuickAccess && !isEmpty(props.quickAccess)){
			params.email = props.quickAccess.email;
			params.firstname = props.quickAccess.name.firstname;
			params.lastname = props.quickAccess.name.lastname;

			addLoading();

			quickSubmitElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					refreshSurveyResults();
					displayToast("success", "Survey submitted.");
				}
			}).finally(() => {
				finishLoading();
			});
		}
		else if(!isNotStudent(props.currentCapabilities.role)) {
			addLoading();

			submitStudentElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					refreshSurveyResults();
					displayToast("success", "Survey submitted.");
				}
			}).finally(() => {
				finishLoading();
			});
		}
		else {
			params = {
				element_id: props.element.element_id,
			}
	
			getSurveyResults(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					const result = {
						attachments : [
							response.data.data[0]
						]
					}
	
					setSurveyResult(result)
					setAnswerSent(true)
				}
			})
		}
	}

	const refreshSurveyResults = () => {
		let params = {
			element_id: props.element.element_id,
		}

		getSurveyResults(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				const result = {
					attachments : [
						response.data.data[0]
					]
				}

				setSurveyResult(result)
				setAnswerSent(true)
			}
		})
	}

	return (
		<>
			{
				(isQuickAccess) ? (
					<ContentSurvey 
						submitLoading={isLoading}
						element={props.element}
						currentCapabilities={props.currentCapabilities}
						surveyResult={surveyResult}
						answer={answer}
						answerSent={answerSent}
						explanation={explanation}
						refreshSurveyResults={refreshSurveyResults}
						dummyRespondents={dummyRespondents}
						setAnswer={setAnswer}
						setExplanation={setExplanation}
						submitStudentResponse={submitStudentResponse}
						quickAccess={props.quickAccess}
						isQuickAccess={isQuickAccess}
					/>
				) : (
					<LessonAccordion {...props}>
						<ContentSurvey 
							submitLoading={isLoading}
							element={props.element}
							currentCapabilities={props.currentCapabilities}
							surveyResult={surveyResult}
							answer={answer}
							answerSent={answerSent}
							explanation={explanation}
							refreshSurveyResults={refreshSurveyResults}
							dummyRespondents={dummyRespondents}
							setAnswer={setAnswer}
							setExplanation={setExplanation}
							submitStudentResponse={submitStudentResponse}
							userCapabilities={props.currentCapabilities}
							updateProgress={props.updateProgress}
							studentProgress={studentProgress}
						/>
					</LessonAccordion>
				)
			}
		</>
	)
}

const mapStateToProps = (state) => {
	let data = {
		userDetails: state.auth.user_details,
		currentCapabilities: state.auth.current_capabilities,
		quickAccess: state.quickAccess
	}

	return data;
}

export default connect(mapStateToProps)(SurveyElement);