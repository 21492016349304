import React, { useEffect, useRef, useState } from "react";

import LessonAccordion from "./LessonAccordion";
import TextView, { ElementTitle } from "../../Typography";
import { Box, Button, IconButton, InputLabel, Skeleton } from "@mui/material";
import TextFieldIconButton from "../../TextFieldIconButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BaseButton from "../../BaseButton";
import { Image, Modal } from "react-bootstrap";
import { deadlineConstantsToDate, formatDate, isEmpty, isPageQuickAccess } from "../../../../helpers/Functions";
import BaseTextField from "../../BaseTextField";
import displayToast from "../../DisplayToast";
import { isNotStudent, useMultiLoading } from "../../../../helpers/Utils";
import { connect } from "react-redux";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { getStudentActionStepsResponse, quickSubmitElementResponse, submitStudentElementResponse } from "../../../../app/crud/element.crud";
import { DEADLINE_CUSTOM, DEADLINE_TOMORROW, DEFAULT_USER_IMAGE, DIR_USER_PROFILE, DUE_DATE_SHORTCUTS, SEARCH_RESULT_COUNT, SEARCH_TIMER_DELAY } from "../../../../models/Constants";
import { Form, Formik } from "formik";
import ActionIconButton from "../../ActionIconButton";
import Datetime from "react-datetime";
import { SearchDropdown } from "../../DropDown";
import { getUsers } from "../../../../app/crud/company.crud";
import { searchLibrary } from "../../../../app/crud/library.crud";
import { components } from 'react-select';
import { createActionstep, deleteActionstep, quickCreateActionstep, quickDeleteActionstep, quickUpdateActionstep, updateActionstep } from "../../../../app/crud/actionstep.crud";
import YesNoDialog from "../../YesNoDialog";
import BaseCheckbox from "../../BaseCheckbox";
import { marked } from "marked";
import { useLocation } from "react-router-dom";

const LOADING_SUBMIT = 'submit';
const LOADING_GET_RESPONSE = 'get_response';

const CustomIndicatorSeparator = ({...props}) => {

	return (
		<components.IndicatorSeparator {...props} className="d-none" />
	)
}

const StudentSearchBar = ({ 
	companyId,
	onChange = (students) => {},
 }) => {
	const [selectedStudent, setSelectedStudent] = useState([]);
	const [prevResults, setPrevResults] = useState([]);

	let searchTimeout = null;

	const loadOption = (inputValue, callback) => {
        if (inputValue) {
			if(searchTimeout) {
				clearTimeout(searchTimeout);
			}

			searchTimeout = setTimeout(() => {
				const params = {
					results_limit: SEARCH_RESULT_COUNT,
					company_id: companyId,
					search_value: inputValue,
				};

				getUsers(params).then((response) => {
					if(response.data.init[0].status === 'ok') {
						let selectedStudentIdCached = {};
						let students = selectedStudent.map((student) => {
							selectedStudentIdCached[student.value] = student.value;
							return student;
						});

						response.data?.data[0]?.forEach((student) => {
							
							if(!selectedStudentIdCached[student.user_id]) {
								students.push({
									value: student.user_id,
									label: `${student.firstname} ${student.lastname}`,
									payload: student,
								});
							}
						});
						callback(students);
						setPrevResults([...students]);
					}
				}).finally(() => {
					searchTimeout = null;
				});
			}, SEARCH_TIMER_DELAY);
        }
        else {
            callback([]);
			setSelectedStudent([]);
        }
    }

	const CustomClearIndicator = ({children, ...props}) => {

		return (
			<span
				role="button"
				className="cursor"
				{...props.innerProps} 
				onMouseDown={(e) => {
					setSelectedStudent([]);
					setPrevResults([]);

					props.clearValue();
				}} 
			>
				<components.ClearIndicator
					{...props}
				/>
			</span>
		);
	}

	const CustomOption = ({label, data, ...props}) => {

		return (
			<components.Option {...props} className={`action-step-search-student-opt ${props.isSelected ? "action-step-search-student-opt-selected" : ""}`}>
				<Image 
					className="action-step-search-student-opt-img"
					src={data?.payload?.image_file ? process.env.REACT_APP_S3_BUCKET + DIR_USER_PROFILE + data?.payload?.image_file : DEFAULT_USER_IMAGE}
				/>
				<span className="action-step-search-student-opt-label">{label}</span>
				{props.isSelected && (
					<CheckIcon className="action-step-search-student-opt-check" />
				)}
			</components.Option>
		)
	}

	const handleChange = (selected) => {
		setSelectedStudent(selected || []);

		if(!isEmpty(onChange)) {
			onChange(selected);
		}
	};

	const CustomMultiValue = ({...props}) => {

		return null;
	}

	return (
		<SearchDropdown
			title="Assignee/s"
			placeholder="Select assignee/s"
			cacheOptions={false}
			loadOptions={loadOption}
			value={selectedStudent}
			isMulti
			hideSelectedOptions={false}
			closeMenuOnSelect={false}
			onChange={handleChange}
			defaultOptions={prevResults}
			className="mt-3 h-5 w-100"
			components={{ 
				Option: CustomOption,
				IndicatorSeparator: CustomIndicatorSeparator,
				MultiValue: CustomMultiValue,
				ClearIndicator: CustomClearIndicator,
			 }}
			styles={{
				valueContainer: (base) => ({
					...base,
					padding: "0.5rem",
					fontSize: "var(--font-size-primary)",
				})
			}}
			noOptionsMessage={({ inputValue }) => inputValue ? `No results for '${inputValue}'` : 'Search for students.'}
		/>
	)
}

const LibrarySearchBar = ({ 
	onChange = (library) => {},
 }) => {
	const [selectedLibrary, setSelectedLibrary] = useState();
	const [prevResults, setPrevResults] = useState([]);
	let searchTimeout = null;

	const loadOption = (inputValue, callback) => {
        if (inputValue) {
			if(searchTimeout) {
				clearTimeout(searchTimeout);
			}

			searchTimeout = setTimeout(() => {
				const params = {
					results_limit: SEARCH_RESULT_COUNT,
					search_value: inputValue,
				};

				searchLibrary(params).then((response) => {
					if(response.data.init[0].status === 'ok') {
						let libraries = response.data?.data[0]?.map((library) => (
							{
								value: library.party_id,
								label: library.title,
								payload: library,
							}
						));

						callback(libraries);
						setPrevResults(libraries);
					}
				}).finally(() => {
					searchTimeout = null;
				});
			}, SEARCH_TIMER_DELAY);
        }
        else {
            callback([]);
        }
    }

	const handleChange = (selected) => {
		setSelectedLibrary(selected);

		if(!isEmpty(onChange)) {
			onChange(selected);
		}
	};

	return (
		<SearchDropdown
			title="Learning Adventure (optional)"
			placeholder="Search..."
			cacheOptions
			loadOptions={loadOption}
			defaultOptions={prevResults}
			className="mt-3 h-5 w-100"
			components={{ 
				IndicatorSeparator: CustomIndicatorSeparator,	
			}}
			styles={{
				valueContainer: (base) => ({
					...base,
					padding: "0.5rem",
					fontSize: "var(--font-size-primary)",
				})
			}}
			value={selectedLibrary}
			onChange={handleChange}
			noOptionsMessage={({ inputValue }) => inputValue ? `No results for '${inputValue}'` : 'Search for Course.'}
		/>
	)
}

export const DueDateSelector = ({
	selected,
	onChange = (id, date) => {}, 
	values = [],
}) => {
	const [calendarOpen, setCalendarOpen] = useState(false);

	const onDateButtonClick = (id, dueDateAdd) => {
		const newDeadline = parseInt(dueDateAdd);
		onChange(id, deadlineConstantsToDate(newDeadline).toLocaleDateString());
	}

	return (
		<div className="d-flex gap-3">
			{values.map((val) => (
				<Button 
					key={val.id}
					className={val.id === selected ? "action-step-due-date-button-active" : "action-step-due-date-button"}
					onClick={() => onDateButtonClick(val.id, val.day_add)}
				>
					{val.label}
				</Button>
			))}
			<Button
				className={selected === DEADLINE_CUSTOM ? "action-step-due-date-button-active" : "action-step-due-date-button"}
				onClick={() => {
					setCalendarOpen(!calendarOpen);
				}}
			>
				Custom
				<Datetime
					className="color-septenary" 
					inputProps={{style: {display: "none"}}}
					timeFormat={false}
					open={calendarOpen}
					onChange={(date) => {
						let formatedDate = date.format('MM/DD/YYYY');
						onChange(DEADLINE_CUSTOM, formatedDate);
						setCalendarOpen(false);
					}}
				/>
			</Button>
		</div>
	)
}

export const ActionstepCardEditModal = ({
	show,
	setShow=false,
	actionstepData,
	elementSettings = {},
	onActionStepEditSubmit = () => {},
	onActionStepDelete = () => {},
	standAlone=false,
	companyId,
}) => {
	const [selectedDueDate, setSelectedDueDate] = useState();
	const [selectedStudents, setSelectedStudents] = useState([]);
	const [selectedLibrary, setSelectedLibrary] = useState();
	const [isStarted, setIsStarted] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false);

	useEffect(() => {
		setSelectedDueDate(DEADLINE_TOMORROW);
		setIsStarted(Boolean(actionstepData?.is_started));
		setIsCompleted(Boolean(actionstepData?.is_completed));
	}, [show]);

	const onDelete = () => {
		onActionStepDelete(actionstepData);
	}

	return (
		<Modal
			show={show}
			onHide={setShow}
			size="md" 
			centered={true}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					Edit Action Step
				</Modal.Title>
			</Modal.Header>
			<Formik
				initialValues={{
					...actionstepData,
					due_date: actionstepData?.due_date,
				}}
				validate={(values) => {
					const errors = {};
					
					if(isEmpty(values.title)) {
						errors.title = 'This field is required.';	
					}

					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(true);

					const data = {
						...values,
						actionstep_id: values.id,
					};

					onActionStepEditSubmit(data);
					setSubmitting(false);
					setShow(false);
				}}
				>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					setSubmitting,
				}) => (
					<Form onSubmit={handleSubmit}>
						<Modal.Body>
							<BaseTextField
								name="title"
								title="Action Step Title"
								placeholder="Enter action step"
								value={values.title}
								onBlur={handleBlur}
								onChange={handleChange}
								helperText={touched.title && errors.title}
								error={Boolean(touched.title && errors.title)}
							/>
							<BaseTextField 
								name="description"
								multiline
								minRows={3}
								maxRows={10}
								title="Description"
								placeholder="Enter a short description of what you want your action step to accomplish, specific instructions, and deliverables"
								value={values.description}
								onBlur={handleBlur}
								onChange={handleChange}
								helperText={touched.description && errors.description}
								error={Boolean(touched.description && errors.description)}
							/>
							{standAlone && (
								<>
									<StudentSearchBar 
										companyId={companyId}
										onChange={(students) => {
											let newStudents = students.map((stud) => stud.value);
											
											setSelectedStudents(newStudents);
										}}
									/>
									<LibrarySearchBar
										onChange={(library) => setSelectedLibrary(library.value)}
									/>
								</>
							)}
						</Modal.Body>
						<Modal.Footer>
							<BaseButton
								customclasses="me-auto"
								variant="abort"
								issubmitting={isSubmitting}
								onClick={() => onDelete(setSubmitting)}
							>
								Delete
							</BaseButton>
							<BaseButton
								type="submit"
								issubmitting={isSubmitting}
							>
								Save
							</BaseButton>
						</Modal.Footer>
					</Form>
				)}
			</Formik>
		</Modal>
	);
}

const ActionStepsNoteModal = ({
	show,
	setShow=false,
	onSave = () => {},
	title = "",
	description = "",
	withNotes = false,
	withDueDate = true,
}) => {

	return (
		<Modal
			show={show}
			onHide={setShow}
			size="md" 
			centered={true}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{title}
				</Modal.Title>
			</Modal.Header>
			<Formik
				initialValues={{
					due_date_id: null,
					due_date: '',
					notes: '',
				}}
				validate={(values) => {
					const errors = {};
					
					if(isEmpty(values.notes) && withNotes) {
						errors.notes = 'This field is required.';	
					}

					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(true);

					const data = {
						...values,
					};

					if(!withDueDate) {
						delete data?.due_date_id;
						delete data?.due_date;
					}

					onSave(data);
				}}
				>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<Form onSubmit={handleSubmit}>
						<Modal.Body>
							{(withDueDate) && (
								<>
									<TextView 
									fontweight="semibold"
									customclasses="mb-2"
								>
									Select deadline: 
									<TextView customclasses="ms-2">
										{values?.due_date}
									</TextView>
								</TextView>
								<div className="d-flex align-items-center justify-content-center w-100 mb-4">
									<DueDateSelector 
										selected={values?.due_date_id}
										values={DUE_DATE_SHORTCUTS}
										onChange={(id, date) => {
											setFieldValue('due_date_id', id);
											setFieldValue('due_date', date);
										}}
									/>
								</div>
								</>
							)}
							{withNotes && (
								<BaseTextField 
									name="notes"
									multiline
									minRows={3}
									maxRows={10}
									placeholder={description}
									value={values.notes}
									onBlur={handleBlur}
									onChange={handleChange}
									helperText={touched.notes && errors.notes}
									error={Boolean(touched.notes && errors.notes)}
								/>
							)}
						</Modal.Body>
						<Modal.Footer>
							<BaseButton
								customclasses="me-auto"
								variant="abort"
								onClick={() => setShow(false)}
							>
								Cancel
							</BaseButton>
							<BaseButton
								type="submit"
								issubmitting={isSubmitting}
							>
								Save
							</BaseButton>
						</Modal.Footer>
					</Form>
				)}
			</Formik>
		</Modal>
	);
}

const EditDate = ({
	value = null,
	onChange = () => {},
}) => {
	const [calendarOpen, setCalendarOpen] = useState(false);

	return (
		<div>
			<ActionIconButton 
				edge="end"
				customclasses="font-size-small"
				onClick={() => {
					setCalendarOpen(!calendarOpen);
				}}
				icon={<BorderColorIcon className="mr-auto color-primary font-size-medium mb-1"/>}
			/>
			<Datetime
				className="color-septenary" 
				inputProps={{style: {display: "none"}}}
				timeFormat={false}
				open={calendarOpen}
				onChange={(date) => {
					let formatedDate = date.format('MM/DD/YYYY');
					onChange(formatedDate);
					setCalendarOpen(false);
				}}
			/>
		</div>
	);
}




export const ActionstepCard = ({
	index,
	actionstepData,
	onEdit,
	elementSettings,
	internalSaveMode=true,
	withDateSelected=true,
	withStartFinishBtn=true,
	withDeadlineOptions=true,
	withEdit=true,
	askForNotes=true,
}) => {
	const [acsData, setActionStepData] = useState();
	const [selectedDueDate, setSelectedDueDate] = useState(); // {id, date}
	const [showSaveDueDateBtn, setShowSaveDueDateBtn] = useState(false);
	const [showActionStepsNoteModal, setShowActionStepsNoteModal] = useState(false);
	const [hasInitialDueDate, setHasInitialDueDate] = useState(false);
	const [isStarted, setIsStarted] = useState(false);
	const [showStartDialog, setShowStartDialog] = useState(false);
	const [completedDate, setCompletedDate] = useState();
	const [isCompletionConfirmed, setIsCompletionConfirmed] = useState(false);
	const [noteWithDueDate, setNoteWithDueDate] = useState(true);

	useEffect(() => {
		setActionStepData({...actionstepData});
		setHasInitialDueDate(!isEmpty(actionstepData?.due_date));
		setIsStarted(Boolean(actionstepData?.is_started));
		setCompletedDate(actionstepData?.context?.completed_date);
	}, [actionstepData, actionstepData.to_delete]);

	const confirmActionStepStartFinish = () => {
		let newActionData = {
			...acsData,
		};
		
		if(!isStarted) {
			newActionData.is_started = true;
			onEdit(newActionData, false);
		}
	}

	return (
		<>
			<ActionStepsNoteModal 
				show={showActionStepsNoteModal}
				setShow={setShowActionStepsNoteModal}
				title={
					askForNotes ? (
						noteWithDueDate ? 
							"Set a New Deadline" : 
							"Completion Notes"
					) : "Adjust Deadline"
				}
				description={
					noteWithDueDate ? 
						"Briefly describe how this task was completed or any relevant details (required)." : 
						"Provide a short explanation for marking this action step as complete."
				}
				withNotes={askForNotes && hasInitialDueDate}
				withDueDate={noteWithDueDate}
				onSave={(data) => {
					let newActionData = {
						...acsData,
						...data,
						due_date: isEmpty(data?.due_date) ? acsData?.due_date : data?.due_date,
					};
					
					if(isCompletionConfirmed && isStarted) {
						newActionData.is_completed = true;
					}

					setActionStepData(newActionData);

					if(hasInitialDueDate) {
						onEdit(newActionData, false);
					}

					setIsCompletionConfirmed(false);
					setShowActionStepsNoteModal(false);
				}}
			/>
			<YesNoDialog 
				message={`Are you sure you want to ${isStarted ? 'complete' : 'start'} this action step?`}
				open={showStartDialog}
				onClose={() => {
					setShowStartDialog(false);
				}}
				confirm={confirmActionStepStartFinish}
			/>
			{
				acsData && 
				<div key={index} className="w-100 mb-3 position-relative">
					<div className="d-flex">
						<TextView fontweight="medium">{index}.</TextView>
						<div>
							<div className="d-flex px-2 align-items-center">
								<TextView fontweight="medium" customclasses="me-2">{acsData.title}</TextView>
								{(withEdit && isEmpty(completedDate)) && (
									<ActionIconButton 
										edge="end"
										customclasses="font-size-small"
										onClick={() => {
											if(!isEmpty(onEdit) && !acsData.to_delete) {
												onEdit(acsData, true);
											}
											else if(acsData.to_delete) {
												acsData.to_delete = false;
												onEdit(acsData, false);
												displayToast("success", "Successfully restore action step.");
											}
										}}
										icon={<BorderColorIcon className="mr-auto color-primary font-size-medium mb-1"/>}
									/>
								)}
							</div>
							{acsData.to_delete ? (
								null
							) : (
								<div className="d-flex flex-column mt-2" style={{rowGap: '6px'}}>
									{!isEmpty(acsData.description) && (
										<>
											<TextView customclasses="ps-2" fontweight="semibold">
												Details:
											</TextView>
											<div dangerouslySetInnerHTML={{__html: marked(acsData.description)}} />
										</>
									)}
									{!isEmpty(completedDate) && (
										<TextView customclasses="ps-2" fontweight="semibold">
											Completed: <TextView fontcolor="black">{formatDate(completedDate)}</TextView>
										</TextView>
									)}
									{(withDateSelected && !isEmpty(acsData?.due_date) && isEmpty(completedDate)) && (
										<div className="d-flex">
											<TextView customclasses="ps-2 me-2 justify-content-center" fontweight="semibold">
												Deadline: <TextView>{acsData.due_date || "Not set"}</TextView>
											</TextView>
											{(hasInitialDueDate && withEdit) && (
												<ActionIconButton 
													edge="end"
													customclasses="font-size-small"
													onClick={() => {
														setShowActionStepsNoteModal(true);
													}}
													icon={<BorderColorIcon className="mr-auto color-primary font-size-medium mb-1"/>}
												/>
											)}
										</div>
									)}
								</div>
							)}
						</div>
					</div>
					{(withDeadlineOptions && !hasInitialDueDate && isEmpty(completedDate)) && (
						<div className="mt-1 ms-2">
							<TextView customclasses="mb-2">Select a new deadline:</TextView>
							<DueDateSelector 
								selected={selectedDueDate?.id}
								values={DUE_DATE_SHORTCUTS}
								onChange={(id, date) => {
									setSelectedDueDate({
										id,
										date,
									});
		
									let newActionData = {
										...acsData,
										due_date: date,
									};

									setShowSaveDueDateBtn(true);

									if(internalSaveMode) {
										setActionStepData(newActionData);
									}
									else {
										onEdit(newActionData, false);
									}
								}}
							/>
						</div>
					)}
					<div className="d-flex">
						{(withStartFinishBtn && isEmpty(completedDate) && !isEmpty(acsData?.due_date)) && (
							<BaseButton 
								variant="text" 
								customclasses="mt-2 flex-shrink-0"
								onClick={() => {
									if(isStarted) {
										setIsCompletionConfirmed(true);
										setNoteWithDueDate(false);
										setShowActionStepsNoteModal(true);
										return;
									}
									else {
										setShowStartDialog(true);
									}
								}}
							>
								<TextView fontweight="medium">{isStarted ? 'Complete' : 'Start'} action step</TextView>
							</BaseButton>
						)}
						{(internalSaveMode && !hasInitialDueDate && showSaveDueDateBtn) && (
							<div className="w-100 d-flex ms-auto">
								<BaseButton 
									variant="text" 
									customclasses="ms-auto"
									onClick={() => {
										// TODO show notes here if not first time changing due date
										if(hasInitialDueDate && isEmpty(acsData?.notes)) {
											displayToast('error', 'Note is required.')
											setShowActionStepsNoteModal(true);
											return;
										}

										onEdit(acsData, false);
										setShowSaveDueDateBtn(false);
									}}
								>
									<TextView fontweight="medium">Save deadline</TextView>
								</BaseButton>
							</div>
						)}
					</div>
				</div>
			}
		</>
	);
}

let localCustomIdCount = 0;

function generateCustomId() {
	return `custom-${localCustomIdCount++}`;
}

function isCustomId(id) {
	return !Boolean(parseInt(id));
}

export function createDefaultActionData(title, elementId, attachmentAddId=0) {
	return {
		id: generateCustomId(),
		title: title,
		description: "",
		is_completed: false,
		is_started: false,
		due_date: "",
		remarks: {
			element_id: elementId,
			attachment_additionals_id: attachmentAddId, // custom
		},
		is_new: true,
	};
}

const LoadingSteps = (count = 3) => {
	
	return (
		<Box>
			{Array(count).fill(null).map((_, i) => (
				<Box className="d-flex flex-column mt-2" sx={{rowGap: '6px'}} key={i}>
					<Skeleton width="124px" height="24px" />
					<Skeleton width="260px" height="24px" />
				</Box>
			))}
		</Box>
	)
}

const ContentActionSteps = ({
	isQuickAccess = false,
	toSubmit = false,
	answerSent = false,
	allowAdd = false,
	allowEdit = false,
	requiredActions = 0,
	studentAnswers = null,
	customChoice = null,
	studentProgress = null,
	
	isLoading = () => {},
	onActionStepEdit = () => {},
	setSelectedActionstep = () => {},
	setShowEditActionStepModal = () => {},
	setCustomChoice = () => {},
	handleAddCustomChoice = () => {},
	submitStudentResponse = () => {},
	updateProgress = () => {},
	...props
}) => {

	const stepsComponent = (
		<>
			{(toSubmit || (isNotStudent(props?.currentCapabilities?.role) && !isQuickAccess)) ? (
				studentAnswers?.map((studAns, i) => (
					<Box
						className="w-100 d-flex align-items-center mb-2"
						key={studAns?.id ?? i}
					>
						{(isNotStudent(props?.currentCapabilities?.role) && !isQuickAccess) ? (
							<TextView>{i + 1}. {studAns?.title}</TextView>
						) : (
							<BaseCheckbox 
								onChange={(e) => {
									let newStudAns = {...studAns};
									newStudAns.selected = e.target.checked;
									
									onActionStepEdit(newStudAns, false);
								}}
								title={studAns?.title}
							/>
						)}
						{(!isNotStudent(props?.currentCapabilities?.role || isQuickAccess) && allowEdit) && (
							<ActionIconButton 
								customclasses="ms-2"
								onClick={() => {
									setSelectedActionstep(studAns);
									setShowEditActionStepModal(true);
								}}
								icon={<BorderColorIcon className="mr-auto color-primary font-size-medium mb-1"/> }
							/>
						)}
					</Box>
				))
			) : (
				studentAnswers?.map((studAns, i) => (
					<ActionstepCard 
						key={studAns?.id || i}
						index={i + 1}
						actionstepData={studAns}
						onEdit={onActionStepEdit}
						elementSettings={studAns?.context}
						withDateSelected={!isNotStudent(props?.currentCapabilities?.role) || isQuickAccess}
						withEdit={allowEdit}
					/>
				))
			)}
		</>
	)

	return (
		<>
			{(!isQuickAccess && !isEmpty(props?.element?.title)) && (
				<div className="d-flex">	
					<ElementTitle>{ props?.element?.title }</ElementTitle>
				</div>
			)}
			<div className="d-flex flex-column position-relative">
				<div className="d-flex flex-column mb-3">
					{(!isLoading(LOADING_GET_RESPONSE) && toSubmit && requiredActions > 0) && ( // show only if not submitted
						<TextView fontcolor="septenary" customclasses="ms-auto">
							{requiredActions} action(s) required
						</TextView>
					)}
					<TextView customclasses="d-block">
						{ props.element?.description }
					</TextView>
				</div>
				<div className="d-flex flex-column mb-1">
					{isLoading(LOADING_GET_RESPONSE) && (
						<LoadingSteps />
					)}

					{!isLoading(LOADING_GET_RESPONSE) && (
						stepsComponent
					)}
				</div>
				{(!isLoading(LOADING_GET_RESPONSE) && !isNotStudent(props?.currentCapabilities?.role) && allowAdd) && (
					<div className="d-flex mt-1">
						<TextFieldIconButton
							className="w-100 mb-2"
							placeholder="Add a custom action step"
							multiline
							maxRows="2"
							value={customChoice}
							onChange={(e) => {setCustomChoice(e.target.value)}}
							iconbutton={
								<IconButton 
									className="ms-1 mb-2"
									onClick={() => {
										handleAddCustomChoice(customChoice)
									}}
								>
									<AddCircleOutlineIcon className="color-green font-size-xxl"/>
								</IconButton>
							}
						/>
					</div>
				)}
				{(!isLoading(LOADING_GET_RESPONSE) && !answerSent && (!isNotStudent(props?.currentCapabilities?.role) || isQuickAccess) && toSubmit) && (
					<BaseButton
						issubmitting={isLoading(LOADING_SUBMIT)}
						customclasses="ms-auto mt-2"
						onClick={() => {
							submitStudentResponse();
						}}
					>
							Submit
					</BaseButton>
				)}
			</div>
			{
				(
					!isLoading(LOADING_GET_RESPONSE) && 
					!isNotStudent(props?.currentCapabilities?.role) && 
					props.element.last_element &&
					answerSent
				) && (
					<div className="d-flex ms-2 mt-2">
						<BaseButton 
							customclasses="ms-auto"
							onClick={() => {
								updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, studentProgress);
							}}
						>
							Next Element
						</BaseButton>
					</div>
				)
			}
		</>
	)
}

const ActionStepsElement = ({ ...props }) => {
	const [answerSent, setAnswerSent] = useState(false);
	const [customChoice, setCustomChoice] = useState("");
	const [answer, setAnswer] = useState([]);
	const [showEditActionStep, setShowEditActionStepModal] = useState(false);
	const [selectedActionstep, setSelectedActionstep] = useState();
	const [currentActionstepSettings, setCurrentActionstepSettings] = useState();
	const [studentAnswers, setStudentAnswers] = useState([]);
	const [toSubmit, setToSubmit] = useState(true);
	const [toRemoveActionStepId, setToRemoveActionStepId] = useState();

	const [showDialog, setShowDialog] = useState(false)
	const [dialogDetails, setDialogDetails]  = useState("")
	const [showRemoveActionStepDialog, setShowRemoveActionStepDialog] = useState(false);
	const {
		isLoading,
		addLoading,
		finishLoading,
	} = useMultiLoading();

	const requiredActions = props?.element?.context?.required_step ?? 0;
	const allowAdd = props?.element?.context?.allow_add;
	const allowEdit = props?.element?.context?.allow_edit;
	const selectedCompanyId = props?.currentCapabilities?.company?.company_id;

	const getStudentActionStepsResponseController = useRef(null);

	const location = useLocation();
	const isQuickAccess = isPageQuickAccess(location);

	useEffect(() => {
		if(isNotStudent(props.currentCapabilities.role) || isQuickAccess || toSubmit) { // student
			let newStudentAnswers = props?.element?.additional_attachments?.map((addAttch, i) => ({
				id: generateCustomId(),
				title: addAttch.title,
				description: "",
				is_completed: false,
				is_started: false,
				due_date: "",
				remarks: {
					element_id: props.element.element_id,
					attachment_additionals_id: addAttch.id,
				},
				is_new: true,
			}));

			setInitialDueDateStudentAnswers(newStudentAnswers);
		}
	}, [props?.element?.additional_attachments, props.studentAnswers]);

	const getStudentResponse = () => {
		addLoading(LOADING_GET_RESPONSE);

		getStudentActionStepsResponseController?.current?.abort();

		getStudentActionStepsResponseController.current = new AbortController();
		const signal = getStudentActionStepsResponseController.current.signal;

		getStudentActionStepsResponse(props?.element?.element_id, {
			email: isQuickAccess ? props?.quickAccess?.email : props?.userDetails?.email,
		}, { signal }).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				let actionResponse = response?.data?.data?.[0] ?? [];
				
				if(actionResponse?.length > 0) {
					setInitialDueDateStudentAnswers(actionResponse);
					setToSubmit(false);
				}
			}
		}).catch((error) => {
			console.error('Error fetching data:', error);
		}).finally(() => {
			finishLoading(LOADING_GET_RESPONSE, -1);
		});
	}

	useEffect(() => {
		if(isEmpty(props?.element?.element_id)) {
			return;
		}

		if(!isNotStudent(props.currentCapabilities.role) || isQuickAccess) {
			getStudentResponse();
		}
	}, [props?.element, answerSent]);

	const setInitialDueDateStudentAnswers = (answers = []) => {
		let newStudentAnswers = answers?.map((answer) => ({
			...answer,
			initial_due_date: answer?.due_date,
		}));

		setStudentAnswers(newStudentAnswers);
	}

	const handleAddCustomChoice = (value) => {
		if(isEmpty(value)) {
			return;
		}

		if(actionStepValueExists(value)) {
			displayToast("error", "Found Macthing Choices, Please check the existing choices.");
			return;
		}

		const newStudentAnswers = [...studentAnswers];

		const data = createDefaultActionData(value, props.element.element_id);

		newStudentAnswers.push(data);
		setInitialDueDateStudentAnswers(newStudentAnswers);
		setCustomChoice("");
	
		setSelectedActionstep(data);

		if(!toSubmit) {
			setShowEditActionStepModal(true);
		}
	}

	const actionStepValueExists = (value) => {
		for(let studAns of studentAnswers) {
			if(studAns.value == value) {
				return true;
			}
		}

		return false;
	}

	const [studentProgress, setStudentProgress] = useState(0)

	// bundle edit/submit of action steps
	const submitStudentResponse = () => {
		const toSubmitAnswers = studentAnswers.filter((studAns) => 
			studAns.selected
		);
		
		if(toSubmitAnswers?.length < requiredActions) {
			displayToast("error", `Please choose at least ${requiredActions} action step/s.`);
			return;
		}

		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			student_response: toSubmitAnswers,
		}

		let submitRequest;
		
		if(isQuickAccess) {
			params.email = props?.quickAccess?.email;
			params.firstname = props?.quickAccess?.name?.firstname;
			params.lastname = props?.quickAccess?.name?.lastname;
		
			submitRequest = quickSubmitElementResponse(params);
		}
		else {
			submitRequest = submitStudentElementResponse(params);
		}

		// to enable in the student view
		if(!isNotStudent(props.currentCapabilities.role) || isQuickAccess) {
			addLoading(LOADING_SUBMIT);
			
			submitRequest.then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					setStudentProgress(response?.data?.data?.["library_progress"]?.[0])

					displayToast("success", "Action steps submitted.")
					setAnswerSent(true)
					setToSubmit(false);
				}
			}).finally(() => {
				finishLoading(LOADING_SUBMIT);
			});
		}
		else {
			setAnswerSent(true)
			displayToast("success", "This is just a test, Submission not recorded on admin view.")
			finishLoading(LOADING_SUBMIT);
		}
	}

	const onActionStepEdit = (data, showModal = true) => {
		if(showModal) {
			setShowEditActionStepModal(true);
			setSelectedActionstep(data);
		}
		else {
			onActionStepEditSubmit(data);
		}
	}

	// single edit/submit of action steps
	const onActionStepEditSubmit = async (data) => {
		let editedStud = data;
		
		if(data.is_new && !toSubmit && isEmpty(data.to_delete)) { // id is not custom
			try {
				let toSubmitData = {
					...data,
					users: [props.userDetails.user_id ?? props.userDetails.id],
				};

				if(isQuickAccess) {
					toSubmitData.emails = [props?.quickAccess?.email];
				}

				const response = await quickCreateActionstep(toSubmitData);
				
				if(response.data.init[0].status !== 'ok') {
					displayToast("error", response.data.init[0]["message"]);
					return;
				}

				editedStud = response.data.data[0];

				displayToast("success", "Action step create success.");
			} catch (error) {
				displayToast("error", "Something went wrong.");
			}
		}
		else if(!data.is_new && !toSubmit) {
			try {
				let toSubmitData = {
					...data,
					action_step_id: data.id,
				};
				const response = await quickUpdateActionstep(toSubmitData);
				
				if(response.data.init[0].status !== 'ok') {
					displayToast("error", response.data.init[0]["message"]);
					return;
				}

				editedStud = response.data.data[0];

				displayToast("success", "Action step edit success.");
			} catch (error) {
				displayToast("error", "Something went wrong.");
			}
		}
		
		let newStudentAnswers = studentAnswers.map((studAns) => {

			if(studAns.id === data.id) {
				return {...editedStud, to_delete: null};
			}

			return studAns;
		});

		setInitialDueDateStudentAnswers(newStudentAnswers);
	}
	
	const onActionStepDelete = (data) => {
		let remainingActionStep = studentAnswers.length;
		// count action step with to_deleted true
		studentAnswers.forEach((studAns) => {
			if(studAns.to_delete) {
				remainingActionStep--;
			}
		});
		remainingActionStep--; // consider the actionstep to be deleted

		if(remainingActionStep < requiredActions) {
			setShowEditActionStepModal(false);
			displayToast("error", `We require you to choose at least ${requiredActions} steps.`);
			return;
		}

		let newStudentAnswers = [...studentAnswers];

		if(toSubmit || data.is_new) { // to submit actionsetup
			newStudentAnswers = newStudentAnswers.map((studAns) => {
				if(studAns.id == data.id) {
					studAns.to_delete = true;
				}
	
				return studAns;
			});

			setInitialDueDateStudentAnswers(newStudentAnswers);
			setShowEditActionStepModal(false);
		}
		else { // edit mode
			setToRemoveActionStepId(data.id);
			setShowRemoveActionStepDialog(true);
		}
	}
	
	const confirmActionStepRemove = () => {
		if(isEmpty(toRemoveActionStepId)){
			return;
		}

		let newStudentAnswers = [...studentAnswers].filter((studAns) => studAns.id != toRemoveActionStepId);

		quickDeleteActionstep(toRemoveActionStepId).then((response) => {
			if(response.data.init[0].status !== 'ok') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				setInitialDueDateStudentAnswers(newStudentAnswers);
				displayToast("success", "Action steps deleted.");
			}
		}).finally(() => {
			setShowEditActionStepModal(false);
			setShowRemoveActionStepDialog(false);
			setToRemoveActionStepId(null);
		});
	}

	const actionStepsElementComponent = (
		<>
			<YesNoDialog 
				message="Are you sure you want to permanently delete this action step?"
				open={showRemoveActionStepDialog}
				onClose={() => {
					setShowRemoveActionStepDialog(false);
					setToRemoveActionStepId(null);
				}}
				confirm={confirmActionStepRemove}
			/>
			<ActionstepCardEditModal 
				show={showEditActionStep && !isEmpty(selectedActionstep)}
				setShow={setShowEditActionStepModal}
				actionstepData={selectedActionstep}
				elementSettings={currentActionstepSettings}
				onActionStepEditSubmit={onActionStepEditSubmit}
				onActionStepDelete={onActionStepDelete}
				companyId={selectedCompanyId}
			/>
			<ContentActionSteps 
				isQuickAccess={isQuickAccess}
				toSubmit={toSubmit}
				answerSent={answerSent}
				allowAdd={allowAdd}
				allowEdit={allowEdit}
				requiredActions={requiredActions}
				studentAnswers={studentAnswers}
				customChoice={customChoice}
				studentProgress={studentProgress}
				
				isLoading={isLoading}
				onActionStepEdit={onActionStepEdit}
				setSelectedActionstep={setSelectedActionstep}
				setShowEditActionStepModal={setShowEditActionStepModal}
				setCustomChoice={setCustomChoice}
				handleAddCustomChoice={handleAddCustomChoice}
				submitStudentResponse={submitStudentResponse}

				updateProgress={props?.updateProgress}
				element={props?.element}
				currentCapabilities={props?.currentCapabilities}
			/>
		</>
	);

	if(isQuickAccess) {
		return actionStepsElementComponent;
	}

	return (
		<LessonAccordion {...props}>
			{actionStepsElementComponent}
		</LessonAccordion>
	)
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
		userDetails : state.auth.user_details,
		quickAccess: state.quickAccess,
	}

	return data;
}

export default connect(mapStateToProps)(ActionStepsElement);