import axios from "axios";
import { DEFAULT_AUTH_HEADER } from "../../models/Constants";
import { objectToUrlQuery } from "../../helpers/Functions";

export function signUp(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/sign-up", params, DEFAULT_AUTH_HEADER);
}

export function login(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/app-login", params, DEFAULT_AUTH_HEADER);
}

export function otpVerification(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/otp-verification", params, DEFAULT_AUTH_HEADER);
}

export function resendOTP(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/otp-resend", params, DEFAULT_AUTH_HEADER);
}

export function getUserByToken() {
	return axios.get(process.env.REACT_APP_API_URL + "/auth/user");
}

export function updateUser(params){
	return axios.post(process.env.REACT_APP_API_URL + "/auth/update-user", params);
}

export function forgotPassword(params){
	return axios.post(process.env.REACT_APP_API_URL + "/forgot-password", params, DEFAULT_AUTH_HEADER);
}

export function newPassword(params){
	return axios.post(process.env.REACT_APP_API_URL + "/new-password", params, DEFAULT_AUTH_HEADER);
}

export function updateUsername(params){
	let dataPost = new FormData();

	for (const [key, value] of Object.entries(params)) {
		if (typeof value === 'object') {
			if (value instanceof File) { // Check if the value is a file object
				dataPost.append(key, value, value.name); // Append the file object with its name
			} 
			else {
				dataPost.append(key, JSON.stringify(value));
			}
		} 
		else {
			dataPost.append(key, value);
		}
	}
	return axios.post(process.env.REACT_APP_API_URL + "/auth/update-user-name", dataPost);
}

export function updateAdditionalInfo(params){
	return axios.post(process.env.REACT_APP_API_URL + "/auth/update-additional-info", params);
}

export function quickUpdateAdditionalInfo(params){
	return axios.post(process.env.REACT_APP_API_URL + "/quick-update-additional-info", params, DEFAULT_AUTH_HEADER);
}

export function updateUserPassword(params){
	return axios.post(process.env.REACT_APP_API_URL + "/auth/update-user-password", params);
}

export function getUsers(params){
	return axios.get(process.env.REACT_APP_API_URL + "/user?" + objectToUrlQuery(params));
}

export function deleteUserAccount(params) {
	return axios.delete(process.env.REACT_APP_API_URL + "/user/admin-delete-account?" + objectToUrlQuery(params));
}

export function verifyEmail(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/verify-email-address", params, DEFAULT_AUTH_HEADER);
}

export function loginGPT(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/gpt-login", params, DEFAULT_AUTH_HEADER);
}

export function getPersonDetails(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/quick-get-user-infos?"  + objectToUrlQuery(params), DEFAULT_AUTH_HEADER);
}