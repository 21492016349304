import {Buffer} from 'buffer';
import moment from 'moment-timezone';
import displayToast from '../theme/partials/DisplayToast';
import { DATE_FRAME_ALL_TIME, DATE_FRAME_LAST_MONTH, DATE_FRAME_THIS_MONTH, DATE_FRAME_THIS_YEAR, ERROR_MESSAGES, LIBRARY_TYPE_IN_PERSON_TRAINING, LIBRARY_TYPE_INTELLIGUIDE, LIBRARY_TYPE_LEARNING_HABIT, LIBRARY_TYPE_MANUAL, LIBRARY_TYPE_POLICY, LIBRARY_TYPE_TRAINING, LIBRARY_TYPE_WEBINAR } from '../models/Constants';
import { useEffect, useRef } from 'react';
import { SEARCH_TIMER_DELAY } from '../models/Constants';

export function objectToUrlQuery(filter) {
	let params = "";

	if(filter !== null) {
		// eslint-disable-next-line
		Object.keys(filter).map(function(key, index) {
			if(index === 0) {
				params = `${key}=${filter[key]}`;
			}
			else {
				params = params + `&${key}=${filter[key]}`;
			}
		});
	}

	return params;
}

export function decryptString(string) {
	var CryptoJS = require('crypto-js');

	var key = process.env.REACT_APP_API_KEY;
	let encrypted = Buffer.from(string, 'base64');
	encrypted = JSON.parse(encrypted);
	const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
	const value = encrypted.value;
	key = CryptoJS.enc.Base64.parse(key);
	
	var decrypted = CryptoJS.AES.decrypt(value, key, {
		iv: iv
	});

	return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}

export function timeFromString(timeString, timeFormat = "HH:mm:ss", formatTo = "hh:mm A") {
	return moment.tz(timeString, timeFormat, "Asia/Manila").format(formatTo); // TODO: must have settings for timezone
}

export function mergeArrayOfObjects(arr1, arr2) {
	return arr1.concat(arr2).reduce((acc, object) => {
		Object.entries(object).forEach(([key, value]) => {
			if(!acc.hasOwnProperty(key)) {
				acc[key] = value;
			}
		});

		return acc;
	}, {});
}

export function isEmpty(obj) {
	if (obj === null || obj === undefined) {
		return true;
	}

	if(typeof obj === "undefined") {
		return true;
	}

	if(obj instanceof File) {
		if(obj.size == 0) {
			return true;
		}
		else {
			return false;
		}
	}

	if(typeof obj === "object" && Object.keys(obj).length === 0) {
		return true;
	}

	if(typeof obj === "string" && obj.length === 0) {
		return true;
	}

	if(typeof obj === "string" && obj === "null") {
		return true;
	}

	if(typeof obj === "string" && obj === "undefined") {
		return true;
	}

	return false
}

export function isVideoFile(link) {
	const fileExtensions = [
		".mp4",
		".mov",
		".avi",
		".wmv",
		".mkv",
		".flv",
		".webm"
	];

	return compareLink(fileExtensions, link);
}

export function isAudioFile(link) {
	const fileExtensions = [
		".mp3",
		".m4a",
		".flac",
		".mp4",
		".wav",
		".wma",
		".aac"
	];

	return compareLink(fileExtensions, link);
}

function compareLink(fileExtensions, link) {
	if(link) {
		for(const fileExtension of fileExtensions) {
			if(link.includes(fileExtension)) {
				return true;
			}
		}
	}

	return false;
}

export function formatDate(dateString, format="MMM DD, YYYY") {
	const date = new Date(dateString);
	return moment(date).format(format);
}

export function toNumerical(wordString){
	const numberOnly = wordString.replace(/\D/g, '');
	const noLeadingZeros = numberOnly.replace(/^0+/, '');
	const count = noLeadingZeros === '' ? '0' : noLeadingZeros;

	return count
}

export function makeCapitalize(inputString) {
	return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
}

export const paramFinder = ( prompt , paramValues, ignoreError = false) => {
	const pattern = /\{\{(\w+)\}\}/g;
	let error = false

	let replacedString = prompt.replace(pattern, (match, word) => {
		if (match == "{{rank}}") {
			return paramValues["identification_name"] || match;
		} 
		else if(isEmpty(paramValues[word])) {
			if(!ignoreError){
				displayToast("error", `You have a parameter ${match} on your prompt but did not give a definition, Please fill up the ${match} textbox.`);
				error = true;
			}

			return match
		}

		return paramValues[word] || match;
	});

	if(error) {
		return false
	}

	return replacedString
};

export function transformArrayToObject(inputArray, keyWord, valueWord) {
	const resultObject = {};
  
	inputArray.forEach(item => {
		resultObject[item[keyWord].toLowerCase()] = [item[valueWord]];
	});

	return resultObject;
}

export function trimAIResponseToArray(response) {
	const startIndex = response.indexOf('[');
	const endIndex = response.lastIndexOf(']');

	if (startIndex !== -1 && endIndex !== -1) {
		const trimmedString = response.substring(startIndex , endIndex + 1).trim();
	
		return trimmedString;
	} 
	else {
		return null 
	}
}

export function transformBoldElement (wordString, format) {
	let output = "";

	if(format == "element") {
		output = wordString.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
	}
	else {
		output = wordString.replace(/<b>(.*?)<\/b>/g, '**$1**');
	}
	
	return output
}

export function isPageQuickAccess(location) {
	const firstPathSegment = location.pathname.split('/')[1];
	const isQuickAccess = firstPathSegment === 'me';

	return isQuickAccess
}

export function getRandomColor(brightness) {
	function randomChannel(brightness) {
		var r = 255 - brightness;
		var n = 0 | (Math.random() * r + brightness);
		var s = n.toString(16);
		return (s.length == 1) ? '0' + s : s;
	}
	return '#' + randomChannel(brightness) + randomChannel(brightness) + randomChannel(brightness);
}

export function getRandomNumber(min, max) {
	return Math.floor(Math.random() * (max - min) + min);
}

export function isValidEmail(value) {
	return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
}

export function findNumbersOnArray(arr, nums) {
	//todo: return always true when on dev to access all features
	if(Array.isArray(arr)){
		return nums.every(num => arr.includes(num));
	}
	else {
		return false
	}
}

export function deadlineConstantsToDate(dayAdd, currentDate = new Date()) {
	return new Date(
		currentDate.getFullYear(),
		currentDate.getMonth(),
		currentDate.getDate() + dayAdd,
	);
}

export function isObject(variable) {
	return variable !== null && typeof variable === 'object' && !Array.isArray(variable);
}

export function findFirstNullProgressReportIndex(data) {
	if(isEmpty(data?.[0]?.lessons?.[0]?.progress_report)){
		return { parentIndex: "starting", sort: 0, next_unopened_lesson: 0, completed_lesson: 0 }
	}
	else {
		const progress = data.find((parent, parentIndex) => {
			const allLessonsHaveProgress = parent.lessons.every(lesson => lesson.progress_report !== null);
		
			if (!allLessonsHaveProgress) {
				return true; // This will cause find to return the current parent
			}
		
			return false; // continue to next parent
		});
		
		if (progress) {
			const parentIndex = data.indexOf(progress);

			let lessonIndex = progress.lessons.findIndex(lesson => lesson.progress_report === null);
			let completedLesson = 0;

			if(lessonIndex > 0) {
				const lastLessonCompleted = data[parentIndex].lessons[lessonIndex - 1].progress_report.is_completed

				if(lastLessonCompleted) {
					completedLesson = lessonIndex;
					lessonIndex = lessonIndex + 1;
				}
			}

			if(lessonIndex == 0) {
				const lastModuleIndex = parentIndex - 1;

				if(lastModuleIndex >= 0){
					let lastModuleLastLesson = data[lastModuleIndex].lessons.length - 1
					let lastModuleLastLessonData = data[lastModuleIndex].lessons[lastModuleLastLesson]

					if(lastModuleLastLessonData.progress_report.is_completed) {
						completedLesson = parentIndex
					}
				}
			}

			const result = { parentIndex: parentIndex, sort: progress.sort, next_unopened_lesson: lessonIndex, completed_lesson: completedLesson};

			return result;
		}
		else {
			const result = { parentIndex: "finished", sort: 0, next_unopened_lesson: 0, completed_lesson: 0 };

			return result;
		}
	}
}

export const base64ToBlob = (dataURL) => {
	const [header, data] = dataURL.split(',');
	const mime = header.match(/:(.*?);/)[1];
	const byteString = atob(data);
	const arrayBuffer = new ArrayBuffer(byteString.length);
	const uintArray = new Uint8Array(arrayBuffer);

	for (let i = 0; i < byteString.length; i++) {
	  uintArray[i] = byteString.charCodeAt(i);
	}

	return new Blob([arrayBuffer], { type: mime });
};

export function ensureArray(variable) {
	if (Array.isArray(variable)) {
		return variable;
	}
	else if (typeof variable === 'string') {
		if (variable.includes(',')) {
			return variable.split(',').map(item => item.trim());
		} 
		else {
			return [variable];
		}
	}
	else {
		return [];
	}
}

export function libraryTypeToName(type, isPlural=false) {
	switch(type) {
		case LIBRARY_TYPE_TRAINING:
			return isPlural ? "Online Courses" : "Online Course";
		case LIBRARY_TYPE_MANUAL:
			return isPlural ? "Manuals" : "Manual";
		case LIBRARY_TYPE_POLICY:
			return isPlural ? "Policies" : "Policy";
		case LIBRARY_TYPE_IN_PERSON_TRAINING:
			return isPlural ? "In-Person Trainings" : "In-Person Training";
		case LIBRARY_TYPE_WEBINAR:
			return isPlural ? "Webinars" : "Webinar";
		case LIBRARY_TYPE_INTELLIGUIDE:
			return isPlural ? "Intelliguides" : "Intelliguide";
		case LIBRARY_TYPE_LEARNING_HABIT:
			return isPlural ? "Learning Habit Plan" : "Learning Habit Plan";
	}

	return "";
}

export function formatISODate(isoDateString) {
	const date = new Date(isoDateString);
	const padToTwoDigits = (num) => String(num).padStart(2, '0');
	
	return `${padToTwoDigits(date.getUTCHours())}-${padToTwoDigits(date.getUTCMinutes())} ${padToTwoDigits(date.getUTCDate())}-${padToTwoDigits(date.getUTCMonth() + 1)}-${date.getUTCFullYear()}`;
}

export function getHighestUnitDateAgo(targetDate, showDate = false) {
	const now = new Date();
	const diffInMs = now - new Date(targetDate);
	const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
	const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Calculate hours
	const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
	const diffInWeeks = Math.floor(diffInDays / 7);
	const diffInMonths = Math.floor(diffInDays / 30);

	if (diffInMonths > 0) {
		return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago ${showDate ? `: ${formatDate(new Date(now.setMonth(now.getMonth() - diffInMonths)))}` : ""}`;
	}
	else if (diffInWeeks > 0) {
		return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''} ago ${showDate ? `: ${formatDate(new Date(now.setDate(now.getDate() - diffInWeeks * 7)))}` : ""}`;
	}
	else if (diffInDays > 0) {
		return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago ${showDate ? `: ${formatDate(new Date(now.setDate(now.getDate() - diffInDays)))}` : ""}`;
	}
	else if (diffInHours > 0) {
		return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago ${showDate ? `: ${formatDate(new Date(now.setHours(now.getHours() - diffInHours)))}` : ""}`;
	}
	else {
		return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago ${showDate ? `: ${formatDate(new Date(now.setMinutes(now.getMinutes() - diffInMinutes)))}` : ""}`;
	}
}

export function calculatePercentage(n, total) {
	if (total === 0) {
		return 0;
	}
	return (n / total) * 100;
}

export function dateFrameConstantToParam(selectedDateFilter) {
	switch (selectedDateFilter) {
		case DATE_FRAME_THIS_MONTH:
			return { sub_month: 0, sub_year: 0 };
		case DATE_FRAME_LAST_MONTH:
			return { sub_month: 1, sub_year: 0 };
		case DATE_FRAME_THIS_YEAR:
			return { sub_year: 0 };
		case DATE_FRAME_ALL_TIME:
			return {};
		default:
			return {};
	}
}

export function formatTimeToAmPm(time) {
	const [hours, minutes] = time.split(":");
	const date = new Date();
	date.setHours(parseInt(hours, 10));
	date.setMinutes(parseInt(minutes, 10));
	return date.toLocaleString("en-US", {
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	});
}

export function checkForErrorCode(error_code) {
	return ERROR_MESSAGES.find(item => item.error_code === error_code);
}

export function strDateToTimeElapse(inputDate) {
	const now = new Date();
	const past = new Date(inputDate);
	const diffInSeconds = Math.floor((now - past) / 1000); // difference in seconds

	if(!inputDate) {
		return null;
	}

	// helper function to format the output with singular/plural handling
	const pluralize = (count, singular, plural) =>
		count === 1 ? `${count} ${singular} ago` : `${count} ${plural} ago`;

	if (diffInSeconds < 60) { // less than a minute (60 seconds)
		return pluralize(diffInSeconds, "sec.", "secs.");
	} else if (diffInSeconds < 3600) { // less than a hour (3600 seconds = 60 minutes)
		const minutes = Math.floor(diffInSeconds / 60);
		return pluralize(minutes, "min.", "mins.");
	} else if (diffInSeconds < 86400) { // less than a day (86400 seconds = 24 hours)
		const hours = Math.floor(diffInSeconds / 3600);
		return pluralize(hours, "hr.", "hrs.");
	} else if (diffInSeconds < 604800) { // less than a week (604800 seconds = 7 days)
		const days = Math.floor(diffInSeconds / 86400);
		return pluralize(days, "day", "days");
	} else if (diffInSeconds < 2419200) { // less than a month (2419200 seconds ≈ 28 days)
		const weeks = Math.floor(diffInSeconds / 604800);
		return pluralize(weeks, "week", "weeks");
	} else if (diffInSeconds < 29030400) { // less than a year (29030400 seconds ≈ 12 months)
		const months = Math.floor(diffInSeconds / 2419200);
		return pluralize(months, "month", "months");
	} else { // more than a year
		const years = Math.floor(diffInSeconds / 29030400);
		return pluralize(years, "yr.", "yrs.");
	}
}

export function combineObjects(obj1, obj2) {
	const combined = {};
  
	for (let key in obj1) {
	  if (obj1.hasOwnProperty(key)) {
		combined[key] = obj1[key];
		}
	}
	
	for (let key in obj2) {
		if (obj2.hasOwnProperty(key) && !combined.hasOwnProperty(key)) {
			combined[key] = obj2[key];
		}
	}
  
	return combined;
}

export function generateSidebarTheme(primary) {
	const adjustColor = (hex, percent) => {
		let num = parseInt(hex.slice(1), 16);
		let r = (num >> 16) + percent;
		let g = ((num >> 8) & 0x00ff) + percent;
		let b = (num & 0x0000ff) + percent;
	
		r = Math.max(Math.min(255, r), 0);
		g = Math.max(Math.min(255, g), 0);
		b = Math.max(Math.min(255, b), 0);
	
		return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
	}

	const lightenColor = (hex, amount) => {
		return adjustColor(hex, Math.abs(amount)); // make sure it's always lightening
	}

	const adjustContrast = (hex, amount) => {
		return adjustColor(hex, amount); // darkens or lightens based on primary
	}

	const secondary = lightenColor(primary, 70); // make the secondary lighter
	const tertiary = adjustContrast(primary, -50); // create a darker, contrasting tertiary

	return {
		primary,
		secondary,
		tertiary,
	};
}

export function pluralize(singularWord, count = 0) {
	/**
	 * Note:
	 * - This function can only handle basic pluralization rules.
	 * - It does not handle irregular plurals (e.g., child -> children, person -> people).
	*/

	if (count === 1) {
		return singularWord; // Return singular if count is 1
	}

	// Handle words ending in 'y' (e.g., company -> companies)
	if (singularWord.endsWith('y') && !['a', 'e', 'i', 'o', 'u'].includes(singularWord[singularWord.length - 2])) {
		return singularWord.slice(0, -1) + 'ies';
	}

	// Handle words ending in 's', 'x', 'z', 'ch', 'sh' (e.g., bus -> buses)
	if (/(s|x|z|ch|sh)$/.test(singularWord)) {
		return singularWord + 'es';
	}

	// Default rule: add 's' for regular plurals
	return singularWord + 's';
}

export function safeJsonParse(value) {
	try {
		return value ? JSON.parse(value) : null;
	} catch (error) {
		console.error("Failed to parse JSON:", error);
		return value;
	}
}

export function parseBoolean(value) {
	if (typeof value === 'boolean') {
		return value; // Already a boolean, return as is
	}

	if (typeof value === 'string') {
		const lowerValue = value.toLowerCase();
		if (lowerValue === 'true') {
			return true;
		}
		if (lowerValue === 'false') {
			return false;
		}
	}

	if (typeof value === 'number') {
		return value === 1; // Treat 1 as true, everything else as false
	}

	return Boolean(value); // Fallback for other truthy/falsy cases
}

export function reconstructTools(data) {
	let returnData
	
	if(Array.isArray(data)){
		returnData = data?.map(item => ({
			value: item,
			label: item == "file_search" ? "File Search" : "Code Interpreter"
		}));
	}
	else if (typeof data === "string") {
		const cleanedData = data.replace(/[\[\]]/g, "");
		const dataArray = cleanedData ? cleanedData.split(",") : [];

		returnData = dataArray.map(item => {
			const trimmedItem = item.trim();
			return {
				value: trimmedItem,
				label: trimmedItem === "file_search" ? "File Search" : "Code Interpreter"
			};
		});
	}

	return returnData
}

export function extractValidNumber(value) {
	const numberOnly = value.replace(/\D/g, '');  // Remove non-numeric characters
	const noLeadingZeros = numberOnly.replace(/^0+/, '');  // Remove leading zeros
	return noLeadingZeros === '' ? '0' : noLeadingZeros; 
}

/**
 * Creates a debounced function that delays invoking func until after wait milliseconds have elapsed
 * since the last time the debounced function was invoked.
 * @param {Function} func The function to debounce
 * @param {number} wait The number of milliseconds to delay
 * @return {Function} Returns the new debounced function
 */
export const debounce = (func, wait) => {
	let timeout;
	
	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export function generateRandomId(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomId = '';
    for (let i = 0; i < length; i++) {
        randomId += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomId;
};
