import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { forwardRef } from 'react';

const BaseTextField = forwardRef(({ display = "block", onKeyDown, value, onChange, ...props }, ref) => {
	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			if (e.shiftKey) {
				e.preventDefault();
				onChange?.({ target: { value: value + "\n" } });
			} 
			else {
				onKeyDown?.(e);
			}
		}
	};
	return (
		<div className={`w-100 mb-3 d-${display} ${props?.customclasses}`}>
			<div className="d-flex justify-content-between">
				{props.title && (
					<InputLabel
						className={`
							${(props.titleclass) ? props.titleclass :
							"font-size-medium color-septenary"}
							mb-2`
						}
					>
						{props.title}
					</InputLabel>
				)}

				{props?.extralink && props.extralink}
			</div>

			<TextField
				className="w-100"
				inputProps={{
					className: { input: "content-textfield" }
				}}
				value={value}
				onChange={onChange}
				onKeyDown={handleKeyDown}
				{...props}
			/>
		</div>
	);
});

export default BaseTextField;