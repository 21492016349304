import React, { useContext, useEffect, useState } from "react";

import LessonAccordion from "./LessonAccordion";
import TextView from "../../Typography";
import { setAI } from "../../../../app/redux/slices/dashboardSlice";
import { connect } from "react-redux";
import { IconButton, InputLabel, Skeleton} from "@mui/material";
import PageTabs, { TabPanel } from "../../PageTabs";
import { combineObjects, isEmpty, isPageQuickAccess, paramFinder } from "../../../../helpers/Functions";
import { isNotStudent } from "../../../../helpers/Utils";
import BaseButton from "../../BaseButton";
import { quickTextElementToEmail, submitStudentElementResponse, textElementToEmail } from "../../../../app/crud/element.crud";
import displayToast from "../../DisplayToast";
import { useLocation } from "react-router-dom";
import { AI_TEXT_PROMPT_RETURN, DEFAULT_EMAIL_FOOTER } from "../../../../models/Constants";
import { quickAiChatCompletion } from "../../../../app/crud/ai.crud";
import { Modal } from "react-bootstrap";
import BaseTextField from "../../BaseTextField";
import { getUserByToken, updateAdditionalInfo } from "../../../../app/crud/person.crud";
import { setUserDetails } from "../../../../app/redux/slices/authSlice";
import { LibraryContext } from "../../../../contexts/contexts";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from "rehype-raw"; 

const QuillContainer = ({value}) => {
	return(
		<div dangerouslySetInnerHTML={{__html : value}} />
	)
}

const EditAIModal = ({ ...props }) => {
	const [editParams, setEditParams] =useState({ ...props.aiparams })

	return (<>
		<Modal size="xl" show={props.show} onHide={props.onHide} centered>
			<Modal.Header closeButton>
				<Modal.Title>User Information</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='mb-2 mt-2'>
					<InputLabel className='font-size-large color-primary'>Let’s Personalize It</InputLabel>
					<TextView>Kindly provide the information below so we can personalize it for you.</TextView>
				</div>
				{
					props.aiparams && Object.entries(props.aiparams).map(([parameter, value], key) => {
						let titleName = parameter.charAt(0).toUpperCase() + parameter.slice(1);

						if (isEmpty(value)) {
							return <BaseTextField
								key={key}
								title={titleName}
								name={parameter}
								className="w-100"
								variant="outlined"
								value={editParams?.[parameter]}
								onChange={(e) => {
									setEditParams(prevState => ({
										...prevState,
										[parameter]: e.target.value,
									}));
								}}
							/>
						}
					})
				}
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex">
					<BaseButton
						onClick={(e) => {
							const isEditParamsValid = editParams && Object.keys(editParams).every(
								key => editParams[key] !== undefined && editParams[key] !== null && editParams[key] !== ""
							);
					
							if (isEditParamsValid) {
								props.setAIParams(editParams);
								props.setNewParam(false);
								props.updateAddInfo(editParams)
								props.onHide();
							} else {
								displayToast("Please fill up empty values.");
							}
						}}
					>
						Submit
					</BaseButton>
				</div>
			</Modal.Footer>
		</Modal>
	</>);
}

const ContentText = ({ elementText, setElementText, ...props}) => {
	const [newParam, setNewParam] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [additionalInfoDetails, setAdditionalInfoDetails] = useState({})
	const [renderNext, setRenderNext] = useState(false);
	const { learningHabitID = null } = useContext(LibraryContext) || {};

	useEffect(() => {
		if(props.element.context?.text_type == "text_ai" && props.element.context.ai_customized == 1) {
			if(elementText != props.element.description) {
				setRenderNext(true)
			}
			else {
				setRenderNext(false)
			}
		}
		else {
			setRenderNext(true)
		}
	}, [elementText])

	useEffect(() => {
		setElementText(props.element.description)
	}, [props.element.description])

	const submitStudentResponse = () => {
		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			learning_habit_id: learningHabitID ?? null
		}

		if(props.element.context?.text_type == "text_ai" && props.element.context.ai_customized == 1) 
		{
			params.gpt_response = elementText
		}

		// to enable in the student view
		submitStudentElementResponse(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, response.data.init[0]?.["library_progress"]);
			}
		})
	}

	const textCompletion = () => {
		let params = {}
		let formatedPrompt = (isEmpty(props.element.context.ai_prompt)) ? true : paramFinder(props.element.context.ai_prompt, additionalInfoDetails)

		if(!formatedPrompt){
			return false
		}

		if(!isEmpty(props.element?.context?.ai_transcript)){
			let transcripts = JSON.parse(props.element?.context?.ai_transcript);
			transcripts = transcripts.map(obj => obj.transcript).join('\n\n');

			formatedPrompt = formatedPrompt + "\n" + transcripts + "\n" + AI_TEXT_PROMPT_RETURN
		}

		if(props.element.context?.defaultText == 0) {
			params = {
				prompt : formatedPrompt
			}
		}
		else {
			params = {
				string : props.element.description,
				prompt : formatedPrompt
			}
		}

		params.element_id = props.element_id;
		params.email = props.email;

		setSubmitting(true)

		quickAiChatCompletion(params).then((response) => {
			if (response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
				setSubmitting(false)
			}
			else {
				let newdescription = response.data.data[0].content

				if(props.element.context.ai_is_custom_label == 1){
					newdescription = props.element.context.ai_custom_label + "</br><br>" + newdescription;
				}

				setElementText(newdescription)

				setShowModal(false)
				setSubmitting(false);
				setNewParam(false);
			}
		});
	}

	const createParamArr = async (prompt) => {
		const pattern = /\{\{(\w+)\}\}/g;
		let parameters = {};
	
		await new Promise((resolve) => {
			prompt?.replace(pattern, (match, word) => {
				let removeBraces = match.replace(/{{|}}/g, '');
	
				if (!parameters.hasOwnProperty(removeBraces)) {
					let value = props.userDetails?.additional_info?.[removeBraces] || "";

					parameters[removeBraces] = value;

					if(isEmpty(value)){
						setNewParam(true)
					}
				}
	
				return match;
			});

			resolve();
		});
	
		return parameters;
	};

	useEffect(() => {
		const fetchData = async () => {
			if (!isEmpty(props.element)) {
				if (props.element.context.ai_customized == 1) {
					try {
						if(!isEmpty(props.element?.context?.ai_prompt)){
							let params = await createParamArr(props.element?.context?.ai_prompt);
							setAdditionalInfoDetails(params)
						}
					} catch (error) {
						console.error('Error fetching data:', error);
					}
				}
			}
		};
	
		fetchData();
	}, []);

	const handleModalToggle = () => {
		setShowModal(!showModal);
	};

	useEffect(() => {
		if (
			props.element.context?.text_type == "text_ai" &&
			!isEmpty(props.element?.attachments?.[0]?.student_attachment_response)
		) {
			let studentResponse = props.element.attachments[0].student_attachment_response
			setElementText(studentResponse.content)
		}
	}, [props.element]);

	return (<>
		{
			props.element.context?.text_type == "text_ai" && (<>
				<EditAIModal 
					show={showModal}
					setShow={setShowModal}
					onHide={handleModalToggle}
					textCompletion={textCompletion}
					aiparams={additionalInfoDetails}
					setAIParams={setAdditionalInfoDetails}
					setNewParam={setNewParam}
					updateAddInfo={props.updateAddInfo}
				/>
				<TextView className="mb-4">
					{
						isEmpty(elementText) ? (<>
							<>
								{Array.from({ length: 3 }).map((_, index) => (
									<Skeleton key={index} variant="rounded" width={"100%"} height={"3vh"} />
								))}
							</>
						</>) : (
							<ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
								{elementText}
							</ReactMarkdown>
						)
					}

					{
						(
							props.element.context.ai_customized == 1 && 
							!props.isQuickAccess && 
							!isNotStudent(props.userCapabilities?.role) &&
							(elementText == props.element.description)
						) && (
							<div className="d-flex justify-content-center align-items-center my-2">
								<BaseButton 
									className="font-primary font-size-xm bg-primary color-white"
									customstyles={{ borderRadius: '30px', height: '3.5rem', width: '15rem' }}
									issubmitting={submitting}
									onClick={(e) => {
										if(newParam){
											setShowModal(true)
										}
										else {
											textCompletion()
										}
									}}
								>
									Personalize for me
								</BaseButton>
							</div>
						)
					}
				</TextView>
				{
					(
						(props.element.context.ai_customized == 1 && (elementText != props.element.description)) ||
						(props.element.context.ai_customized == 0)
					) && (
						(!isEmpty(props.email) && props.element.context.text_mailable == 1) ? (
							<div className="d-flex justify-content-center align-items-center">
								<BaseButton
									variant="text"
									fontsize="xm"
									onClick={() => {
										const textToEmail = (props.element.context.ai_customized == 1) ? elementText : props.element.description
										props.emailTextToUser(textToEmail)
									}}
								> 
									Email This To Me 
								</BaseButton>
							</div>
						) : null
					)
				}
			</>)
		}
		{props.element.context?.text_type == "text_variation" && (
			isNotStudent(props.userCapabilities.role) ? (<>
				<PageTabs
					tabs={props.selectTabs} 
					currentValue={props.statusTab} 
					setCurrentValue={props.setStatusTab}
					customclasses="p-0 mb-3 mt-0"
				/>
				{props.element.additional_attachments?.map((choice, index) => (
					<TabPanel
						value={props.statusTab} 
						index={index} 
						key={index}
					>
						<InputLabel 
							className="font-size-medium color-primary font-weight-black"
						>
							Showed when:
						</InputLabel>
						<ul>
							{choice.context?.map((selected) => (
								<li key={selected.id}>
									<TextView>
										{selected.label}
									</TextView>
								</li>
							))}
						</ul>

						<QuillContainer
							key={index}
							value={ choice.description } 
						/>
					</TabPanel>
				))}
			</>) : (
				//todo: selected choice variation (change index number or create multiple)  
				<>
					<QuillContainer value={ props.element?.additional_attachments[0]?.description } />
					{
						(!isEmpty(props.email) && props.element.context.text_mailable == 1) ? (
							<div className="d-flex justify-content-center align-items-center">
								<BaseButton	onClick={() => props.emailTextToUser()}> Email This To Me </BaseButton>
							</div>
						) : null
					}
				</>
			)
		)}
		{
			(
				!isNotStudent(props?.userCapabilities?.role) && 
				props.element.last_element &&
				renderNext
			) && (
				<div className="d-flex ms-2">
					<BaseButton 
						customclasses="ms-auto"
						onClick={() => {
							submitStudentResponse();
						}}
					>
						Next Element
					</BaseButton>
				</div>
			)
		}
	</>)
}

const TextElement = ({ setAI, setUserDetails, ...props }) => {
	const [selectTabs, setSelectTabs] = useState([]);
	const [statusTab, setStatusTab] = useState(0);
	const [elementText, setElementText] = useState(props.element.description)
	const location = useLocation();
  	const isQuickAccess = isPageQuickAccess(location);

	useEffect(() => {
		if(!isEmpty(props.element && props.element.context?.text_type == "text_variation")){
			setSelectTabs(elementToTab())
		}
	}, [props.element])

	useEffect(() => {
		if(!isEmpty(props.quickAccessAI)){
			setElementText(props.quickAccessAI)
		}
	}, [props.quickAccessAI])

	const elementToTab = () => {
		let tabArr = []
		
		tabArr = props.element.additional_attachments?.map((_, index) => (
			{
				value: index,
				label: index == 0 ?  "Main Text" : `Variation ${index}`
			}
		))

		return tabArr
	}

	const updateAddInfo = (additionalInfo) => {
		let params = {
			"party_id": props.userDetails?.party_id,
			"additional_info" : additionalInfo
		}
		
		updateAdditionalInfo(params).then((response) => {
			if (response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				displayToast("success", response.data.init[0]["message"])

				getUserByToken().then((response) => {
					if (response.data.init[0].status === 'error') {
						displayToast("error", response.data.init[0]["message"]);
					}
					else {
						const parsedUserDetail = {
							...response.data.data[0],
							user_img: response.data.data[0].image_file,
							additional_info: JSON.parse(response.data.data[0].additional_info)
						};
						
						setUserDetails(parsedUserDetail)
					}
				})
			}
		})
	}

	const emailTextToUser = (textAI = "") => {
		let params = {
			name : props.name,
			text: !isEmpty(textAI) ? textAI : props.element.description,
			email: props.email,
			title : props.element?.title,
			footer: isEmpty(props.element?.context?.ai_email_footer) ? DEFAULT_EMAIL_FOOTER : props.element.context.ai_email_footer,
		}

		if(props?.quickAccess){
			quickTextElementToEmail(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "Email has been sent.  Please check your email.");
				}
			});
		}
		else {
			textElementToEmail(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "Email has been sent please check your email");
				}
			});
		}
	}
	
	return (<>
		{
			(isQuickAccess) ? (
				<ContentText 
					element={props.element}
					email={props.email}
					emailTextToUser={emailTextToUser}
					setStatusTab={setStatusTab}
					statusTab={statusTab}
					selectTabs={selectTabs}
					isQuickAccess={isQuickAccess}
					elementText={elementText} 
					setElementText={setElementText}
					ai={isQuickAccess ? props.aiQuickAccess : props.ai}
				/>
			) : (
				<LessonAccordion {...props}>{/* TODO: Add lesson object */}
					<ContentText 
						element={props.element}
						email={props.email}
						emailTextToUser={emailTextToUser}
						setStatusTab={setStatusTab}
						statusTab={statusTab}
						selectTabs={selectTabs}
						isQuickAccess={isQuickAccess}
						elementText={elementText} 
						setElementText={setElementText}
						ai={props.ai}
						setAI={setAI}
						userCapabilities={props.currentCapabilities}
						updateProgress={props.updateProgress}
						userDetails={props.userDetails}
						updateAddInfo={updateAddInfo}
					/>
				</LessonAccordion>
			)
		}
	</>)
}

const mapStateToProps = (state) => {
	return {
		ai: state.dashboard?.ai,
		aiQuickAccess: state.quickAccess?.ai,
		currentCapabilities : state.auth?.current_capabilities,
		userDetails : state.auth?.user_details
	}
}

const mapDispatchToProps = {
	setAI,
	setUserDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(TextElement);