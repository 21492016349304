import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';

const StyledTabs = styled((props) => (
	<Tabs
		{...props}
		TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
	/>
))({
	'& .MuiTabs-indicator': {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		maxWidth: '100%',
		width: '100%',
		backgroundColor: 'var(--theme-primary)',
	},
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		padding: '0',
		textTransform: 'none',
		fontWeight: 600,
		fontSize: "var(--font-size-primary)",
		marginRight: theme.spacing(3),
		fontFamily: "Poppins",
		color: 'var(--theme-secondary)',
		'&.Mui-selected': {
			color: 'var(--theme-primary)',
		},
		'& .MuiTab-indicator': {
			top: 0,
		}
	}),
);

const PageTabs = ({ 
		tabs, 
		currentValue, 
		setCurrentValue, 
		customclasses, 
		tabclasses,
		deleteFunction,
		...props 
	}) => {
	return (
		<StyledTabs
			className={`${customclasses ? customclasses : "mb-5"}`}
			value={currentValue}
			onChange={(e, newValue) => {
				setCurrentValue(newValue);
			}}
			variant="scrollable"
			{...props}
		>
			{
				tabs?.map((tab, index) => {
					return (
						<StyledTab
							className={tabclasses}
							key={tab.value}
							value={tab.value}
							label={tab?.label}
							iconPosition="end"
							icon={tab.delete ? (
								<ClearIcon
									className="font-size-medium"
									onClick={(e) => {
										deleteFunction(index, tabs)
									}}
								/>
							) : null}
						/>
					)
				})
			}
		</StyledTabs>
	)
}

export const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && (
				<>
					{children}
				</>
			)}
		</div>
	);
}

export default PageTabs;