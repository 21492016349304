/**
 * TextView Component
 * Renders text with customizable styles using CSS utility classes.
 *
 * Props:
 * - `fontsize` (string, default: "primary"): Sets font size. Options:
 *   - "small" (0.72rem), "secondary" (0.87rem), "xm" (0.92rem), "primary" (1rem),
 *   - "medium" (1.14rem), "large" (1.22rem), "l2" (1.32rem), "l3" (1.42rem),
 *   - "l4" (1.52rem), "l5" (1.62rem), "xl" (1.72rem), "xl2" (1.82rem),
 *   - "xl3" (1.92rem), "xxl" (2.12rem), "xxxl" (3.2rem).
 * 
 * - `fontweight` (string, default: "regular"): Sets font weight. Options:
 *   - "light" (300), "regular" (400), "medium" (500), "semibold" (600),
 *   - "bold" (700), "black" (900).
 * 
 * - `fontcolor` (string, optional): Adds a color class. Options:
 *   - "white", "black", "green", "red", "primary", "secondary", "tertiary",
 *   - "quaternary", "quinary", "senary", "septenary", "octonary", "quick-access".
 * - `dsplay` (string, optional): equivalent to css display option
 * - `customclasses` (string, optional): use to add custom class
*/
const TextView = ({ fontsize = "primary", fontweight = "regular", fontcolor = null, display = "inline-block", customclasses = '', className = '', children, ...props }) => {
	const mergedClassNames = `
		d-${display}
		w-auto
		${"font-size-" + fontsize}
		${"font-weight-" + fontweight}
		${fontcolor && `color-${fontcolor}`}
		${className}
		${customclasses}
	`;

	return (
		<div className={mergedClassNames} { ...props }>
			{ children }
		</div>
	)
}

export const HeaderTitle = ({ ...props }) => {
	let fontSize =  "font-size-primary";

	return (
		<TextView customclasses={`${props.customclasses} me-3 color-quinary ${fontSize}`}>
			{ props.children }
		</TextView>
	)
}

export const ElementTitle = ({ display = "block", ...props }) => {
	return (
		<TextView customclasses="mb-3" fontsize="large" display={display}>
			<b>{props.children}</b>
		</TextView>
	)
}

export default TextView;