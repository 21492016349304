import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import { checkIsActive } from "../helpers/RouterHelper";
import { COMPANY_LOGO_URL, DEFAULT_OLERN_OPTION_ICON, DEFAULT_OLERN_WIDE_IMAGE } from "../../models/Constants";
import TextView from "../partials/Typography";
import { Box, List, Tooltip } from "@mui/material";
import { Image } from "react-bootstrap";
import { useLayoutContext } from "./Layout";
import ActionIconButton from "../partials/ActionIconButton";
import { isEmpty } from "../../helpers/Functions";
import NavigationPrompt from "../../hooks/useNavigationPrompt";

const MainSidebar = ({
	currentCapabilities,
	onValueSelect = () => { },
}) => {
	const [companyLogo, setCompanyLogo] = useState();

	const location = useLocation();
	const navigate = useNavigate();

	const {
		primaryOptions,
		isMainSidebarOpen,
		selectedPrimaryValue,
		setIsMainSidebarOpen,
		setShouldNavigate,
		shouldNavigate,
	} = useLayoutContext();

	useEffect(() => {
		let newLogo = currentCapabilities?.company?.image_file;

		if(isMainSidebarOpen) { // wide logo
			newLogo = currentCapabilities?.company?.settings?.wide_logo;
			newLogo = isEmpty(newLogo) ? DEFAULT_OLERN_WIDE_IMAGE : process.env.REACT_APP_S3_BUCKET + newLogo;
		}
		else { // small logo
			newLogo = isEmpty(newLogo) ? DEFAULT_OLERN_OPTION_ICON : process.env.REACT_APP_S3_BUCKET + newLogo;
		}

		setCompanyLogo(newLogo);
	}, [currentCapabilities, isMainSidebarOpen]);

	const getMenuIsActive = (location, urls, urlExclusions = null) => {
		if (Array.isArray(urls)) {
			for (let url of urls) {
				if (checkIsActive(location, url, urlExclusions)) {
					return "active";
				}
			}
		}
		else {
			return checkIsActive(location, urls, urlExclusions) ? "active" : "";
		}

		return "";
	}

	const handleNavigation = (e, url, value = null) => {
		if(!shouldNavigate) {
			e.preventDefault();

			if(!NavigationPrompt({}, true, setShouldNavigate)) {
				return false;
			}
		}

		if(value) {
			onValueSelect(value);
		}

		// navigate(url);

		return true;
	}

	return (
		<div className={`main-sidebar-fixed ${!isMainSidebarOpen && 'close'}`}>
			<Link
				className="main-sidebar-logo-icon"
				onClick={(e) => {
					handleNavigation(e, "/");
				}}
			>
				<Image src={companyLogo} />
			</Link>
			<div className={`main-sidebar-container ${!isMainSidebarOpen && 'close'}`}>
				<List className="main-sidebar-container-items">
					<Box className={`subsidebar-wide-options-item sidebar-toggle ${!isMainSidebarOpen && 'close'}`}>
						<ActionIconButton
							icon={isMainSidebarOpen ? (
								<KeyboardDoubleArrowLeftIcon className="theme-sub-nav-icon" />
							) : (
								<KeyboardDoubleArrowRightIcon className="theme-sub-nav-icon" />
							)}
							onClick={() => {
								setIsMainSidebarOpen(!isMainSidebarOpen);
							}}
						/>
					</Box>

					{primaryOptions?.map((item, j) => {
						if (item?.hidden) {
							return null;
						}

						let isLink = !isEmpty(item?.url);

						let isActive = isLink ? getMenuIsActive(location, item.url, item?.url_exclusions) : item.value === selectedPrimaryValue;
						let OptionContainer = Box;

						if (item?.url) {
							OptionContainer = Link;
						}

						let option = (
							<OptionContainer
								key={j}
								to={Array.isArray(item?.url) ? item?.url?.[0] : item?.url}
								className={`subsidebar-wide-options-item ${!isMainSidebarOpen && 'close'} ${(isActive) && 'active'}`}
								onClick={(e) => {
									if(item.onClick) {
										item.onClick();
									}

									if(isEmpty(item.navigate) || item.navigate) { // default behavior
										onValueSelect(item?.value);
									}
									else {
										handleNavigation(e, Array.isArray(item?.url) ? item?.url?.[0] : item?.url, item?.value);
									}
								}}
							>
								{item.icon}
								<TextView fontsize="secondary" fontweight="regular" customclasses={`subsidebar-wide-options-item-text ${!isMainSidebarOpen && 'close'}`}>{item.title}</TextView>
							</OptionContainer>
						);

						if (!isMainSidebarOpen) {
							option = (
								<Tooltip
									placement="right"
									title={item.title}
									arrow
									key={j}
								>
									{option}
								</Tooltip>
							);
						}

						return (
							<React.Fragment key={j}>
								{item.line_break_top && <Box className="main-sidebar-divider" />}
								{option}
								{item.line_break && <Box className="main-sidebar-divider" />}
							</React.Fragment>
						);
					})}
				</List>
			</div>
		</div>
	);
}


const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities
	}

	return data;
}

export default connect(mapStateToProps)(MainSidebar);