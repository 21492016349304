import React, { useEffect, useRef, useState } from "react";

import io from "socket.io-client";

import LessonAccordion from "./LessonAccordion";
import TextView, { ElementTitle } from "../../Typography";
import ReflectionResponse from "./ReflectionResponse";
import { Card, Image } from "react-bootstrap";
import { isNotStudent, useLoading } from "../../../../helpers/Utils";
import { connect } from "react-redux";
import { getStudentAttachmentResponse, quickGetStudentAttachmentResponse, quickSubmitElementResponse, submitStudentElementResponse } from "../../../../app/crud/element.crud";
import displayToast from "../../DisplayToast";
import { strDateToTimeElapse, isEmpty, isPageQuickAccess, generateRandomId } from "../../../../helpers/Functions";
import { Box, CardContent } from "@mui/material";
import { DIR_USER_PROFILE } from "../../../../models/Constants";
import {Modal} from 'react-bootstrap';
import BaseButton from "../../BaseButton";
import InitialsImage from "../../InitialsImage";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ActionIconButton from "../../ActionIconButton";
import PushPinIcon from '@mui/icons-material/PushPin';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContextMenu from "../../ContextMenu";
import { useLocation } from "react-router-dom";

const INITIAL_RESPONSE_COUNT = 5;

const ConversationStarterModal = ({...props}) => {
	return (
		<Modal
			size="xl"
			show={props.showModuleModal}
			onHide={() => props.setShowModuleModal(false)}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Student Responses {"(" + props.studentResponses?.length + " responses)"}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ConversationStarter studentResponses={props.studentResponses ?? []} />
			</Modal.Body>
		</Modal>
	)
}

export const ConversationStarterCard = ({ 
	additionalinfo,
	firstname,
	lastname,
	imagefile,
	content,
	createdat,
	withmenu=true,
 }) => {
	const cardBtnOptionRef = useRef();
	const [openMenu, setOpenMenu] = useState(false);

	return (
		<Card className="d-inline-block h-100 w-100 conversation-starter-container position-relative">
			{withmenu && (
				<React.Fragment>
					<Box 
						className="position-absolute" 
						ref={cardBtnOptionRef}
						style={{ right: 0, top: 0 }}
					>
						<ActionIconButton 
							icon={<MoreHorizIcon />}
							customclasses="color-quinary"
							onClick={() => {
								setOpenMenu(true);
							}}
						/>
					</Box>
					<ContextMenu
						anchorEl={cardBtnOptionRef.current}
						open={openMenu}
						onClose={() => setOpenMenu(false)}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<div style={{ padding: '16px', borderRadius: '16px' }}>
							<div className="mb-2">
								<PushPinIcon className="color-secondary" />
								<TextView fontsize="xm" fontweight="regular" customclasses="ms-2">Pin to top</TextView>
							</div>
							<div>
								<VisibilityIcon className="color-secondary" />
								<TextView fontsize="xm" fontweight="regular" customclasses="ms-2">Hide reply</TextView>
							</div>
						</div>
					</ContextMenu>
				</React.Fragment>
			)}
			<CardContent className="d-flex">
				<div className="d-flex mb-1">
					{
						(additionalinfo?.is_bot) ? (
							!isEmpty(additionalinfo.bot_image) ? (
								<Image 
									src={process.env.REACT_APP_S3_BUCKET + "/conversation_starters/images/" + additionalinfo.bot_image}
									roundedCircle
									style={{
										objectFit: "contain",
										width: "3rem",
										height: "3rem",
										marginRight: "1rem"
									}}
								/>
							) : (
								<InitialsImage 
									firstname={firstname}
									lastname={lastname}
								/>
							)
						) : (
							!isEmpty(imagefile) ? (
								<Image 
									src={process.env.REACT_APP_S3_BUCKET + DIR_USER_PROFILE + imagefile}
									roundedCircle
									style={{
										objectFit: "contain",
										width: "3rem",
										height: "3rem",
										marginRight: "1rem"
									}}
								/>
							) : (
								<InitialsImage 
									firstname={firstname}
									lastname={lastname}
								/>
							)
						)
					}
					
				</div>
				<div className="d-flex flex-column">
					<TextView fontsize="primary" fontweight="bold" style={{ lineHeight: '18px', marginBottom: '8px' }}>
						{firstname + " " + lastname?.charAt(0) + "."}
					</TextView>
					<TextView fontsize="xm" fontweight="regular">
						{content}
					</TextView>
					{createdat && (
						<TextView fontsize="small" fontweight="regular" fontcolor="secondary" style={{ lineHeight: '18px' }}>
							{strDateToTimeElapse(new Date(createdat))}
						</TextView>
					)}
				</div>
			</CardContent>
		</Card>
	);
}

const ConversationStarter = ({...props}) => {
	return (
		<div 
			className="d-flex flex-column" 
			style={{ 
				maxHeight:"30vw", 
				overflow: 'auto',
				rowGap: '2rem',
			}}
		>
			{
				props.studentResponses?.map((responses, i) => {
					let additionalInfo = JSON.parse(responses.user.additional_info)

					if(responses?.user?.email != props.userDetails?.email || props.isAdmin){	
						return (
							<ConversationStarterCard 
								key={i}
								additionalinfo={additionalInfo}
								firstname={responses?.user?.firstname}
								lastname={responses?.user?.lastname}
								imagefile={responses?.user?.image_file}
								content={responses?.content}
								createdat={responses?.created_at}
								withmenu={false}
							/>	
						);
					}
				})
			}
		</div>
	)
}

const UserAnswer = ({answer}) => {

	if(isEmpty(answer)) {
		return null;
	}

	return(
		<div className="quiz-container border w-100 p-4">
			<div className="d-flex align-items-center mb-2">
				<TextView fontcolor="green" fontweight="semibold">Your Answer</TextView>
			</div>
			<TextView>
				{answer}
			</TextView>
		</div>
	)
}

const ContentReflection = ({
	isLoading = false,
	isQuickAccess = false,
	isShared = false,
	totalResponse = 0,
	studentResponses = null,
	studentProgress = null,
	responseSubmitted = null,
	answer = null,
	getReflectionResponses = () => {},
	setShowModal = () => {},
	submitStudentResponse = () => {},
	updateProgress = () => {},
	...props
}) => {


	return (<>
		{
			(!isQuickAccess && props.element?.title) && (
				<ElementTitle>
					{ props.element?.title }
				</ElementTitle>
			)
		}
		{
			props.element?.description && (
				<TextView >
					{ props.element?.description }
				</TextView>
			)
		}
		<Box>
			{(!isNotStudent(props?.currentCapabilities?.role) || isQuickAccess) && isEmpty(answer) && (
				<ReflectionResponse 
					isLoading={isLoading}
					hasAI={props.element.context}
					isQuestion="true"
					submitStudentResponse={submitStudentResponse}
				/>
			)}

			{(isNotStudent(props?.currentCapabilities?.role) || (isShared && !isEmpty(answer))) && (
				<Box className="mt-4">
					<UserAnswer answer={answer} />
					<TextView 
						customclasses="mb-4"
						fontweight="bold" 
						fontcolor="quinary"
					>
						Let's see what your peers said:
					</TextView>	
					
					{studentResponses?.length == 0 && (
						<TextView 
							display="block" 
							fontcolor="quinary" 
							customclasses="w-100 text-center"
						>
							No responses from peers yet. Stay tuned!
						</TextView>
					)}

					<ConversationStarter 
						studentResponses={studentResponses}
						userDetails={props.userDetails}
						isAdmin={isNotStudent(props.currentCapabilities.role)}
					/>
				</Box>
			)}
		</Box>
		<div className="mt-2 d-flex justify-content-center align-items-center">
			{
				(	
					totalResponse > INITIAL_RESPONSE_COUNT && (isNotStudent(props.currentCapabilities.role) || (isShared) && responseSubmitted)
				) && (
					<BaseButton 
						variant="text"
						onClick={() => {
							getReflectionResponses(999);
							setShowModal(true);
						}}
					>
						<TextView fontcolor="primary">View All</TextView>
					</BaseButton>
				)
			}
		</div>
		{
			(
				!isQuickAccess && 
				!isNotStudent(props?.currentCapabilities?.role) && 
				props.element.last_element && 
				responseSubmitted
			) && (
				<div className="d-flex ms-2 mt-2">
					<BaseButton 
						customclasses="ms-auto"
						onClick={() => {
							updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, studentProgress);
						}}
					>
						Next Element
					</BaseButton>
				</div>
			)
		}	
	</>);
}

const ReflectionElement = ({ ...props }) => {
	const [studentResponses, setStudentResponses] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [responseSubmitted, setResponseSubmitted] = useState(false);
	const [answer, setAnswer] = useState();
	const [totalResponse, setTotalResponse] = useState(0);
	const {
		isLoading,
		addLoading,
		finishLoading,
	} = useLoading();

	const location = useLocation();
	const isQuickAccess = isPageQuickAccess(location);

	const isShared = props.element?.context?.is_shared == "true";

	const socket = useRef(null);

	const getReflectionResponses = (pageSize = INITIAL_RESPONSE_COUNT) => {
		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			lesson_resource_id : props?.element.lesson_resource_id,
			element_id : props.element.element_id,
			is_mentor : isNotStudent(props.currentCapabilities.role) ? 1 : 0,
			page_size: pageSize,
		};

		if(!isNotStudent(props.currentCapabilities.role)) {
			params.exclude_user_email = props?.userDetails?.email ?? props?.quickAccess?.email;
		}

		let getResponseReq;

		if(isQuickAccess) {
			params.email = props?.quickAccess?.email;
			params.firstname = props?.quickAccess?.name?.firstname;
			params.lastname = props?.quickAccess?.name?.lastname;

			getResponseReq = quickGetStudentAttachmentResponse(props?.element.attachment_id, params);
		}
		else {
			getResponseReq = getStudentAttachmentResponse(props?.element.attachment_id, params);
		}

		getResponseReq.then((response) => {
			if (response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			} 
			else {
				if(!isEmpty(response.data.data)) {
					setStudentResponses(response.data.data)
					setTotalResponse(response.data?.init?.[0]?.pagination?.total ?? 0);
				}
			}
		});
	}

	const [studentProgress, setStudentProgress] = useState(0)

	useEffect(() => { 
		if (!socket.current) {
			socket.current = io(process.env.REACT_APP_WEBSOCKET, {
				withCredentials: true,
				transports: ["websocket", "polling"],
			});
	 
			socket.current.on("connect", () => {
				socket.current.emit("joinReflectRoom", { "element": props.element, "id": generateRandomId(8) });
			});

			socket.current.on("reflectUpdate", (updatedElement) => {
				let pagination = updatedElement?.pagination;
				let resElement = updatedElement?.element;

				if (resElement?.element_id === props.element?.element_id) {
					delete updatedElement?.element;
					delete updatedElement?.pagination;

					let newData = Object.values(updatedElement);

					setStudentResponses(newData)
					setTotalResponse(pagination?.total ?? 0);
				}
			});
		}
	 
		return () => {
			if (socket.current) {
				socket.current.disconnect();
				socket.current = null;
			}
		};
	}, [props.element.element_id])

	const submitStudentResponse = (std_res) => {
		if(isEmpty(std_res?.trim())) {
			displayToast('error', "Please type your insight before submitting.");
			return;
		}

		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			student_response: std_res
		}

		let submitRequest;

		if(isQuickAccess) {
			params.email = props?.quickAccess?.email;
			params.firstname = props?.quickAccess?.name?.firstname;
			params.lastname = props?.quickAccess?.name?.lastname;
		
			submitRequest = quickSubmitElementResponse(params);
		}
		else {
			submitRequest = submitStudentElementResponse(params);
		}

		// to enable in the student view
		if(!isNotStudent(props.currentCapabilities.role) || isQuickAccess) {
			addLoading();

			submitRequest.then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					setStudentProgress(response.data.init[0]?.["library_progress"])
					setResponseSubmitted(true)
					setAnswer(std_res)
					displayToast("success", "Reflection submitted");
				}
			}).finally(() => {
				finishLoading();
			})
		}
		else {
			displayToast("success", "This is just a test, Answer not recorded on admin view")
		}
	}

	useEffect(() => {
		// only retieve peer responses if already submitted or admin
		if(!isEmpty(answer) || (isNotStudent(props.currentCapabilities.role) && !isQuickAccess)) {
			getReflectionResponses();
		}
	}, [answer]);

	useEffect(() => {
		if(!isNotStudent(props.currentCapabilities.role)) {
			const responseAttachments = props?.element?.attachments?.[0]?.student_attachment_response;

			if(!isEmpty(responseAttachments)) {
				setResponseSubmitted(true)
				setAnswer(responseAttachments.content);
			}
		}
		else{
			getReflectionResponses();
		}
	}, [props.element]);


	const reflectionElementComponent = (
		<ContentReflection 
			isLoading={isLoading}
			isQuickAccess={isQuickAccess}
			isShared={isShared}
			totalResponse={totalResponse}
			studentResponses={studentResponses}
			studentProgress={studentProgress}
			responseSubmitted={responseSubmitted}
			answer={answer}
			getReflectionResponses={getReflectionResponses}
			setShowModal={setShowModal}
			submitStudentResponse={submitStudentResponse}
			updateProgress={props?.updateProgress}
			element={props?.element}
			currentCapabilities={props?.currentCapabilities}
			userDetails={props?.userDetails}
		/>
	);

	if(isQuickAccess) {
		return reflectionElementComponent;
	}

	return (<>
		{(isNotStudent(props?.currentCapabilities.role)) && (
			<ConversationStarterModal 
				showModuleModal={showModal}
				setShowModuleModal={setShowModal}
				studentResponses={studentResponses}
			/>
		)}
		<LessonAccordion {...props}>
			{reflectionElementComponent}
		</LessonAccordion>
	</>)
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
		userDetails: state.auth.user_details,
		quickAccess: state.quickAccess,
	}

	return data;
}

export default connect(mapStateToProps)(ReflectionElement);