import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import * as redux from "./app/redux/store";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<App
		store={redux.store}
		persistor={redux.persistor}
	/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
