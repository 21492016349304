import SlowMotionVideoOutlinedIcon from '@mui/icons-material/SlowMotionVideoOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import QuizIcon from '@mui/icons-material/Quiz';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import { Tooltip } from '@mui/material';

import TextView from '../../Typography';

import {
	VIDEO_ELEMENT,
	REFLECTION_ELEMENT,
	SURVEY_ELEMENT,
	LINK_ELEMENT,
	IMAGE_ELEMENT,
	AUDIO_ELEMENT,
	DOWNLOAD_ELEMENT,
	TEXT_ELEMENT,
	DOCUMENT_ELEMENT,
	SIGNATURE_ELEMENT,
 	QUIZ_ELEMENT,
	ACTIONS_STEP_ELEMENT,
	ACTION_SUMMARY_ELEMENT,
	WORD_CLOUD_ELEMENT,
	RATING_ELEMENT,
	QUOTES_ELEMENT,
	CUSTOM_PLAN_ELEMENT,
	INTELLIGUIDE_ELEMENT
} from '../../../../models/Constants';
import { isEmpty } from '../../../../helpers/Functions';

export const ElementTypeIcon = ({ elementtype, ...props }) => {
	// TODO: and signature
	const iconElement = (elementtype) => {
		switch(elementtype) {
			case VIDEO_ELEMENT:
				return <SlowMotionVideoOutlinedIcon {...props} />
			case REFLECTION_ELEMENT:
				return <CommentOutlinedIcon {...props} />
			case SURVEY_ELEMENT:
				return <AssessmentOutlinedIcon {...props} />
			case LINK_ELEMENT:
				return <AddLinkOutlinedIcon {...props} />
			case IMAGE_ELEMENT:
				return <PhotoSizeSelectActualOutlinedIcon {...props} />
			case AUDIO_ELEMENT:
				return <AudioFileOutlinedIcon {...props} />
			case DOWNLOAD_ELEMENT:
				return <CloudDownloadOutlinedIcon {...props} />
			case DOCUMENT_ELEMENT:
				return <PictureAsPdfOutlinedIcon {...props} />
			case QUIZ_ELEMENT:
				return <QuizIcon {...props} />
			case ACTIONS_STEP_ELEMENT:
				return <ChecklistRtlIcon {...props} />
			case WORD_CLOUD_ELEMENT:
				return <SettingsSystemDaydreamIcon {...props} />
			case RATING_ELEMENT:
				return <ThumbsUpDownIcon {...props} />
			case QUOTES_ELEMENT:
				return <FormatQuoteIcon {...props} />
			case CUSTOM_PLAN_ELEMENT:
				return <AssignmentIcon {...props} />
			case INTELLIGUIDE_ELEMENT:
				return <PsychologyAltIcon {...props}/>
			case TEXT_ELEMENT:
			default:
				return <TextSnippetOutlinedIcon {...props} />
		}
	}

	return (
		<Tooltip title={ props.tooltiptext || elementTitle(elementtype) } placement={props.tooltipplace || "top"}>
		  {iconElement(elementtype)}
		</Tooltip>
	);
}

const elementTitle  = (elementtype) => {
	let title = "";
	switch(elementtype) {
		case VIDEO_ELEMENT:
			title = "Video";
			break;
		case REFLECTION_ELEMENT:
			title = "Reflection";
			break;
		case SURVEY_ELEMENT:
			title = "Survey";
			break;
		case LINK_ELEMENT:
			title = "Link";
			break;
		case IMAGE_ELEMENT:
			title = "Image";
			break;
		case AUDIO_ELEMENT:
			title = "Audio";
			break;
		case DOWNLOAD_ELEMENT:
			title = "Download";
			break;
		case DOCUMENT_ELEMENT:
			title = "PDF Viewer";
			break;
		case SIGNATURE_ELEMENT:
			title = "Signature";
			break;
		case QUIZ_ELEMENT:
			title = "Quiz";
			break;
		case ACTIONS_STEP_ELEMENT:
			title = "Action Steps";
			break;
		case ACTION_SUMMARY_ELEMENT:
			title = "Action Summary";
			break;
		case WORD_CLOUD_ELEMENT:
			title = "Word Cloud";
			break;
		case RATING_ELEMENT:
			title = "Ratings";
			break;
		case QUOTES_ELEMENT:
			title = "Quote";
			break;
		case CUSTOM_PLAN_ELEMENT:
			title = "Custom Plan";
			break;
		case INTELLIGUIDE_ELEMENT:
			title = "Intelliguide";
			break;
		case TEXT_ELEMENT:
		default:
			title = "Text";
	}

	return title;
}

const ElementButton = ({ ...props }) => {
	const size = "10rem";

	return (
		<div className={`color-septenary border-0 ${props.customclasses}`} style={{ maxWidth: "100%" }}>
			<ElementTypeIcon
				className={`d-block mx-auto w-100 mb-3 ${props.iconclass}`}
				style={{ fontSize: "4vw" }}
				{...props}
			/>
			{
				(isEmpty(props.title)) ? (
					<TextView customclasses="text-center font-weight-medium w-100" display="block">
						{ elementTitle(props.elementtype) }
					</TextView>
				) : (
					props.title = "none" ? null : props.title
				)
			}
			
		</div>
	)
}

export default ElementButton;