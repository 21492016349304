
const NavigationPrompt = ({ content = "You have unsaved changes. Do you want to leave?" }, shouldPrompt, setShouldNavigate = null) => {
	if (shouldPrompt) {
		const userConfirmed = window.confirm(content);

		if (userConfirmed) {
			if(setShouldNavigate) {
				setShouldNavigate(true);
			}

			return true;
		}
		// If not confirmed, do nothing - this prevents navigation
		return false;
	}
	else {
		if(setShouldNavigate) {
			setShouldNavigate(true);
		}

		return true;
	}
};

export default NavigationPrompt;
