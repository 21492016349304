import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import LessonAccordion from "./LessonAccordion";
import TextView from "../../Typography";
import { isEmpty, isPageQuickAccess, isVideoFile, pluralize } from "../../../../helpers/Functions";
import { Link, useLocation } from "react-router-dom";
import { isNotStudent, useLoading } from "../../../../helpers/Utils";
import { connect } from "react-redux";
import { getCommentTags, getElementComments, submitElementComment, submitStudentElementResponse, toggleCommentReaction } from "../../../../app/crud/element.crud";
import displayToast from "../../DisplayToast";
import BaseButton from "../../BaseButton";
import ReplyIcon from '@mui/icons-material/Reply';
import { DIR_USER_PROFILE, SEARCH_TIMER_DELAY } from "../../../../models/Constants";
import { Card, Image } from "react-bootstrap";
import InitialsImage from "../../InitialsImage";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import BaseTextField from "../../BaseTextField";
import { CardContent, Skeleton } from "@mui/material";

const MAX_VIDEO_RELOAD = 3;
const RETRY_DELAY = 500;

export const CommentCard = ({ 
	firstname,
	lastname,
	imagefile,
	content,
	replytoname,
	createdat,
	withreply = false,
	withshowhidereplies=false,
	showhiderepliesmessage="",
	activelike = false,
	activedislike = false,
	likecount = 0,
	dislikecount = 0,
	onShowReplies = () => {},
	onReply = () => {},
	onLike = () => {},
	onDisLike = () => {},
 }) => {

	return (
		<Card className="d-inline-block h-100 w-100 conversation-starter-container position-relative">
			<CardContent className="d-flex">
				<div className="d-flex mb-1">
					{
						!isEmpty(imagefile) ? (
							<Image 
								src={process.env.REACT_APP_S3_BUCKET + DIR_USER_PROFILE + imagefile}
								roundedCircle
								style={{
									objectFit: "contain",
									width: "3rem",
									height: "3rem",
									marginRight: "1rem"
								}}
							/>
						) : (
							<InitialsImage 
								firstname={firstname}
								lastname={lastname}
							/>
						)
					}
				</div>
				<div className="d-flex flex-column w-100">
					<div className="d-flex">
						<TextView fontsize="primary" fontweight="bold" style={{ lineHeight: '18px', marginBottom: '8px' }}>
							{firstname + " " + lastname?.charAt(0) + "."}
						</TextView>
						{replytoname && (
							<TextView fontsize="small" fontweight="regular" fontcolor="secondary" customclasses="ms-2" style={{ lineHeight: '20px' }}>
								reply to {replytoname}
							</TextView>
						)}
						<div className="d-flex align-items-center ms-auto">
							{
								withreply && (
									<div
										className="me-2 color-secondary cursor-pointer d-flex align-items-center"
										onClick={onReply}
									>
										<ReplyIcon className="color-secondary me-1 mb-1 font-size-medium"/>
										<TextView fontsize="xm">Reply</TextView>
									</div>
								)
							}
							<div className="me-2 color-secondary">
								<ThumbUpAltIcon 
									className={`color-green-hover me-1 mb-1 font-size-medium pointer ${
										(activelike) && "color-green"
									}`}
									onClick={onLike}
								/>
								<TextView>{likecount}</TextView>
							</div>
							<div className="d-flex color-secondary">
								<ThumbDownAltIcon 
									className={`color-red-hover me-1 mb-1 font-size-medium pointer ${
										(activedislike) && "color-red"
									}`}
									onClick={onDisLike}
								/>
								<TextView>{dislikecount}</TextView>
							</div>
						</div>
					</div>
					<TextView fontsize="xm" fontweight="regular">
						{content}
					</TextView>
					<div className="d-flex align-items-center w-100" style={{ height: '26px' }}>
						{withshowhidereplies && (
							<div className="ms-auto">
								<TextView
									fontcolor="secondary"
									customclasses="d-block clickable-text ms-auto"
									onClick={onShowReplies}
								>
									{showhiderepliesmessage}
								</TextView>
							</div>
						)}
					</div>
				</div>
			</CardContent>
		</Card>
	);
}

export const CommentBox = ({reply, commentText, replyDetails, setCommentText, submitElementComment}) => {
	return	(
		<div className="mt-1 pt-k4">
			<div className="d-block w-100 mb-1">
				<TextView className="d-block font-size-medium color-septenary">
					{
						!isEmpty(replyDetails) ? (
							!isEmpty(replyDetails?.reply_to) ? ( 
								`Replying to ${replyDetails?.reply_to.name}`
							) : (
								`Answering the question of ${reply.fname + " " + reply.lname.charAt(0) + "."}`
							)
						) : "Ask a Question"
					}
				</TextView>
			</div>
			<form 
				className="d-flex w-100 align-items-center"
				onSubmit={(e) => {
					e.preventDefault();
					submitElementComment(commentText, replyDetails);
				}}
			>
				<div className="w-100 me-2">
					<BaseTextField
						customclasses="w-100 mb-0"
						value={commentText}
						place
						onChange={(e) => {
							setCommentText(e.target.value)
						}}
					/>
				</div>
				<BaseButton
					customclasses="mb-3"
					customstyles={{ height: 'fit-content' }}
					disabled={isEmpty(commentText)}
					type="submit"
				>
					Submit
				</BaseButton>
			</form>
		</div>	
	);
}

const QandAContainer = ({replyContext, reply, index, showhiderepliesmessage, onShowReplies, ...props}) => {
	const [likeModifier, setLikeModifier] = useState({"like_status" : reply.user_react, "likes" : reply.likes_count, "dislikes" : reply.dislikes_count});

	return (
		<div className="w-100 mb-4" key={index}>
			<CommentCard 
				firstname={reply?.fname}
				lastname={reply?.lname}
				imagefile={reply?.image_file}
				content={reply?.content}
				replytoname={replyContext.reply_to?.name} // replyContext?.reply_to
				createdat={reply?.created_at}
				withshowhidereplies={!isEmpty(reply?.replies)}
				showhiderepliesmessage={showhiderepliesmessage}
				withreply={!isNotStudent(props?.userCapabilities.role) || !isEmpty(props?.quickAccess)}
				likecount={likeModifier.likes}
				dislikecount={likeModifier.dislikes}
				activelike={likeModifier.like_status == "liked"}
				activedislike={likeModifier.like_status == "disliked"}

				onShowReplies={onShowReplies}

				onReply={() => {
					props.setReplyingType("replying")
					if(!isEmpty(replyContext?.reply_to)){
						props.setReplyDetails({
							"parent_id": replyContext?.parent_id,
							"reply_to" : {
								"user_id" : reply.user_id, 
								"name" : reply.fname + " " + reply.lname.charAt(0) + ".",
								"comment_id" : replyContext?.reply_to.comment_id
							}
						})
					}
					else {
						props.setReplyDetails({
							"parent_id": replyContext?.parent_id,
							"reply_to" : {
								"user_id" : reply.user_id, 
								"name" : reply.fname + " " + reply.lname.charAt(0) + ".",
								"comment_id" : reply.id
							}
						})
					}
				}}

				onLike={() => {
					if (isEmpty(props?.quickAccess)){
						if(isNotStudent(props?.role)){
							return
						}
					}
					if((likeModifier.like_status != "liked")){
						props.UpdateCommentLike(reply.id, 1)

						setLikeModifier({
							like_status : "liked",
							likes : Number(likeModifier.likes) + 1,
							dislikes : likeModifier.like_status == "disliked" ? Number(likeModifier.dislikes) - 1 : likeModifier.dislikes,
						})
					}
					else {
						props.UpdateCommentLike(reply.id)

						setLikeModifier({
							like_status : "none",
							likes :  Number(likeModifier.likes) - 1,
							dislikes : Number(likeModifier.dislikes),
						})
					}
				}}

				onDisLike={() => {
					if (isEmpty(props?.quickAccess)){
						if(isNotStudent(props?.role)){
							return
						}
					}

					if((likeModifier.like_status != "disliked")){
						props.UpdateCommentLike(reply.id, 0)

						setLikeModifier({
							like_status : "disliked",
							dislikes :  Number(likeModifier.dislikes) + 1,
							likes : likeModifier.like_status == "liked" ? Number(likeModifier.likes) - 1 : Number(likeModifier.likes),
						})
					}
					else {
						props.UpdateCommentLike(reply.id)

						setLikeModifier({
							like_status : "none",
							dislikes :  Number(likeModifier.dislikes) - 1,
							likes : Number(likeModifier.likes),
						})
					}
				}}
			/>
		</div>
	);
}

const TagsComponent = ({ tags }) => {
	const tagsArray = tags?.split(',')?.map(tag => tag.trim());
	const firstTwoTags = tagsArray?.slice(0, 2);
	const remainingTags = tagsArray?.slice(2);

	return (
		<TextView className="w-25 font-size-xm color-secondary">
			Tags:{' '}
			{firstTwoTags?.map((tag, index) => (
				<span key={index} className="tag-item color-secondary">
					{tag + `${index <= tagsArray.length ? ", " : " "}`}
				</span>
			))}
			{remainingTags?.length > 0 && (
			<span
				className="more-tags color-secondary"
				title={remainingTags.join(', ')}
				style={{ cursor: 'pointer', textDecoration: 'underline' }}
			>
				more
			</span>
			)}
		</TextView>
	);
};

const ContentVideo = ({
		commentText, 
		setCommentText, 
		submitElementComment, 
		replyDetails,
		setReplyDetails,
		replyType,
		setReplyingType,
		...props}) => {
	const [duration, setDuration] = useState(0);
	const [watchTime, setWatchTime] = useState(0);
	const [elementCompleted, setElementCompleted] = useState(false);
	const [showReplyControl, setShowReplyControl] = useState([]);
	const [commentLimit, setCommentLimit] = useState(5);
	const [commentLoading, setCommentLoading] = useState(false)
	const [commentsCount, setCommentsCount] = useState(0);
	const [videoId, setVideoId] = useState(1);
	const [videoLoadingFailed, setVideoLoadingFailed] = useState(false);
	const playerRef = useRef(null);

	const {
		isLoading,
		addLoading,
		finishLoading,
	} = useLoading();

	const handlePause = () => {
		const currentTime = playerRef.current.getCurrentTime();
		setWatchTime(currentTime);
	};

	const handleEnded = () => {
		setWatchTime(duration)
		setElementCompleted(true)
	};

	const handleDuration = (duration) => {
		setDuration(duration);
	};

	useEffect(() => {
		addLoading();
	}, [videoId]);

	useEffect(() => {
		if(!isNotStudent(props?.userCapabilities?.role) && watchTime !== 0){
			submitStudentResponse();
		}
	}, [watchTime])

	useEffect(() => {
		let questionAndAnswerSection = props?.questionAndAnswerSection;

		const countComments = (comments) => {
			if (!Array.isArray(comments)) {
				return 0;
			}
		
			let total = 0;
		
			for (let comment of comments) {
				total += 1; // count the current comment
				total += countComments(comment?.replies); // recursively count replies
			}
		
			return total;
		};

		if(Object.keys(questionAndAnswerSection).length > 0) {
			let totalCommentCount = 0;

			for(let question of Object.values(questionAndAnswerSection)) {
				totalCommentCount += 1;
				totalCommentCount += countComments(Object.values(question?.replies));
			}

			setCommentsCount(totalCommentCount);
		}
		else {
			setCommentsCount(props?.element?.comment_count);
		}
	}, [props?.element?.comment_count, props?.questionAndAnswerSection]);

	const submitStudentResponse = () => {
		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			video_duration: watchTime
		}

		// to enable in the student view
		submitStudentElementResponse(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				if(elementCompleted){
					props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, response.data.init[0]?.["library_progress"]);
				}
			}
		})
	}

	const reloadVideo = () => {
		setVideoId((prevId) => prevId + 1);
	}

	const handleError = (e) => {
		console.error('Error loading video:', videoId, e);

		if(videoId >= MAX_VIDEO_RELOAD) {
			setVideoLoadingFailed(true);
		}
		else {
			setTimeout(() => {
				reloadVideo();
			}, RETRY_DELAY);
		}
	};

	const getElementCommentHandle = (element_id) => {
		setCommentLoading(true)

		let params = {
			"element_id" : element_id,
			"email" : props.email
		}

		getElementComments(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				if(!isEmpty(response?.data?.data)){
					let comments = response.data.data;
					props.setQuestionAndAnswerSection(comments)
				}
				else {
					displayToast("info", "There are no questions.")
				}

				if(!props.showComments){
					props?.setShowComments(true)
				}
					
				setCommentLoading(false)
			}
		});
	}

	const toggleQuestionShowAnswers = (key, status=false) => {
		let commentsCopy = {...props?.questionAndAnswerSection};

		if(!isEmpty(commentsCopy[key])) {
			commentsCopy[key].show_replies = status;
		}

		props.setQuestionAndAnswerSection(commentsCopy);
	}

	const UpdateCommentLike = (comment_id, like = null) => {	
		let params = {
			"element_id" : props.element.element_id,
			"comment_id" : comment_id,
			"like_status" : like ,
			"email" : props.email
		}

		toggleCommentReaction(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {

			}
		});
	}

	useEffect(() => {
		if(!isEmpty(props.questionAndAnswerSection)){
			setShowReplyControl([])	
			let editControl = [...showReplyControl];
		
			if (!isEmpty(props.questionAndAnswerSection)) {
				Object.keys(props.questionAndAnswerSection).forEach(key => {
					let question = props.questionAndAnswerSection[key];
					Object.keys(question.replies || {}).forEach(i => {
						let answer = question.replies[i];
	
						if (answer && answer.id && !editControl.some(rep => rep.id === answer.id)) {
							editControl.push({
								"id": answer.id,
								"show": false,
							});
						}
					});
				});
			}
			
			setShowReplyControl(editControl);
		}
	},[props.questionAndAnswerSection]);

	const [searchValue, setSearchValue] = useState("")
	const [searchDelayTimer, setSearchDelayTimer] = useState();
	const [tagOptions, setTagOptions] = useState([]);

	const handleTagSearch = (value) => {
		setSearchValue(value);
		let newSearchDelayTimer = searchDelayTimer;

		if(Boolean(searchDelayTimer)) {
			clearTimeout(searchDelayTimer);
			newSearchDelayTimer = null;
		}
		
		if(isEmpty(newSearchDelayTimer)) {
			newSearchDelayTimer = setTimeout(() => {
				searchCommentTag(value)
			}, SEARCH_TIMER_DELAY);
		}

		setSearchDelayTimer(newSearchDelayTimer);
	}

	const searchCommentTag = (tagSearch) => {

		getCommentTags(tagSearch).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				if(!isEmpty(response?.data?.data)){
					let tags = []

					response?.data?.data[0]?.forEach((tag) => {
						tags.push({
							value : tag.id, 
							label: tag.name, 
							details: tag.details
						})
					})

					setTagOptions(tags)
				}
			}
		});
	}

	return (<>
		<TextView customclasses="mb-4" onClick={() => reloadVideo()}>
			{ props.element.description }
		</TextView>

		{(isLoading && !videoLoadingFailed) && (
			<Skeleton variant="rounded" width="100%" height={400} />
		)}

		{videoLoadingFailed && (
			<div className="d-flex w-100 align-items-center justify-content-center py-4">
				<TextView fontcolor="quinary">Oops! Something went wrong while loading the video.</TextView>
			</div>
		)}

		{!videoLoadingFailed && (
			<div hidden={isLoading}>
				{
					(props.element?.context?.video_link !== null && isVideoFile(props.element?.context?.video_link)) ? (
						<ReactPlayer
							key={videoId}
							ref={playerRef}
							width="100%"
							height="auto"
							className={"reactPlayer"}
							url={props.element?.context?.video_link}
							controls={true}
							onPause={handlePause}
							onEnded={handleEnded}
							onDuration={handleDuration}
							onError={handleError}
							onReady={() => {
								finishLoading();
							}}
						/>
					)
					: (
						<iframe
							key={videoId}
							src={props.element?.context?.video_link}
							style={{
								width: '100%',
								minHeight: "90vh",
								height: '100%',
								border: '0',
								overflow: 'hidden'
							}}
							onError={handleError}
							onLoad={() => {
								finishLoading();
							}}
						></iframe>
					)
				}
			</div>
		)}

		{
			(props.showTranscript && !isEmpty(props.element?.context?.transcript)) && (
				<div className="p-3 quiz-container my-2">
					<TextView customclasses="font-size-medium" style={{fontFamily: 'var(--bs-body-font-family)'}}>
						{props.element?.context?.transcript}
					</TextView>
				</div>
			)
		}

		{
			(isNotStudent(props?.userCapabilities.role) && isEmpty(props.quickAccess) && !isEmpty(props.element?.context?.transcript)) && (
				<div className="d-flex my-2">
					<Link
						className="ms-auto"
						onClick={() => {
							props?.setShowTranscript(!props?.showTranscript)
						}}
					>
							{props?.showTranscript ? "Hide Transcript" : "Read Transcript"}
					</Link>
				</div>
			)
		}

		{
			(props.showComments && props.allowquestions) && (<>
				<div className="q-and-a-container">
					<TextView className="color-black font-size-large font-weight-medium">
						Here's what your peers asked...
					</TextView>
					{
						(props.showComments && (!isNotStudent(props?.userCapabilities.role) || !isEmpty(props?.quickAccess))) && (
							<div className="d-flex">
								<div className="me-2 d-flex justify-content-center align-items-center">
									{
										(!isEmpty(props.userDetails?.image_file)) ? (
											<Image 
												className="d-block"
												src={process.env.REACT_APP_S3_BUCKET + DIR_USER_PROFILE + props.userDetails?.image_file}
												style={{
													objectFit: "cover",
													height: "5rem",
													width: "5rem",
													borderRadius: "50%", 
													marginRight: "1rem"
												}}
											/>
										) : 
										(
											<InitialsImage 
												style={{
													objectFit: "contain",
													width: "5rem",
													height: "5rem",
													marginRight: "1rem",
													borderRadius: "50%",
													display: "flex",
													alignItems: "center",
													justifyContent: "center", 
													fontSize: "2rem",
													color: "var(--theme-senary) !important",
													backgroundColor: "var(--theme-primary) !important"
												}}
												firstname={isEmpty(props.userDetails) ? props.name.firstname : props.userDetails.firstname}
												lastname={isEmpty(props.userDetails) ? props.name.lastname : props.userDetails.lastname}
												mono={true}
											/>
										)
									}
								</div>
								<div className="me-2 w-100">
									<CommentBox
										reply={props.questionAndAnswerSection[replyDetails?.parent_id]}
										replyDetails={null}
										commentText={props.questionText}
										setCommentText={props.setQuestionText}
										submitElementComment={submitElementComment}
									/>
								</div>
							</div>
						)
					}
					{
						isEmpty(props.questionAndAnswerSection) ? (<>
							<div className="d-flex justify-content-center text-center ms-2 mb-2">
								<TextView className="color-septenary font-size-xm mt-2 ">
									Be the first to ask a question! There are no questions asked right now. Feel free to ask questions.
								</TextView>
							</div>					
						</>) : (
							<div className="d-flex flex-column">
								{
									Object.keys(props.questionAndAnswerSection || {})
										.sort((a, b) => {
											const commentA = props.questionAndAnswerSection[a];
											const commentB = props.questionAndAnswerSection[b];
											return new Date(commentB.created_at) - new Date(commentA.created_at);
										})
										.slice(0, commentLimit)
										.map(key => {
										return (
										<div className="ms-2" key={key}>
											<CommentCard 
												firstname={props.questionAndAnswerSection[key].fname}
												lastname={props.questionAndAnswerSection[key].lname}
												imagefile={props.questionAndAnswerSection[key].image_file}
												content={props.questionAndAnswerSection[key].content}
												createdat={props.questionAndAnswerSection[key].created_at}
												withshowhidereplies={Object.keys(props.questionAndAnswerSection[key].replies || {})?.length > 0}
												showhiderepliesmessage={props.questionAndAnswerSection[key]?.show_replies ? 'Hide Replies' : 'Show More'}
												withreply={!isNotStudent(props?.userCapabilities.role) || !isEmpty(props?.quickAccess)}
												likecount={props.questionAndAnswerSection[key].likes_count}
												dislikecount={props.questionAndAnswerSection[key].dislikes_count}
												activelike={props.questionAndAnswerSection[key].user_react == "liked"}
												activedislike={props.questionAndAnswerSection[key].user_react == "disliked"}

												onShowReplies={() => {
													toggleQuestionShowAnswers(key, !Boolean(props.questionAndAnswerSection[key]?.show_replies));
												}}

												onReply={() => {
													toggleQuestionShowAnswers(key, true);

													setReplyDetails({
														"parent_id" : props.questionAndAnswerSection[key].id
													})
													setReplyingType("answering")
												}}

												onLike={() => {
													if (isEmpty(props?.quickAccess)){
														if(isNotStudent(props?.userCapabilities.role)){
															return
														}
													}

													const newQuestionAndAnswerSection = { ...props.questionAndAnswerSection };
												
													if (newQuestionAndAnswerSection[key].user_react != "liked") {
														newQuestionAndAnswerSection[key] = {
															...newQuestionAndAnswerSection[key],
															user_react: "liked",
															likes_count: Number(newQuestionAndAnswerSection[key].likes_count) + 1,
															dislikes_count: newQuestionAndAnswerSection[key].user_react == "disliked" ? 
																Number(newQuestionAndAnswerSection[key].dislikes_count) - 1 : 
																Number(newQuestionAndAnswerSection[key].dislikes_count),
														};
												
														UpdateCommentLike(newQuestionAndAnswerSection[key].id, 1);
													}
													else {
														newQuestionAndAnswerSection[key] = {
															...newQuestionAndAnswerSection[key],
															user_react: "none",
															likes_count: Number(newQuestionAndAnswerSection[key].likes_count) - 1
														};
												
														UpdateCommentLike(newQuestionAndAnswerSection[key].id);
													}
												
													props.setQuestionAndAnswerSection(newQuestionAndAnswerSection);
												}}

												onDisLike={() => {
													if (isEmpty(props?.quickAccess)){
														if(isNotStudent(props?.userCapabilities.role)){
															return
														}
													}

													const newQuestionAndAnswerSection = { ...props.questionAndAnswerSection };
																											
													if (newQuestionAndAnswerSection[key].user_react != "disliked") {
														newQuestionAndAnswerSection[key] = {
															...newQuestionAndAnswerSection[key],
															user_react: "disliked",
															dislikes_count: Number(newQuestionAndAnswerSection[key].dislikes_count) + 1,
															likes_count: newQuestionAndAnswerSection[key].user_react == "liked" ? 
																Number(newQuestionAndAnswerSection[key].likes_count) - 1 : 
																Number(newQuestionAndAnswerSection[key].likes_count),
														};
												
														UpdateCommentLike(newQuestionAndAnswerSection[key].id, 1);
													}
													else {
														newQuestionAndAnswerSection[key] = {
															...newQuestionAndAnswerSection[key],
															user_react: "none",
															dislikes_count: Number(newQuestionAndAnswerSection[key].dislikes_count) - 1
														};
												
														UpdateCommentLike(newQuestionAndAnswerSection[key].id);
													}
												
													props.setQuestionAndAnswerSection(newQuestionAndAnswerSection);
												}}
											/>
											<div className="d-flex ms-auto justify-content-end">
												{
													(!isEmpty(props.questionAndAnswerSection[key].tags)) && (
														<TagsComponent 
															tags={props.questionAndAnswerSection[key].tags}
														/>
													)
												}
												
											</div>
											{props.questionAndAnswerSection[key]?.show_replies && (
												<React.Fragment>
													<TextView className="color-secondary color-primary font-size-medium my-2">Answers:</TextView>
													<div className={(window.innerWidth >= 600) ? 'ps-5' : 'ps-1'}>
														{
															(
																props.showComments && 
																(!isNotStudent(props?.userCapabilities.role) || !isEmpty(props?.quickAccess)) &&
																(replyType == "answering") && 
																(replyDetails?.parent_id == props.questionAndAnswerSection[key].id)
															) && (<>
																<CommentBox
																	reply={props.questionAndAnswerSection[replyDetails.parent_id]}
																	replyDetails={replyDetails}
																	commentText={commentText}
																	setCommentText={setCommentText}
																	submitElementComment={submitElementComment}
																/>
															</>)
														}
														{Object.keys(props.questionAndAnswerSection[key].replies || {}).map(i => {
															let reply = props.questionAndAnswerSection[key].replies[i]
															let replyContext = JSON.parse(reply.context)
						
															return (<>
																<QandAContainer
																	reply={reply}
																	showhiderepliesmessage={(showReplyControl?.find(rep => rep.id == reply.id)?.show) ? "Hide Replies" : "Show Replies"}
																	replyContext={replyContext}
																	index={i}
																	userCapabilities={props?.userCapabilities}
																	setReplyDetails={setReplyDetails}
																	setReplyingType={setReplyingType}
																	UpdateCommentLike={UpdateCommentLike}
																	role={props?.userCapabilities.role}
																	quickAccess={props?.quickAccess}
																	onShowReplies={() => {
																		const updatedShowReplyControl = showReplyControl.map(rep => 
																			rep.id === reply.id ? { ...rep, show: !rep.show } : rep
																		);
										
																		setShowReplyControl(updatedShowReplyControl);
																	}}
																/>
																{
																	(
																		props.showComments && 
																		(!isNotStudent(props?.userCapabilities.role) || !isEmpty(props?.quickAccess)) &&
																		(replyType == "replying") &&
																		(replyDetails?.reply_to?.comment_id == reply.id)
																	) && (<>
																		<CommentBox
																			reply={props.questionAndAnswerSection[replyDetails.parent_id]}
																			replyDetails={replyDetails}
																			commentText={commentText}
																			setCommentText={setCommentText}
																			submitElementComment={submitElementComment}
																		/>
																	</>)
																}
																{
																	(!isEmpty(reply.replies)) && (
																		<div className="d-block">
																			{
																				showReplyControl?.find(rep => rep.id == reply.id)?.show  && (<>
																					{reply.replies.map((rep, a) => {
																						let repContext = JSON.parse(rep.context)
						
																						return (<>
																							<QandAContainer
																								reply={rep}
																								replyContext={repContext}
																								index={a}
																								userCapabilities={props?.userCapabilities}
																								setReplyDetails={setReplyDetails}
																								setReplyingType={setReplyingType}
																								UpdateCommentLike={UpdateCommentLike}
																								role={props?.userCapabilities.role}
																								quickAccess={props?.quickAccess}
																							/>
																						</>)
																					})}
																				</>) 
																			}
																		</div>
																	)
																}
															</>)
															})}
													</div>
												</React.Fragment>
											)}
										</div>
									)})
								}
							</div>
						)
					}

					{
						(Object.keys(props.questionAndAnswerSection || {}).length > commentLimit) && (
							<div className="d-flex justify-content-center">
								<TextView
									fontcolor="secondary"
									customclasses="d-block clickable-text"
									onClick={() => {
										setCommentLimit(commentLimit + 5)
									}}
								>
									View More Questions
								</TextView>
							</div>
						)
					}
				</div>
			</>)
		}
		{
			props.allowquestions && (
				<div className="d-flex my-2">
					<BaseButton
						customclasses="mx-auto"
						variant="text"
						onClick={() => {
							if(isEmpty(props.questionAndAnswerSection)){
								if(props?.showComments) {
									props?.setShowComments(false)
								}
								else {
									getElementCommentHandle(props.element.element_id)
								}
							}
							else {
								props?.setShowComments(!props?.showComments)
							}
						}}
					>
						{
						commentLoading ? "Loading" : 
							(props?.showComments ? "Hide Questions" : 
								(commentsCount > 0 ? `View ${commentsCount || 0} ${pluralize('Question', props.element?.comment_count)}` : "Ask A Question")
							)
						}
					</BaseButton>
				</div>
			)
		}
		{
			(
				!isNotStudent(props?.userCapabilities?.role) && 
				elementCompleted && 
				props.element.last_element
			) && (
				<div className="d-flex ms-2">
					<BaseButton 
						customclasses="ms-auto"
						onClick={() => {
							submitStudentResponse();
						}}
					>
						Next Element
					</BaseButton>
				</div>
			)
		}
	</>)
}

const VideoElement = ({ ...props }) => {
	const [showTranscript, setShowTranscript] = useState(false)
	const [showComments, setShowComments] = useState(false)
	const location = useLocation();
  	const isQuickAccess = isPageQuickAccess(location);
	const [commentText, setCommentText] = useState("")
	const [questionText, setQuestionText] = useState("")
	const [replyDetails, setReplyDetails] = useState(null)
	const [replyType, setReplyingType] = useState(null)
	const [questionAndAnswerSection, setQuestionAndAnswerSection] = useState({});
	const [questionTags, setQuestionTags] = useState([]);
	
	const handleSubmitElementComment = (comment, replyDetails = null) => {
		let params = {
			content : comment,
			element_id : props.element.element_id,
			email : !isEmpty(props.userDetails) ? props.userDetails.email : props.email,
			context : replyDetails,
			tags: !isEmpty(replyDetails) ? [] : questionTags,
			status : "approved" // todo : to be with moderation
		}

		submitElementComment(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				let commentResponse = response.data.data[0];
				updateCommentsUI(comment, replyDetails, commentResponse, !isEmpty(replyDetails) ? [] : questionTags)
			}
		})
	}

	const updateCommentsUI = (comment, replyDetails, commentResponse, tags) => {
		let senderName = isEmpty(props.userDetails) ? props.name : props.userDetails
	
		if(isEmpty(replyDetails)){
			let idNumber = commentResponse.id;
			let newTags = isEmpty(tags) ? [] : tags.map(item => item.label).join(", ")

			let newEntry = {
				[idNumber]: {
					"id": idNumber,
					"user_id": props.userDetails.user_id,
					"content": comment,
					"context": null,
					"likes_count": 0,
					"dislikes_count": 0,
					"status": "approved",
					"created_at": new Date().toISOString(),
					"fname": senderName.firstname,
					"lname": senderName.lastname,
					"image_file": props.userDetails.user_img,
					"tags" : newTags,
					"replies": {}
				}
			};

			let combinedQandASection = { ...newEntry, ...questionAndAnswerSection };
			let sortedQandASection = Object.fromEntries(
				Object.entries(combinedQandASection).sort(([, a], [, b]) =>
					new Date(b.created_at) - new Date(a.created_at)
				)
			);

			setQuestionAndAnswerSection(sortedQandASection);
			setQuestionText("");
			setQuestionTags([]);
		}
		else {
			let parentId = replyDetails.parent_id;
			let newReplyId = commentResponse.id;

			let newEntry = {
				"id": newReplyId,
				"user_id": props.userDetails.user_id,
				"content": comment,
				"context": JSON.stringify(replyDetails),
				"likes_count": 0,
				"dislikes_count": 0,
				"status": "approved",
				"created_at": new Date().toISOString(),
				"fname": senderName.firstname,
				"lname": senderName.lastname,
				"image_file": props.userDetails.user_img,
				"replies" : []
			};

			let updatedQandASection = { ...questionAndAnswerSection };

			if (isEmpty(replyDetails.reply_to)) {
				updatedQandASection[parentId].replies = {
					...updatedQandASection[parentId].replies,
					[newEntry.id]: newEntry
				};
			}
			else {
				const replyToId = replyDetails.reply_to.comment_id;

				if (updatedQandASection[parentId].replies[replyToId]) {
					updatedQandASection[parentId].replies[replyToId].replies = [
						...updatedQandASection[parentId].replies[replyToId].replies,
						newEntry
					];
				}
			}

			setQuestionAndAnswerSection(updatedQandASection);
			setCommentText("")
			setReplyingType(null)
		}
	}

	return (
		(!isQuickAccess) ? (
			<LessonAccordion {...props}>
				<ContentVideo 
					element={props.element}
					showTranscript={showTranscript}
					setShowTranscript={setShowTranscript}
					userCapabilities={props.currentCapabilities}
					userDetails={props.userDetails}
					updateProgress={props.updateProgress}
					showComments={showComments}
					setShowComments={setShowComments}
					questionAndAnswerSection={questionAndAnswerSection}
					setQuestionAndAnswerSection={setQuestionAndAnswerSection}
					commentText={commentText}
					setCommentText={setCommentText}
					submitElementComment={handleSubmitElementComment}
					questionText={questionText} 
					setQuestionText={setQuestionText}
					replyDetails={replyDetails}
					setReplyDetails={setReplyDetails}
					replyType={replyType}
					setReplyingType={setReplyingType}
					email={props.userDetails.email}
					questionTags={questionTags}
					setQuestionTags={setQuestionTags}
					allowquestions={props.allowquestions}
				/>
			</LessonAccordion>
		) : (
			<ContentVideo 
				element={props.element}
				showTranscript={showTranscript}
				setShowTranscript={setShowTranscript}
				userCapabilities={props.currentCapabilities}
				updateProgress={props.updateProgress}
				name={props.name}
				email={props.email}
				showComments={showComments}
				setShowComments={setShowComments}
				questionAndAnswerSection={questionAndAnswerSection}
				setQuestionAndAnswerSection={setQuestionAndAnswerSection}
				commentText={commentText}
				setCommentText={setCommentText}
				submitElementComment={handleSubmitElementComment}
				questionText={questionText} 
				setQuestionText={setQuestionText}
				replyDetails={replyDetails}
				setReplyDetails={setReplyDetails}
				replyType={replyType}
				setReplyingType={setReplyingType}
				questionTags={questionTags}
				setQuestionTags={setQuestionTags}
				quickAccess={props.quickAccess}
				allowquestions={props.allowquestions}
			/>
		)
	)

	return 
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
		userDetails: state.auth.user_details,
	}

	return data;
}

export default connect(mapStateToProps)(VideoElement);