import { useState } from "react";
import BaseTextField from "../../../../theme/partials/BaseTextField";
import BaseButton from "../../../../theme/partials/BaseButton";

const AssistantChatBox = ({ 
	promptMessage,
	setPromptMessage,
	...props 
}) => {

  return (
	<div className="d-flex bg-white p-1 rounded">
		<BaseTextField 
			customclasses="bg-white w-100 m-0"
			value={promptMessage}
			multiline
			maxRows={3}
			onChange={(e) => {
				setPromptMessage(e.target.value)
			}}
			onKeyDown={(e) => {
				if (e.key === "Enter" && !props.isSubmitting) {
					props.sendMessage();
				}
			}}
		/>
		<BaseButton
			className="assistant-gradient-button ms-2 me-1"
			disabled={props.isSubmitting}
			style={{
				height: "3.5rem",
				width: "15%",
				borderRadius: 15 
			}}
			onClick={()=>{
				props.sendMessage()
			}}
		>
			Send
		</BaseButton>
	</div>
  );
};

export default AssistantChatBox;
