import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { forwardRef } from 'react';

const ButtonStyled = styled(Button)(({ theme }) => ({
	boxShadow: 'none',
	'&.MuiButton-black-text:hover, &.MuiButton-text:hover, &.MuiButton-green-text:hover, &.MuiButton-red-text:hover': {
		backgroundColor: 'transparent',
		transform: 'scale(1.15)',
	},
}));

const BaseButton = forwardRef(({ customclasses = "", className = "", ...props }, ref) => {
	let variant = "";
	let style = "";
	let disabled = (props.disabled || props.issubmitting) ? "disabled" : "";

	switch(props.variant) {
		case "outlined":
			variant = "outlined";
			style = "font-size-xm color-primary bg-white outline-primary"
			break;
		case "abort":
			variant = "outlined";
			style = "font-size-xm color-secondary bg-white outline-secondary"
			break;
		case "undo":
			variant = "outlined";
			style = "font-size-xm color-black bg-senary"
			break;
		case "small":
			variant = "outlined";
			style = "font-size-xm theme-base-button-sm bg-senary";
			break;
		case "medium":
			variant = "outlined";
			style = "font-size-xm theme-base-button-md color-white bg-primary";
			break;
		case "primary":
			variant = "contained";
			style = "bg-primary color-white font-size-xm";
			break;
		case "red":
		case "green":
			variant = "contained";
			style = "bg-"+ props.variant +" color-white font-size-xm";
			break;
		case "text":
			variant = "text";
			style = "color-primary color-primary-hover";
			break;
		case "black-text":
			variant = "text";
			style = "color-black";
			break;
		case "green-text":
			variant = "text";
			style = "color-green";
			break;
		case "red-text":
			variant = "text";
			style = "color-red";
			break;
		default:
			variant = "contained";
			style = "bg-primary font-size-xm";
	}

	const mergedClassNames = `
		theme-base-button
		font-primary
		${style}
		${disabled}
		${customclasses}
		${className}
	`;

	return (
		<ButtonStyled
			ref={ref}
			style={{textTransform: 'none', ...props.customstyles }}
			className={mergedClassNames}
			variant={variant}
			disabled={ (props.disabled) ? true : props.issubmitting }
			{...props}
		>
			{
				props.issubmitting && (
					<CircularProgress
						className="me-2"
						sx={{
							color: "white"
						}}
						size={20}
						thickness={7}
					/>
				)
			}
			{ props.children }
		</ButtonStyled>
	)
})

export default BaseButton;