import React, { useState } from "react";
import BaseTextField from "../../BaseTextField";
import BaseButton from "../../BaseButton";
import TextView from "../../Typography";
import PostProfile from "../../PostProfile";
import Divider from "../../Divider";
import { LikeButton, DislikeButton, ReplyButton, HideReplies } from "../../ReactionButton";
import { aiChatCompletion } from "../../../../app/crud/ai.crud";
import displayToast from "../../DisplayToast";

const ReflectionResponse = ({isLoading = false, ...props}) => {
	const [aiResponse, setAIResponse] = useState("")
	const [studentResponse, setStudentResponse] = useState("")
	const [testAI, setTestAI] = useState(false)

	const AITestResponse = () => {
		if(studentResponse == "") {
			displayToast("error", "Enter your initial response first");
			return
		}

		let params = {
			string : studentResponse,
			prompt : props.hasAI?.ai_prompt
		}
		
		setTestAI(true)
		aiChatCompletion(params).then((response) => {
			if (response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
				setTestAI(false)
			}
			else {
				setAIResponse(response.data.data[0].content)
				setTestAI(false)
			}
		});
	}

	return (
		<div className={`reflection-response-container`} >
			<div className={`reflection-response ${props.isQuestion ? "flex-fill flex-column" : "flex-row"}`}>
				{
					props.isQuestion ? (
						<>
							<BaseTextField
								multiline
								minRows={3}
								maxRows={10}
								customclasses="mb-3"
								placeholder="Type your insight here." 
								value={studentResponse}
								onChange={(e) => {
									setStudentResponse(e.target.value)
								}}
							/>
							{ aiResponse != "" && (
								<BaseTextField
									titleclass="font-size-large color-septenary"
									title="Response"
									disabled
									multiline
									maxRows={8}
									value={aiResponse}
								/>
							)}
							<div className="d-flex justify-content-end">
								{ props.hasAI.ai_customized != 0 && (
										<BaseButton
											customclasses="me-2"
											issubmitting={testAI}
											onClick={() => {
												AITestResponse()
											}}
										>
											Test
										</BaseButton>
								)}
								<BaseButton
									issubmitting={isLoading}
									onClick={() => {
										props.submitStudentResponse(studentResponse)
									}}
								>
									Submit
								</BaseButton>
							</div>
						</>
					)
					: (
						<div className="ps-3 pe-3">
							<PostProfile />
							<TextView customclasses="mb-3" fontsize="medium" display="block">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare rutrum amet, a nunc mi lacinia in iaculis. Pharetra ut integer nibh urna. Placerat ut adipiscing nulla lectus vulputate massa, scelerisque. Netus nisl nulla placerat dignissim ipsum arcu.
							</TextView>
							<Divider />
							<div className="d-flex flex-row py-3">
								<div className="d-inline-flex flex-grow-1">
									<LikeButton customclasses="me-4" text="20" />
									<DislikeButton text="3" />
								</div>
								<div className="d-inline-flex flex-grow-1 justify-content-end">
									<HideReplies customclasses="me-4" text="2" />
									<ReplyButton />
								</div>
							</div>
						</div>
					)
				}
			</div>
		</div>
	)
}

export default ReflectionResponse;