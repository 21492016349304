export function getCurrentUrl(location) {
	return location.pathname.split(/[?#]/)[0];
}

export function checkIsActive(location, url, urlExclusions = null) {
	const current = getCurrentUrl(location);
	
	if (!current || !url) {
		return false;
	}

	if(urlExclusions) {
		for(let exclusion of urlExclusions) {
			const pattern = new RegExp("^" + exclusion.replace(/\*/g, ".*") + "$");
			if (pattern.test(current)) {
				return false;
			}
		}
	}

	// Check if current URL starts with the base URL
	if (current.startsWith(url)) {
		return true;
	}

	// Handle sub-route matching (for backward compatibility)
	const parts = current.split("/");
	const subRoute = parts[2];
	if (url === subRoute) {
		return true;
	}

	return false;
}