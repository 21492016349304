import React, { useEffect, useRef, useState, useContext } from "react";

import LessonAccordion from "./LessonAccordion";
import TextView from "../../Typography";
import { useLocation } from "react-router-dom";
import { isEmpty, isPageQuickAccess } from "../../../../helpers/Functions";
import { connect } from "react-redux";
import { quickSubmitElementResponse, submitStudentElementResponse } from "../../../../app/crud/element.crud";
import displayToast from "../../DisplayToast";
import { isNotStudent } from "../../../../helpers/Utils";
import BaseButton from "../../BaseButton";
import AssistantChatBox from "../../../../app/dashboard/libraries/library-assistant/AssistantChatBox";
import { aiSendMessageToThread, checkRunStatus, getAiAssistantInfo, quickAISendMessageToThread } from "../../../../app/crud/ai.crud";
import { DEFAULT_LIBRARY_IMAGE, DIR_LIBRARY_IMAGE } from "../../../../models/Constants";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Avatar from "../../../layout/partials/Avatar";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { UserContext } from "../../../../contexts/contexts";

const MessageBubble = ({ messageThread, lastMessageRef, ...props }) => {

	const [displayTexts, setDisplayTexts] = useState([]);

	useEffect(() => {
		const intervals = [];
		
		messageThread.forEach((message, index) => {
			if (message.new_message && index == (messageThread.length - 1)) {

				let i = 0;
				const interval = setInterval(() => {
					props.anchorLastMessage("end")
					setDisplayTexts((prev) => {
						const newTexts = [...prev];

						if (i < message.content.length) {
							newTexts[index] = (newTexts[index] || "") + message.content[i];
							i++;
						} else {
							clearInterval(interval); 
						}

						return newTexts;
					});
				}, Math.floor(Math.random() * ((50 - 20) * 10 + 1)) / 10 + 20);
				intervals.push(interval);
			}
			else{
				setDisplayTexts((prev) => [...prev, ""]);
			}
		});

		return () => intervals.forEach(clearInterval);
	}, [messageThread]);

	return messageThread.map((message, index) => {
		const isLastMessage = index === messageThread.length - 1;

		return (<>
			<TextView className={`d-flex ${message.role === "user" ? "justify-content-end pe-1" : "justify-content-start ps-1"} w-100`}>
				{message.role !== "user" && "Intelliguide"}
			</TextView>
			<div
				key={index}
				ref={isLastMessage ? lastMessageRef : null}
				className={`d-flex ${message.role === "user" ? "justify-content-end" : "justify-content-start"} align-items-center mb-3`}
			>
				{(message.role !== "user" && !props.quickAccess)&& (
					<div className="d-flex align-items-center py-3 h-100">
						<Avatar
							lib_img={
								isEmpty(props.image)
									? DEFAULT_LIBRARY_IMAGE
									: process.env.REACT_APP_S3_BUCKET + DIR_LIBRARY_IMAGE + props.image
							}
							customclasses="me-3"
						/>
					</div>
				)}

				<div className={`bubble-chat-container my-2 ${message.role === "user" ? "user-chat-container" : "ai-chat-container"}`}>
					<ReactMarkdown remarkPlugins={[remarkGfm]}>
						{message.new_message ? displayTexts[index] : message.content}
					</ReactMarkdown>
				</div>

				{(message.role === "user" && !props.quickAccess) && (
					<div className="d-flex align-items-center py-3 h-100">
						<Avatar userdetail={props.user_details} customclasses="ms-3" />
					</div>
				)}
			</div>
		</>);
	});
};

const IntelliguideContent = ({
	user_details,
	messages,
	isSubmitting,
	sendMessageToAssistant,
	...props 
}) => {
	const chatContainerRef = useRef(null);
	const lastMessageRef = useRef(null);

	const anchorLastMessage = (block = "start") => {
		if (lastMessageRef.current) {
			lastMessageRef.current.scrollIntoView({ block: block, behavior: "smooth" });
		}
	};

	useEffect(() => {
		anchorLastMessage();
	}, [messages]);


	return(<>
		<TextView className="mb-2 font-weight-medium font-size-large">
			{ props.element?.description }
		</TextView>
		<div className="space-y-2 p-4 border rounded-lg w-80">
			{
				isEmpty(messages) && (
					<div className="d-flex flex-wrap gap-3 p-4 justify-content-center">
						{
							props?.convoStarter?.map((text, index) => (
								!isEmpty(text) && (
									<Card 
										key={index} 
										className="p-4 w-100 w-md-50 w-lg-25 text-center shadow rounded cursor-pointer"
										onClick={() => {
											sendMessageToAssistant(text);
										}}
									>
										<CardContent>
											<Typography variant="body1" color="textSecondary">{text}</Typography>
										</CardContent>
									</Card>
								)
							))
						}
					</div>
				)
			}
			<div
				ref={chatContainerRef} 
				className="element-chat-container mx-1 d-flex flex-column mb-3"
			>
				<MessageBubble 
					messageThread={messages}
					user_details={user_details}
					quickAccess={props.quickAccess}
					lastMessageRef={lastMessageRef}
					anchorLastMessage={anchorLastMessage}
				/>
			</div>
			
			<AssistantChatBox 
				sendMessage={sendMessageToAssistant}
				isSubmitting={isSubmitting}
				promptMessage={props.promptMessage}
				setPromptMessage={props.setPromptMessage}
			/>
		</div>

		{
			(!isNotStudent(props?.userCapabilities?.role) && props.element.last_element) && (
				<div className="d-flex ms-2 mt-2">
					<BaseButton 
						customclasses="ms-auto"
						onClick={() => {
							props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, props.studentProgress);
						}}
					>
						Next Element
					</BaseButton>
				</div>
			)
		}
	</>)
}

const IntelliguideElement = ({ element, ...props }) => {
	const location = useLocation();
	const isQuickAccess = isPageQuickAccess(location);
	const { currentCapabilities, userDetails, quickAccess } = useContext(UserContext) || {};

	const [studentProgress, setStudentProgress] = useState(0);
	const [promptMessage, setPromptMessage] = useState("");
	const [messageThread, setMessageThread] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [threadDetails, setThreadDetails] = useState({});
	const [assistantDetails, setAssistantDetails] = useState({});

	useEffect(() => {
		if (!isEmpty(element)) {
			const contextData = element?.context?.intelliguide_context;
	   		const parsedData = typeof contextData === "string" ? JSON.parse(contextData) : contextData;
			setAssistantDetails(parsedData);
			if (!isEmpty(element?.thread_details)) {
				setThreadDetails(element?.thread_details);
				if (!isEmpty(element?.thread_details?.conversation)) {
					let convo = JSON.parse(element?.thread_details?.conversation);
					let indexedMessages = Object.values(convo)
						.sort((a, b) => a.created_at - b.created_at)
						.map((message, index) => ({
							index,
							...message,
							new_message: false
						}));

					setMessageThread(indexedMessages);
				}
			}
		}
	}, [element]);

	const submitStudentResponse = (thread_id) => {
		let params = {
			library_party_id: element.library_party_id,
			module_resource_id: element?.module_resource_id,
			element_id: element.element_id,
			lesson_resource_id: element?.lesson_resource_id,
			element_attachment_id: element.attachment_id,
			student_response: thread_id
		}

		if(isQuickAccess && !isEmpty(quickAccess)){
			params.email = quickAccess.email;
			params.firstname = quickAccess.name.firstname;
			params.lastname = quickAccess.name.lastname;

			quickSubmitElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "success")
				}
			})
		}
		else if(!isNotStudent(currentCapabilities.role)) {
			submitStudentElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "success")
				}
			})
		}
	}

	const checkLastRun = (thread_id, run_id, threadEdit) => {
		setPromptMessage("");
		setMessageThread(threadEdit);

		let params = {
			run_id: run_id,
			thread_id: thread_id,
		};

		checkRunStatus(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				if (response.data.init[0].status === "busy") {
					let threadRes = response.data?.data;
					checkLastRun(threadRes["thread_id"][0], threadRes["id"][0], threadEdit);
					return;
				}

				let responseChat = JSON.parse(response.data.data[0].conversation || "{}");
				if (!responseChat || Object.keys(responseChat).length === 0) {
					console.error("Invalid response format");
					return;
				}

				const lastMessage = Object.values(responseChat).reduce((latest, current) =>
					new Date(current.created_at) > new Date(latest.created_at) ? current : latest
				);

				let responseThreadEdit = [
					...threadEdit.slice(0, -1), // Remove placeholder message
					{ role: "assistant", content: lastMessage.content, new_message: true }
				];

				setMessageThread(responseThreadEdit);

				if (isEmpty(threadDetails)) {
					let threadData = response.data?.data[0];
					setThreadDetails(threadData);
					submitStudentResponse(threadData.thread_id);
				}
			}
		});
	};

	const sendMessageToAssistant = async (message = promptMessage) => {
		let threadEdit = [
			...messageThread,
			{ role: "user", content: message, new_message: false },
			{ role: "assistant", content: "...", new_message: false }
		];

		setPromptMessage("");
		setMessageThread(threadEdit);

		let params = {
			assistant_id: assistantDetails.assistant_id,
			thread_id: threadDetails?.thread_id || "",
			message: message
		};

		if (isQuickAccess) {
			params.email = quickAccess.email;
		}

		setIsSubmitting(true);

		try {
			const apiCall = isQuickAccess ? quickAISendMessageToThread : aiSendMessageToThread;
			let response = await apiCall(params);

			if (response.data.init[0].status === "error") {
				displayToast("error", response.data.init[0]["message"]);
				return;
			}

			if (response.data.init[0].status === "busy") {
				let threadRes = response.data?.data;
				checkLastRun(threadRes["thread_id"][0], threadRes["id"][0], threadEdit);
				return;
			}

			let responseChat = JSON.parse(response.data.data[0].conversation || "{}");
			if (!responseChat || Object.keys(responseChat).length === 0) {
				console.error("Invalid response format");
				return;
			}

			const lastMessage = Object.values(responseChat).reduce((latest, current) =>
				new Date(current.created_at) > new Date(latest.created_at) ? current : latest
			);

			let responseThreadEdit = [
				...threadEdit.slice(0, -1), // Remove placeholder message
				{ role: "assistant", content: lastMessage.content, new_message: true }
			];

			setMessageThread(responseThreadEdit);

			if (isEmpty(threadDetails)) {
				let threadData = response.data?.data[0];
				setThreadDetails(threadData);
				submitStudentResponse(threadData.thread_id);
			}
		} catch (error) {
			console.error("Error sending message:", error);
			displayToast("error", "An error occurred while sending the message.");
		} finally {
			setIsSubmitting(false);
		}
	};	

	return (
		!isQuickAccess ? (
			<LessonAccordion element={element} {...props}>
				<IntelliguideContent
					element={element}
					userCapabilities={currentCapabilities}
					messages={messageThread}
					sendMessageToAssistant={sendMessageToAssistant}
					promptMessage={promptMessage}
					setPromptMessage={setPromptMessage}
					isSubmitting={isSubmitting}
					user_details={userDetails}
					studentProgress={studentProgress}
					quickAccess={isQuickAccess}
					convoStarter={isEmpty(assistantDetails?.convo_starters) ? [] : assistantDetails?.convo_starters}
				/>
			</LessonAccordion>
		) : (
			<IntelliguideContent
				element={element}
				userCapabilities={currentCapabilities}
				messages={messageThread}
				sendMessageToAssistant={sendMessageToAssistant}
				promptMessage={promptMessage}
				setPromptMessage={setPromptMessage}
				isSubmitting={isSubmitting}
				user_details={quickAccess.name}
				quickAccess={isQuickAccess}
				convoStarter={isEmpty(assistantDetails?.convo_starters) ? [] : assistantDetails?.convo_starters}
			/>
		)
	);
}


export default IntelliguideElement;