import React, { createContext, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useMatch, useNavigate } from "react-router-dom";
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PsychologyIcon from '@mui/icons-material/Psychology';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import MainNavbar from "./MainNavbar";
import SubSidebar from "./SubSidebar";
import MainSidebar from "./MainSidebar";
import { ToastContainer } from "react-toastify";
import { generateSidebarTheme, isEmpty } from "../../helpers/Functions";
import { connect } from "react-redux";
import { isNotStudent } from "../../helpers/Utils";
import { Drawer } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import "../../assets/content.css";
import 'react-toastify/dist/ReactToastify.css';
import { getUserByToken } from "../../app/crud/person.crud";
import { setUserDetails } from "../../app/redux/slices/authSlice";
import displayToast from "../partials/DisplayToast";
import {
	DEFAULT_PRIMARY_COLOR,
	SIDEBAR_LEARNING_HABITS_HOME_PAGE,
	SIDEBAR_LIBRARY_HOME_PAGE,
	SIDEBAR_MAIN_COMPANIES_PAGE,
	SIDEBAR_MAIN_DASHBOARD_PAGE,
	SIDEBAR_MAIN_IN_PERSON_PAGE,
	SIDEBAR_MAIN_INTELLIGUIDE_PAGE,
	SIDEBAR_MAIN_LEARNING_HABITS_PAGE,
	SIDEBAR_MAIN_ONLINE_COURSES_PAGE,
	SIDEBAR_MAIN_SETTINGS_PAGE,
	SIDEBAR_MAIN_WEBINARS_PAGE,
	THEME_PRIMARY_PROPERTY,
	THEME_SIDEBAR_SECONDARY_PROPERTY,
	THEME_TERTIARY_PROPERTY,
	ROLE_SUPER_ADMIN,
	SIDEBAR_LEARNING_HABITS_LESSONS_PAGE,
	SIDEBAR_LEARNING_HABITS_SAVE_LESSONS_PAGE,
	SIDEBAR_LEARNING_HABITS_SAVE_SETTINGS_PAGE,
	SIDEBAR_LEARNING_HABITS_SAVE_TOPICS_PAGE,
	SIDEBAR_LIBRARY_LESSON_DETAILS_PAGE,
	SIDEBAR_LEARNING_HABITS_TOPICS_PAGE
} from "../../models/Constants";

const layoutContext = createContext();
const MAIN_SIDEBAR_WIDTH_MINIMIZE = 60;
const MAIN_SIDEBAR_WIDTH_OPEN = 200;
const SUB_SIDEBAR_WIDTH_OPEN = 278;

export function useLayoutContext() {
	return useContext(layoutContext);
}

const Layout = ({ 
	currentCapabilities, 
	userCapabilities,
	setUserDetails,
}) => {
	const [primaryOptions, setPrimaryOptions] = useState([]);
	const [subSidebarOptions, setSubSidebarOptions] = useState({});
	const [selectedPrimaryValue, setSelectedPrimaryValue] = useState(primaryOptions?.[0]?.value);
	const [uniqSelectedNavValue, setUniqSelectedNavValue] = useState(); // for catching title events
	const [selectedSubSidebarClusterValue, setSelectedSubSidebarClusterValue] = useState();
	const [selectedSubSidebarItemValue, setSelectedSubSidebarItemValue] = useState();
	const [themeColor, setThemeColor] = useState(DEFAULT_PRIMARY_COLOR);
	const [isSubsidebarLoading, setIsSubsidebarLoading] = useState(false);

	const [isMainSidebarOpen, setIsMainSidebarOpen] = useState(true);
	const [isSubSidebarOpen, setIsSubSidebarOpen] = useState(false);
	const [subSidebarOnClick, setSubSidebarOnClick] = useState(null);
	const [shouldNavigate, setShouldNavigate] = useState(true);

	const [layoutClassName, setLayoutClassName] = useState('');
	const [update, setUpdate] = useState(true);

	// page specific states
	const [libraryTypeID, setLibraryTypeID] = useState(true);

	const location = useLocation();
	const navigate = useNavigate();
	const isInLibraryPage = useMatch('/dashboard/:type/:library_code');
	const isInLearningHabitsPage = useMatch('/dashboard/learning-habits/*');
	const isInAdminPanel = location.pathname.startsWith('/admin-panel');

	const SUB_SIDEBAR_LEARNING_HABITS_OPTIONS = [
		{
			interactive: true,
			value: 0,
			title: "",
			items: [
				{
					value: SIDEBAR_LEARNING_HABITS_SAVE_TOPICS_PAGE,
					title: "Topics",
					disabled: false,
					tooltip: null,
				},
				{
					value: SIDEBAR_LEARNING_HABITS_SAVE_LESSONS_PAGE,
					title: "Lessons",
					disabled: false,
					tooltip: null,
				},
				{
					value: SIDEBAR_LEARNING_HABITS_SAVE_SETTINGS_PAGE,
					title: "Settings",
					disabled: false,
					tooltip: null,
				},
			],
		},
	];

	const SIDEBAR_LEARNING_HABITS_HOME_OPTION = {
		value: SIDEBAR_LEARNING_HABITS_HOME_PAGE,
		title: 'Home',
		disabled: false,
		position: 1,
		icon: (<HomeIcon />),
	};

	const getSidebarLearningHabitsLessonsOption = ({ icon = <SelfImprovementIcon />, title = 'Lessons' }) => ({
		value: SIDEBAR_LEARNING_HABITS_LESSONS_PAGE,
		title,
		url: '/dashboard/learning-habits/lessons',
		disabled: false,
		position: 3,
		icon
	});

	const SIDEBAR_LEARNING_HABITS_SETTINGS_OPTION = {
		value: SIDEBAR_MAIN_LEARNING_HABITS_PAGE,
		icon: (<SelfImprovementIcon className="theme-sub-nav-icon" />),
		title: 'Learning Habit Plan',
		url: ['/dashboard/learning-habits', '/dashboard/learning-habits/*'],
		url_exclusions: ['/dashboard/learning-habits/lessons', '/dashboard/learning-habits/lessons/*'],
		hidden: isInAdminPanel || !isNotStudent(currentCapabilities.role),
		disabled: false,
		position: 2,
		onClick: () => {
			setLearningHabitSideBarOptions();
		}
	};

	const SIDEBAR_LEARNING_HABITS_STUDENT_LESSON_OPTION = {
		value: SIDEBAR_LEARNING_HABITS_LESSONS_PAGE,
		icon: (<SelfImprovementIcon className="theme-sub-nav-icon" />),
		title: 'Learning Habit Plan',
		url: ['/dashboard/learning-habits/lessons', '/dashboard/learning-habits/lessons/*'],
		hidden: isNotStudent(currentCapabilities.role),
		disabled: false,
		position: 2,
		onClick: () => {
			setLearningHabitSideBarOptions();
		}
	};

	const setLearningHabitSideBarOptions = () => {
		const learningHabitLessonOption = getSidebarLearningHabitsLessonsOption({ 
			title: 'Lessons',
			icon: <MenuBookIcon />,
		});

		setPrimaryOptions([
			SIDEBAR_LEARNING_HABITS_HOME_OPTION,
			SIDEBAR_LEARNING_HABITS_SETTINGS_OPTION,
			learningHabitLessonOption,
		]);

		if(isNotStudent(currentCapabilities?.role)) {
			setSelectedPrimaryValue(SIDEBAR_MAIN_LEARNING_HABITS_PAGE);
			setSelectedSubSidebarItemValue(SIDEBAR_MAIN_LEARNING_HABITS_PAGE);
		}
		else {
			setSelectedPrimaryValue(SIDEBAR_LIBRARY_LESSON_DETAILS_PAGE);
		}

		setIsSubSidebarOpen(false);
	}

	useEffect(() => {
		let isOpen = true;
		
		if(isInLibraryPage || isInLearningHabitsPage) {
			isOpen = false;
		}

		setIsMainSidebarOpen(isOpen);
	}, [location]);

	useEffect(() => {
		if (update) {
			getUserByToken().then((response) => {
				if (response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					const parsedUserDetail = {
						...response.data.data[0],
						user_img: response.data.data[0].image_file,
						additional_info: JSON.parse(response.data.data[0].additional_info)
					};
					
					setUserDetails(parsedUserDetail)
				}

				setUpdate(false);
			})
		}
	}, [update]);

	useEffect(() => {
		updateThemeColor(themeColor);
	}, [themeColor]);

	useEffect(() => {
		const newThemeColor = currentCapabilities?.company?.settings?.color ?? DEFAULT_PRIMARY_COLOR;

		setThemeColor(newThemeColor);
	}, [currentCapabilities]);

	useEffect(() => {
		switch(selectedPrimaryValue) {
			case SIDEBAR_LEARNING_HABITS_HOME_PAGE:
			case SIDEBAR_LIBRARY_HOME_PAGE:
				navigate('/');
				break;
		}
	}, [selectedPrimaryValue]);

	const updateThemeColor = (baseColor) => {
		const newThemeColor = generateSidebarTheme(baseColor);
		
		document.documentElement.style.setProperty(THEME_PRIMARY_PROPERTY, newThemeColor.primary);
		document.documentElement.style.setProperty(THEME_SIDEBAR_SECONDARY_PROPERTY, newThemeColor.secondary);
		document.documentElement.style.setProperty(THEME_TERTIARY_PROPERTY, newThemeColor.tertiary);

		requestAnimationFrame(() => {
			getComputedStyle(document.documentElement).getPropertyValue(THEME_PRIMARY_PROPERTY).trim();
		})
	}

	const addPrimaryOption = (option) => {
		setPrimaryOptions((prevPrimaryOptions) => {
			let newIconOptions;

			if(isEmpty(prevPrimaryOptions)) {
				newIconOptions = [option];
			}
			else {
				// Check if option already exists
				const optionExists = prevPrimaryOptions.some(existingOption => 
					existingOption.value === option.value
				);

				if(optionExists) {
					return prevPrimaryOptions;
				}

				newIconOptions = [...prevPrimaryOptions, option];
			}

			newIconOptions.sort((a, b) => a?.position - b?.position);

			return newIconOptions;
		});
	};

	const setSubSidebarOptionsFor = (value, navOption) => {
		setPrimaryOptions((prevPrimaryOptions) => {
			return prevPrimaryOptions.map(option => {
				if(option.value === value) {
					return {
						...option,
						sub_sidebar_options: Array.isArray(navOption) ? [...navOption] : []
					};
				}

				return option;
			});
		});
	}

	const getNavigationOptionsFor = (value) => {
		return primaryOptions?.find((primOpt) => primOpt.value === value)?.sub_sidebar_options;
	}

	const dashboardUrlByRole = () => {
		if (isNotStudent(currentCapabilities.role)) {
			if (currentCapabilities?.is_limited) {
				return "/dashboard/course-sales";
			}

			return "/dashboard/admin-dashboard";
		}
		else {
			return "/dashboard/student-dashboard";
		}
	}

	const libTypes = [
		SIDEBAR_MAIN_ONLINE_COURSES_PAGE,
		SIDEBAR_MAIN_IN_PERSON_PAGE,
		SIDEBAR_MAIN_WEBINARS_PAGE,
		SIDEBAR_MAIN_INTELLIGUIDE_PAGE,
		SIDEBAR_MAIN_LEARNING_HABITS_PAGE
	];

	useEffect(() => {
		setSubSidebarOptions({});
		setIsSubSidebarOpen(false);

		// restrict admin panel for super admin only
		if(isInAdminPanel && currentCapabilities?.role !== ROLE_SUPER_ADMIN) {
			navigate('/');
		}

		const DEFAULT_DASHBOARD_PRIMARY_OPTIONS = [
			{
				value: SIDEBAR_MAIN_DASHBOARD_PAGE,
				title: 'Dashboard',
				icon: (<BusinessIcon className="theme-sub-nav-icon" />),
				url: dashboardUrlByRole(),
				hidden: isInAdminPanel,
				line_break: true,
			},
			{
				value: SIDEBAR_MAIN_ONLINE_COURSES_PAGE,
				title: 'Online Courses',
				icon: (<LocalLibraryOutlinedIcon className="theme-sub-nav-icon" />),
				url: ['/dashboard/trainings'],
				hidden: isInAdminPanel,
			},
			{
				value: SIDEBAR_MAIN_IN_PERSON_PAGE,
				title: 'In-Person Trainings',
				icon: (<PersonPinOutlinedIcon className="theme-sub-nav-icon" />),
				url: '/dashboard/in-person-trainings',
				hidden: isInAdminPanel,
			},
			{
				value: SIDEBAR_MAIN_WEBINARS_PAGE,
				title: 'Webinars',
				icon: (<OndemandVideoIcon className="theme-sub-nav-icon" />),
				url: '/dashboard/webinars',
				hidden: isInAdminPanel,
			},
			{
				value: SIDEBAR_MAIN_INTELLIGUIDE_PAGE,
				title: 'Intelliguide',
				icon: (<PsychologyIcon className="theme-sub-nav-icon" />),
				url: '/dashboard/intelliguide',
				hidden: isInAdminPanel
			},
			SIDEBAR_LEARNING_HABITS_SETTINGS_OPTION,
			SIDEBAR_LEARNING_HABITS_STUDENT_LESSON_OPTION,
			{
				value: SIDEBAR_MAIN_COMPANIES_PAGE,
				title: 'Companies',
				icon: (<BusinessIcon className="theme-sub-nav-icon" />),
				url: '/admin-panel/companies',
				hidden: !isInAdminPanel || !isNotStudent(currentCapabilities.role),
			},
			{
				value: SIDEBAR_MAIN_SETTINGS_PAGE,
				title: 'Settings',
				icon: (<SettingsIcon className="theme-sub-nav-icon" />),
				url: isInAdminPanel ? '/admin-panel/settings' : '/dashboard/settings',
				hidden: !isNotStudent(currentCapabilities.role),
				line_break_top: true,
			},
		];

		if (location.pathname.includes('/dashboard/learning-habits')) {
			setLearningHabitSideBarOptions();
		}
		else {
			setPrimaryOptions(DEFAULT_DASHBOARD_PRIMARY_OPTIONS);
		}

		// Check for /dashboard/:type/:library_code pattern using regex
		const dashboardPattern = /^\/dashboard\/[^/]+\/[^/]+$/;
		if (dashboardPattern.test(location.pathname)) {
			const [, , type, libraryCode] = location.pathname.split('/');
			// todo:
		}
	}, [currentCapabilities, userCapabilities]);

	const drawerWidth = MAIN_SIDEBAR_WIDTH_MINIMIZE + (isSubSidebarOpen ? SUB_SIDEBAR_WIDTH_OPEN : 0) + (isMainSidebarOpen ? MAIN_SIDEBAR_WIDTH_OPEN : 0);

	return (
		<layoutContext.Provider
			value={{
				themeColor,
				layoutClassName,
				primaryOptions,
				subSidebarOptions,
				isMainSidebarOpen,
				isSubSidebarOpen,
				selectedPrimaryValue,
				uniqSelectedNavValue,
				selectedSubSidebarClusterValue,
				selectedSubSidebarItemValue,
				setThemeColor,
				setLayoutClassName,
				setPrimaryOptions,
				setIsMainSidebarOpen,
				setIsSubSidebarOpen,
				setSelectedPrimaryValue,
				setUniqSelectedNavValue,
				setSelectedSubSidebarClusterValue,
				setSelectedSubSidebarItemValue,
				setSubSidebarOptionsFor,
				addPrimaryOption,
				getNavigationOptionsFor,
				libraryTypeID,
				setLibraryTypeID,
				setSubSidebarOnClick,
				subSidebarOnClick,
				setShouldNavigate,
				shouldNavigate,
				SUB_SIDEBAR_LEARNING_HABITS_OPTIONS,
				SIDEBAR_LEARNING_HABITS_SETTINGS_OPTION,
				isSubsidebarLoading,
				setIsSubsidebarLoading
			}}
		>
			<ToastContainer />
			<div className="theme-left-container">
				<Drawer
					variant="permanent"
					open={isSubSidebarOpen}
					sx={{
						width: `${drawerWidth}px`,
						position: 'relative',
						transition: 'width 0.5s ease',
						'& .MuiDrawer-paper': {
							width: `${drawerWidth}px`,
							overflowX: 'hidden',
							transition: 'width 0.5s ease',
							flexDirection: 'row',
						},
						zIndex: 0,
						display: 'flex',
					}}
				>
					<MainSidebar 
						onValueSelect={(primval) => {
							if(libTypes.includes(primval)) {
								setLibraryTypeID(primval);
							}

							if(primval === selectedPrimaryValue && primaryOptions?.find((primOpt) => primOpt.value === primval)?.sub_sidebar_options?.length > 0) {
								setIsSubSidebarOpen(!isSubSidebarOpen);
							}
							else {
								setSelectedPrimaryValue(primval);
							}
						}}
					/>
					<SubSidebar
						onSubsidebarClusterClick={(navval) => {
							setUniqSelectedNavValue(navval);
							setSelectedSubSidebarClusterValue(null);
							setSelectedSubSidebarItemValue(null);
						}}
						onSubsidebarItemClick={(navval, navitemval) => {
							setSelectedSubSidebarClusterValue(navval);
							setSelectedSubSidebarItemValue(navitemval);
							setUniqSelectedNavValue(null);
						}}
					/>
				</Drawer>
			</div>
			<div id="main-panel-container" className="theme-right-container d-none d-md-flex">
				<div className="d-flex flex-column w-100">
					<MainNavbar
						update={update}
						setUpdate={setUpdate}
					/>
					<div className={`d-flex flex-grow-1 pb-0 theme-content ${layoutClassName}`}>
						<Outlet />
					</div>
				</div>
			</div>
		</layoutContext.Provider>
	)
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
		userCapabilities: state.auth.user_capabilities,
		companies: state.adminPanel.companies,
	}

	return data;
}

const mapDispatchToProps = {
	setUserDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);