import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DEFAULT_OLERN_LOGO, DEFAULT_OLERN_TRANSPARENT_LOGO, QUICK_ACCESS_BG } from '../models/Constants';
import { ToastContainer } from 'react-toastify';
import { CircularProgress, Divider, Paper } from '@mui/material';
import BaseButton from '../theme/partials/BaseButton';
import displayToast from '../theme/partials/DisplayToast';
import { approveCustomEmail, getCustomEmailDetails } from './crud/library.crud';
import TextView from '../theme/partials/Typography';
import { isEmpty } from '../helpers/Functions';
import { Image } from 'react-bootstrap';

const CustomEmailApproval = () => {
	const params = useParams();
	
	const [paperStyle, setPaperStyle] = useState({})
	const [customEmail, setCustomEmail] = useState(null)
	const [imageUrl, setImageUrl] = useState(`${process.env.REACT_APP_S3_BUCKET}/email_logo/default_library_image.png`)
	const [imageWidth, setImageWidth] = useState(100)
	const [imageHeight, setImageHeight] = useState(50)
	const currentYear = new Date().getFullYear();

	useEffect(() => {
		const checkScreenWidth = () => {
			if (window.innerWidth >= 1200) {
				setPaperStyle({ px: 3, pt: 3, pb: 3, borderRadius: 5, width : 600 });
			} 
			else {
				setPaperStyle({ px: 2, pt: 2, pb: 3, borderRadius: 5, width : 600 });
			}
		};

		checkScreenWidth();

		window.addEventListener('resize', checkScreenWidth);
		return () => window.removeEventListener('resize', checkScreenWidth);
	}, []);

	useEffect(() => {
		getCustomEmailDetails(params.custom_email_id).then((response) => {
			if (response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0].message)
			}
			else {
				const email = response.data.data[0];
				setCustomEmail(email)

				if (!isEmpty(email?.image_logo)) {
					setImageUrl(`${process.env.REACT_APP_S3_BUCKET}/event_uploads/images/logos/${email?.image_logo}`);
					setImageWidth(450);
					setImageHeight(150)
				}
			}
		})
	}, []);

	const containerStyle = {
		border: '1px solid #e1e1e1',
		width: paperStyle.width - 45,
		margin: '0 auto',
	};

	const innerBorderStyle = {
		border: '1px solid #f1f1f1',
		background: '#f1f1f1',
		minHeight: '70vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	};

	const paddingStyle = {
		padding: '20px 40px 30px 40px',
		flex: 1,
	};
	
	return (<>
		<ToastContainer />
		<div className="qa-wide-container p-3 w-100"
			style={{
				backgroundImage: "url(" + QUICK_ACCESS_BG + ")", 
				backgroundSize: 'cover', 
				backgroundPosition: 'center', 
				backgroundRepeat: 'no-repeat',
			}}
		>
			<div className='px-1 w-100 d-block'>
				<div className='d-flex w-100 justify-content-center align-items-center'>
					<Image
						className={`d-flex m-2`}
						style={{
							backgroundColor: 'transparent',
							width: "15vh",
							objectFit: 'contain'
						}}
						src={DEFAULT_OLERN_TRANSPARENT_LOGO}
						alt="Olern Logo"
					/>
				</div>
				<div className='d-flex w-100 justify-content-center align-items-center'>
					<Paper
						sx={paperStyle}
						className='my-5'
					>
						{
							(isEmpty(customEmail)) ? (
								<center>
									<CircularProgress className="d-block"/>
									<Image
										style={{
											objectFit: "contain",
											width: "150px",
											height: "auto",
										}}
										src="/lernnex-logo.png"
										alt="Lernnex Logo"
									/>
								</center>
							) : (<>
								<div 
									className="d-flex mb-2 pt-2 mb-2 mx-2"
									style={{
									borderBottom: 'solid var(--theme-secondary) 2px',
									}}
								>
									<TextView 
										className='font-size-large color-primary mb-2'
									>
										Email Preview
									</TextView>
								</div>
								<div 
									className="d-flex mb-2 pt-2 mb-2 mx-2"
								>
									<TextView 
										className='font-size-medium color-black mb-2'
									>
										Subject: <div className='color-secondary'>{customEmail?.subject}</div>
									</TextView>
								</div>
								<div>
									<div style={containerStyle}>
									<div style={innerBorderStyle}>
									<div style={paddingStyle}>
									<div style={{ textAlign: 'center', marginBottom: '2rem'}}>
										<img src={imageUrl} height={imageHeight} width={imageWidth} alt="Display" />
									</div>
									<div 
										dangerouslySetInnerHTML={{ 
											__html: customEmail?.body?.replace(/<\/p><br><p>/g, "<\/p><p>") 
										}} 
									/>
									</div>
									
									<div style={{ textAlign: 'center', padding: '10px 0' }}>
										© {currentYear} MobileOptima Inc. All Rights Reserved.
									</div>
									</div>
									</div>
								</div>
							</>)
						}
					</Paper>
				</div>
			</div>
		</div>
	</>);
};

export default CustomEmailApproval;