import { useCallback, useEffect, useState, useRef } from 'react';
import {
	VIDEO_ELEMENT,
	REFLECTION_ELEMENT,
	SURVEY_ELEMENT,
	LINK_ELEMENT,
	IMAGE_ELEMENT,
	AUDIO_ELEMENT,
	DOWNLOAD_ELEMENT,
	TEXT_ELEMENT,
	DOCUMENT_ELEMENT,
	SIGNATURE_ELEMENT,
	ROLE_STUDENT,
	RATING_ELEMENT,
	QUOTES_ELEMENT,
	CUSTOM_PLAN_ELEMENT
} from '../models/Constants';

import { ROLE_ADMIN, ROLE_MENTOR, ROLE_OWNER } from '../models/Constants';
import RatingsElement from '../theme/partials/libraries/lessons/RatingsElement';
import { isEmpty } from './Functions';

export const getRole = (roleID) => {
	switch(roleID) {
		case 1:
			return "Admin";
		case 2:
			return "Student";
		case 3:
			return "Mentor";
		case 4:
			return "Super Admin";
		case 5:
			return "Employee";
		case 6:
			return "Owner";
		default:
			return "Custom";
	}
}

export const getCompanyCapabilities = (userCapabilities, companyID) => {
	let selected;

	for(const obj of userCapabilities) {
		if(obj.company_id === companyID) {
			selected = obj;
			break;
		}
	}

	return selected;
}

export const getElementType = (elementTypeID) => {
	switch(elementTypeID) {
		case VIDEO_ELEMENT:
			return "Video";
		case REFLECTION_ELEMENT:
			return "Reflection";
		case SURVEY_ELEMENT:
			return "Survey";
		case LINK_ELEMENT:
			return "Link";
		case IMAGE_ELEMENT:
			return "Image";
		case AUDIO_ELEMENT:
			return "Audio";
		case DOWNLOAD_ELEMENT:
			return "Download";
		case TEXT_ELEMENT:
			return "Text";
		case DOCUMENT_ELEMENT:
			return "Document";
		case SIGNATURE_ELEMENT:
			return "Signature";
		case RATING_ELEMENT:
			return "Ratings";
		case QUOTES_ELEMENT:
			return "Quote";
		case CUSTOM_PLAN_ELEMENT:
			return "Custom Plan";
	}
}

export const isNotStudent = (role) => {
	if(isEmpty(role)) {
		return false;
	}

	return role != ROLE_STUDENT;
}

export const useLoading = () => {
	const [loadingCount, setLoadingCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(loadingCount > 0);
	}, [loadingCount]);

	const addLoading = () => setLoadingCount((prev) => prev + 1);
	const finishLoading = () => setLoadingCount((prev) => Math.max(0, prev - 1));

	return { 
		isLoading, 
		setIsLoading, 
		loadingCount, 
		addLoading, 
		finishLoading 
	};
};

export const useMultiLoading = () => {
	const [loadingCounts, setLoadingCounts] = useState({});
	const [globalLoading, setGlobalLoading] = useState(false);

	useEffect(() => {
		// Global loading state should be true if any key has a count > 0
		setGlobalLoading(Object.values(loadingCounts).some(count => count > 0));
	}, [loadingCounts]);

	const addLoading = (key = null) => {
		setLoadingCounts((prev) => {
			if (key) {
				return { ...prev, [key]: (prev[key] || 0) + 1 };
			}
			return { ...prev, global: (prev.global || 0) + 1 };
		});
	};

	const finishLoading = (key = 'global', weight = 1) => {
		setLoadingCounts((prev) => {
			if (key) {
				if (weight < 0) { // wipe all loading count
					const updatedState = { ...prev };
					delete updatedState[key];
					return updatedState;
				}

				return { ...prev, [key]: Math.max(0, (prev[key] || 0) - weight) };
			}
		});
	};

	const isLoading = (key = null) => {
		if (key) {
			if(!loadingCounts[key]) {
				return false;
			}

			return (loadingCounts[key] || 0) > 0;
		}
		return globalLoading;
	};

	return {
		isLoading,
		addLoading,
		finishLoading,
		loadingCounts // Useful for debugging if needed
	};
};

export const useForceRender = () => {
	const [, setToggle] = useState(false);

	// Function to force a render
	const forceRender = useCallback(() => {
		setToggle((prev) => !prev);
	}, []);

	return forceRender;
};

export const useFirstMount = () => {
	const isFirstMount = useRef(true);

	useEffect(() => {
		isFirstMount.current = false;
	}, []);

	return isFirstMount.current;
};