import React, { useEffect, useState } from "react";

import LessonAccordion from "./LessonAccordion";
import TextView, { ElementTitle } from "../../Typography";
import { connect } from "react-redux";
import BaseButton from "../../BaseButton";
import BaseRadioButton from "../../BaseRadioButton";
import { IconButton, RadioGroup } from "@mui/material";
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import BaseCheckbox from "../../BaseCheckbox";
import { isEmpty, isPageQuickAccess, pluralize } from "../../../../helpers/Functions";
import { quickSubmitElementResponse, submitStudentElementResponse } from "../../../../app/crud/element.crud";
import { isNotStudent } from "../../../../helpers/Utils";
import displayToast from "../../DisplayToast";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import DropComponent from "../../DropableComponent";
import MenuIcon from '@mui/icons-material/Menu';
import DraggableChoice from "../../DraggableChoice";
import { useLocation } from "react-router-dom";

const AnswerStatus = styled('div')(({ ...props }) => ({
	height: "1.5rem",
	borderRadius: "5px",
	background: props.correct == 1 ? "var(--theme-green-secondary)" : "#FFDCDC",
	color: props.correct == 1 ? "var(--theme-green)" : "#F54747",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	textAlign: "center",
	fontSize: "var(--font-size-small)",
	paddingInline: "10px",
}));

const Timer = styled('div')(({ ...props }) => ({
	width: "5rem",
	height: "1.5rem",
	borderRadius: "5px",
	background: "var(--theme-septenary)" ,
	color: "white",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	textAlign: "center",
	fontSize: "var(--font-size-small)",

	transition: 'background-color 0.5s ease',
  	borderColor: 'var(--theme-green)',
}));

const ExplainPanel = ({...props}) => {
	return(
		<div className="quiz-container border w-100 p-4">
			<div className="d-flex align-items-center mb-2">
				<HelpOutlineIcon fontSize="small" />
				<TextView customclasses="ms-1">Additional Information</TextView>
			</div>
			<TextView>
				{props.description}
			</TextView>
		</div>
	)
}

const ContentQuiz = ({
	isQuickAccess = false,
	answerSent = false,
	answer = null,
	count = null,
	correct = false,
	studentProgress = null,
	shuffled = null,
	checkSorting = () => {},
	setShuffled = () => {},
	checkSingleCorrect = () => {},
	checkMultiCorrect = () => {},
	setCorrect = () => {},
	setAnswer = () => {},
	updateProgress = () => {},
	setAnswerSent = () => {},
	handleMouseEnter = () => {},
	handleMouseLeave = () => {},
	...props
}) => {
	const questionType = props?.element?.context?.question_type;
	const multiAnsChoiceLimit = parseInt(props?.element?.context?.choice_limit) || 1;
	const quizPoints = parseInt(props?.element?.context?.points) || null;

	const formatTime = (timeInSeconds) => {
		const minutes = Math.floor(timeInSeconds / 60);
		const seconds = timeInSeconds % 60;
		return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	};

	return (
		<div className="d-flex flex-column w-100">
			<div className="d-flex">
				{
					(!isQuickAccess && props.element?.title) && (
						<ElementTitle>
							{ props.element?.title }
						</ElementTitle>
					)
				}

				<div className="d-flex ms-auto">
					{(quizPoints && !answerSent) && (
						<TextView 
							customclasses="me-2"
							fontcolor="quinary"
							fontweight="semibold"
						>
							{quizPoints} {pluralize('pt', quizPoints)}
						</TextView>
					)}

					{(props.element.context.show_correct && answerSent) && (
						<AnswerStatus correct={correct}>
							{correct ? "CORRECT" : "INCORRECT"}
						</AnswerStatus>
					)}

					{!answerSent && (
						<Timer className="ms-auto">{formatTime(count)}</Timer>
					)}
				</div>
			</div>
			
			<div 
				className="quiz-container"
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave} 
			>
				<span className="d-flex">
					<TextView customclasses="ms-2 mb-2">
						{ props.element?.description }
						{ questionType == 'multi' && (
							` - Select at least ${multiAnsChoiceLimit} choice(s)`
						) }
					</TextView>
				</span>
				{(questionType == "choice" || questionType == "bool") && (
					!answerSent ? (
						<RadioGroup
							className="ps-3"
							name="customized-radios"
							onChange={(e) => {
								setAnswer(e.target.value)
							}}
						>
							{props?.element?.additional_attachments?.map((choice, index) => {
								return(
									<BaseRadioButton
										key={index}
										customclasses="quiz-selection"
										className="p-0 mx-2 my-2"
										title={`${choice.description}`}
										value={choice?.description}
									/>
								)
							})}
						</RadioGroup>
					) : (
						!props.element.context.show_correct ? (
							<div className="d-flex">
								<TextView fontsize="medium">Your Answer is: </TextView>
								<span className="ms-2 font-size-medium">{answer}</span>
							</div>
						) : (
							<div className="d-block ps-3">
								{
									props?.element?.additional_attachments?.map((choice, index) => {
										return(<>
											<BaseRadioButton
												key={index}
												className="d-block p-0 mx-2 my-2"
												selectcolor={
													(choice.context == "correct") ? "green" 
													: ((answer == choice.description && choice.context == "incorrect") ? "red" 
													: "false")
												} //change the radio selected color
												checkedIcon={
													(answer == choice.description && choice.context == "incorrect") ?
													<CancelIcon fontSize="large" />
													: <CheckCircleIcon fontSize="large" />
												}
												checked={(choice.description == answer)}
												title={
													<span style={{color: `${
														(answer == choice.description && choice.context == "correct") ? "green" 
														: ((answer == choice.description && choice.context == "incorrect") ? "red" 
														: 'gray')
													}`}}>
														{`${choice.description} ${(answer == choice.description && choice.context == "correct") ? '- Correct Answer' : ""}`}
													</span>
												}
												value={choice?.description}
												onClick={(e) => e.preventDefault()}
											/><br/>
										</>)
									})
								}
							</div>
						)
					)
				)}
				{
					questionType == "multi" && (
						!answerSent ? (
							props?.element?.additional_attachments?.map((choice, index) => {
								return(
									<div className="d-block ps-3">
										<BaseCheckbox 
											key={index}
											customclasses="quiz-selection mx-1 font-size-medium"
											className="d-block p-0 mx-1 my-2"
											title={choice.description}
											onChange={(e) => {
												if(e.target.checked) {
													const newAnswer = [...answer, choice.description];
													setAnswer(newAnswer)
												}
												else {
													if(!isEmpty(answer)) {
														const newAnswer = answer?.filter((str) => str !== choice.description);
														setAnswer(newAnswer)
													}
												}
											}}
										/><br/>
									</div>
								)
							})
						) : (
							!props.element.context.show_correct ? (
								<div className="d-block">
									<TextView fontsize="medium">Your Answers are: </TextView>
									<ul>
										{answer?.map((ans) => {
											return(<li><span className="d-block font-size-medium ms-2">{ans}</span></li>)
										})}
									</ul>
								</div>
							) : (
								<div className="d-block">
									{props?.element?.additional_attachments?.map((choice, index) => {
										const answerChoice = answer.find(str => (str?.value ?? str) === choice.description);
										return(<div>
											<BaseCheckbox 
												key={index}
												className="ms-3"
												title={
													<TextView color={(!isEmpty(answerChoice) && choice.context == "correct") ? "green" 
														: ((!isEmpty(answerChoice) && choice.context == "incorrect") ? "red" 
														: 'secondary')}>
														{`${choice.description} ${(!isEmpty(answerChoice) && choice.context == "correct") ? '- Correct Answer' : ""}`}
													</TextView>
												}
												sx={{
													color: 'gray',
													'&.Mui-checked': {
														color: 'green',
													},
												}}
												indeterminateIcon={ 
													<span style={{ color: 'red'}}>
														<CancelIcon />
													</span>
												} 
												indeterminate={(!isEmpty(answerChoice) && choice.context == "incorrect")}
												checked={(!isEmpty(answerChoice) && choice.context == "correct")}
												onClick={(e) => e.preventDefault()}
											/>
										</div>)
									})}
								</div>
							)
						)
					)
				}
				{
					questionType == "sort" && (
						!answerSent ? (
							<DragDropContext
								onDragEnd={(param) => {
									const srcI = param.source.index;
									const desI = param.destination?.index;

									if (!isEmpty(desI)) {
										let newArr = [...shuffled]
										const dragItemContent = newArr.splice(srcI, 1) [0]
										newArr.splice(desI, 0, dragItemContent)

										setShuffled(newArr)
									}
								}}
							>
								<DropComponent droppableId="droppable-1">
									{(provided, _) => (
										<div 
											ref={provided.innerRef} 
											{...provided.droppableProps}
											className="p-2"
										>
											{shuffled?.map(function(row, i) {
												const draggableId = row.context.toString();
												return (
													<DraggableChoice 
														value={row.description}
														dragId={draggableId}
														index={i}
													/>
												);
											})}
											{provided.placeholder}
										</div>
									)}
								</DropComponent>
							</DragDropContext>
						) : (
							!props.element.context.show_correct ? (
								<div className="d-block">
									<TextView fontsize="medium">Your answer is: </TextView>
									<ul>
										{shuffled?.map((ans, i) => {
											return(<li><span className="d-block font-size-medium ms-2">{`${i + 1}. ${ans.description}`}</span></li>)
										})}
									</ul>
								</div>
							) : (
								<div className="d-block">
									{answer?.map((choice, index) => {
										return(<div>
											<BaseCheckbox 
												key={index}
												className="ms-3"
												align="start"
												title={
													<TextView color={choice.context == index ? "green" :  "red"}>
														{`${index + 1}. ${choice.description}`}
													</TextView>
												}
												sx={{
													color: 'gray',
													'&.Mui-checked': {
														color: 'green',
													},
												}}
												indeterminateIcon={ 
													<span style={{ color: 'red'}}>
														<CancelIcon />
													</span>
												} 
												indeterminate={(parseInt(choice.context) != index)}
												checked={(parseInt(choice.context) == index)}
												onClick={(e) => e.preventDefault()}
											/>
										</div>)
									})}
								</div>
							)
						)
					)
				}
			</div>
			{
				(props.element?.context?.explain?.status && answerSent) && (
					<ExplainPanel 
						description={props.element.context.explain.value}
					/>
				)
			}
			{
				(!answerSent || (isNotStudent(props?.currentCapabilities?.role) && !isQuickAccess)) && (
					<div className="d-flex">
						<BaseButton
							customclasses="ms-auto"
							onClick={() => {
								if(!answerSent){
									if(!isEmpty(answer)) {
										if(questionType == "multi") {

											if(answer?.length < multiAnsChoiceLimit) {
												displayToast("error", `Please select at least ${multiAnsChoiceLimit} choice(s).`);
												return;
											}

											setCorrect(checkMultiCorrect(answer))
											setAnswerSent(true)	
										}
										else {
											setCorrect(checkSingleCorrect(answer))
											setAnswerSent(true)
										}
									}
									else {
										if(questionType == "sort") {
											setCorrect(checkSorting(shuffled))
											setAnswerSent(true)
											setAnswer([...shuffled]);
										}
										else {
											displayToast("error", "Please select an answer before submitting.");
										}
									}
								}
								else {
									// Admin View only retry function
									setAnswerSent(false)
									setAnswer([])
								}
								
							}}
							disabled={(isNotStudent(props.currentCapabilities.role)) ? false : answerSent}
						>
							{(answerSent && isNotStudent(props.currentCapabilities.role)) ? "Retry" : "Submit"}
						</BaseButton>
					</div>
				)
			}
		
			{
				(!isNotStudent(props?.currentCapabilities?.role) && props.element.last_element && answerSent) && (
					<div className="d-flex ms-2 mt-2">
						<BaseButton 
							customclasses="ms-auto"
							onClick={() => {
								updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, studentProgress);
							}}
						>
							Next Element
						</BaseButton>
					</div>
				)
			}
		</div>
	)
}

const QuizElement = ({ ...props }) => {
	const [answer, setAnswer] = useState([])
	const [answerSent, setAnswerSent] = useState(false)
	const [correct, setCorrect] = useState(false)

	const location = useLocation();
	const isQuickAccess = isPageQuickAccess(location);

	const checkSingleCorrect = (value) => {
		const matchingChoice = props?.element?.additional_attachments.find(obj => obj.description === value);

		if(matchingChoice.context == "correct") {
			return true
		}
		else {
			return false
		}
	}

	const checkMultiCorrect = (values) => {
		const allCorrect = values.every(value => {
			const matchingChoice = props?.element?.additional_attachments.find(obj => obj.description === value);
			return matchingChoice?.context === 'correct';
		});

		if (allCorrect) {
			return true;
		} else {
			return false;
		}
	}

	const checkSorting = (sortingAnwer=[]) => {
		if (!Array.isArray(sortingAnwer)) {
			return false;
		}

		return sortingAnwer.map((obj, index) => {
			if (obj.hasOwnProperty('context') && obj.context == index) {
				return true;
			}
			else {
				return false;
			}
		}).every(Boolean); // Check if all elements are true
	}

	const [count, setCount] = useState(0);
	const [intervalId, setIntervalId] = useState(null);

	const findAnswerValues = (answer, type) => {
		if(type == 'multi') {
			const matchingObjects = props.element.additional_attachments.filter((item) => answer.includes(item.description));
			let answers = []
			
			if(!isEmpty(matchingObjects)){
				matchingObjects.map((ans, index) => {
					const item = {
						id: ans.id,
						value: ans.description
					}
					
					answers.push(item)
				})
			}

			return answers
		}
		else if(type == "sort"){
			let answers = []
			
			if(!isEmpty(shuffled)){
				shuffled.map((ans, index) => {
					const item = {
						id: ans.id,
						value: ans.description
					}
					
					answers.push(item)
				})
			}

			return answers
		}
		else {
			const matchingObject = props.element.additional_attachments.find((item) => item.description === answer);
			return [
				{
					id: matchingObject.id,
					value: matchingObject.description
				}
			]
		}
	}

	const [studentProgress, setStudentProgress] = useState(0)

	const submitStudentResponse = () => {
		const student_response = {
			question_type : props.element.context.question_type,
			points: (correct ? parseInt(props.element.context.points) : 0),
			time : count, 
			answers: findAnswerValues(answer, props.element.context.question_type)
		}
		
		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			student_response: student_response
		}

		let submitRequest;

		if(isQuickAccess) {
			params.email = props?.quickAccess?.email;
			params.firstname = props?.quickAccess?.name?.firstname;
			params.lastname = props?.quickAccess?.name?.lastname;
		
			submitRequest = quickSubmitElementResponse(params);
		}
		else {
			submitRequest = submitStudentElementResponse(params);
		}
		
		// to enable in the student view
		if(!isNotStudent(props.currentCapabilities.role) || isQuickAccess) {
			submitRequest.then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					setStudentProgress(response.data.init[0]?.["library_progress"])
					displayToast("success", "Quiz answers submitted");
				}
			})
		}
		else {
			displayToast("success", "This is just a test, Answer not recorded on admin view")
		}
	}

	useEffect(() => {
		if(answerSent && isEmpty(props.element?.attachments?.[0].student_attachment_response)){
			setTimeout(() => {
				submitStudentResponse()	
			}, 500);// to give time so set state correct
		}
	}, [answerSent])

	//todo in studentView, just prototype
	const handleMouseEnter = () => {
		if(!answerSent) {
			// Start counting when the mouse enters the div
			const id = setInterval(() => {
				setCount((prevCount) => prevCount + 1);
			}, 1000);

			setIntervalId(id);
		}
	};
	
	const handleMouseLeave = () => {
		// Stop counting when the mouse leaves the div
		clearInterval(intervalId);
	};

	const [shuffled, setShuffled] = useState(shuffleArray(props.element.additional_attachments));

	function shuffleArray(array) {
		const shuffledArray = [...array];
		for (let i = shuffledArray.length - 1; i > 0; i--) {
		  const j = Math.floor(Math.random() * (i + 1));
		  [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
		}
		return shuffledArray;
	}

	useEffect(() => {
		setShuffled(shuffleArray(props.element?.additional_attachments))
	},
	[props.element.additional_attachments])

	useEffect(() => {
		if(!isNotStudent(props.currentCapabilities.role)) {
			const responseAttachments = props?.element?.attachments?.[0]?.student_attachment_response;

			if(!isEmpty(responseAttachments)) {
				const contents = JSON.parse(responseAttachments.content);
				const remarks = JSON.parse(responseAttachments.remarks);

				if(props.element.context.question_type == "choice" || props.element.context.question_type == "bool") {
					setAnswer(remarks[0])
					setCorrect(checkSingleCorrect(remarks[0]))
					setAnswerSent(true)
				}
				else if (props.element.context.question_type == "multi") {
					let ans = contents.map((content, i) => ({
						id: content,
						value: remarks[i]
					}));
					
					setAnswer(ans);
					setCorrect(checkMultiCorrect(remarks))
					setAnswerSent(true)
				}
				else if (props.element.context.question_type == "sort") {
					let attachment = props.element?.attachments?.[0];
					let studentAttachmentResponse = attachment?.student_attachment_response;
					let studentAns = JSON.parse(studentAttachmentResponse?.content);

					/* arrange the attatchments based on student answer */
					let additionalAttatchmentsMap = {}; // map attachment objects by id
					props.element?.additional_attachments?.forEach((attchment) => {
						additionalAttatchmentsMap[attchment?.id] = attchment;
					});

					let newAnswer = studentAns?.map((attId) => additionalAttatchmentsMap?.[attId]);

					setCorrect(checkSorting(newAnswer));
					setAnswerSent(true);
					setAnswer(newAnswer);
				}
			}
		}
	}, [props.element])

	let quizElementComponent = (
		<ContentQuiz 
			isQuickAccess={isQuickAccess}
			answerSent={answerSent}
			answer={answer}
			count={count}
			correct={correct}
			shuffled={shuffled}
			setShuffled={setShuffled}
			checkSorting={checkSorting}
			checkSingleCorrect={checkSingleCorrect}
			checkMultiCorrect={checkMultiCorrect}
			setCorrect={setCorrect}
			setAnswer={setAnswer}
			setAnswerSent={setAnswerSent}
			updateProgress={props?.updateProgress}
			handleMouseEnter={handleMouseEnter}
			handleMouseLeave={handleMouseLeave}
			studentProgress={studentProgress}
			element={props?.element}
			currentCapabilities={props?.currentCapabilities}
		/>
	);

	if(isQuickAccess) {
		return quizElementComponent;
	}

	return (
		<LessonAccordion {...props}>
			{quizElementComponent}
		</LessonAccordion>
	)
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
		quickAccess: state.quickAccess
	}

	return data;
}

export default connect(mapStateToProps)(QuizElement);