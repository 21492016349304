import axios from "axios";
import { DEFAULT_AUTH_HEADER } from "../../models/Constants";
import { objectToUrlQuery } from "../../helpers/Functions";

export function aiChatCompletion(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/openai/create-chat-completion" , params);
}

export function aiQuiz(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/openai/create-ai-quiz" , params);
}

export function quickAiChatCompletion(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/quick-ai-completion/1" , params, DEFAULT_AUTH_HEADER);
}

export function aiSendMessageToThread(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/openai/send-message-thread" , params);
}

export function quickAISendMessageToThread(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/quick-send-message-thread" , params, DEFAULT_AUTH_HEADER);
}

export function getAiAssistantInfo(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/openai/ai-assistant?" + objectToUrlQuery(params));
}

export function updateThreadTitle(params) {
	return axios.patch(process.env.REACT_APP_API_URL + "/openai/ai-thread", params);
}

export function updateAssistantDetails(params) {
    let dataPost = new FormData();

    for (const [key, value] of Object.entries(params)) {
        if (key === "ai_tools" && Array.isArray(value)) {
            dataPost.append(key, JSON.stringify(value));
        }
        else if (value !== null && value !== undefined) {
            if (typeof value === "object") {
                if (value instanceof File) {
                    dataPost.append(key, value, value.name);
                } else {
                    dataPost.append(key, JSON.stringify(value));
                }
            }
            else {
                dataPost.append(key, value);
            }
        }
    }

    return axios.post(`${process.env.REACT_APP_API_URL}/openai/ai-assistant`, dataPost, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export function checkRunStatus(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/quick-check-run-status" , params, DEFAULT_AUTH_HEADER);
}