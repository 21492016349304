import { useEffect } from "react";
import { isEmpty } from "../../helpers/Functions";
import TextView from "../partials/Typography";
import { Box, List, ListItemText, Skeleton, Tooltip } from "@mui/material";
import ActionIconButton from "../partials/ActionIconButton";
import { useLayoutContext } from "./Layout";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BaseButton from "../partials/BaseButton";

const SubSidebar = ({
	onSubsidebarClusterClick = () => { },
	onSubsidebarItemClick = () => { },
}) => {

	const {
		primaryOptions,
		isSubSidebarOpen,
		getNavigationOptionsFor,
		setIsSubSidebarOpen,
		selectedPrimaryValue,
		uniqSelectedNavValue,
		selectedSubSidebarClusterValue,
		selectedSubSidebarItemValue,
		subSidebarOnClick,
		isSubsidebarLoading
	} = useLayoutContext();

	useEffect(() => {
		const hasNavOptions = !isEmpty(primaryOptions?.find((primOpt) => primOpt.value === selectedPrimaryValue)?.sub_sidebar_options);
		if (hasNavOptions && !isSubSidebarOpen) {
			setIsSubSidebarOpen(true);
		}
		else if(!hasNavOptions && isSubSidebarOpen) {
			setIsSubSidebarOpen(false);
		}
	}, [selectedPrimaryValue, primaryOptions]);

	const handleSubSidebarOnClick = () => {
		if(!isEmpty(subSidebarOnClick)) {
			subSidebarOnClick();
		}
		else {
			setIsSubSidebarOpen(!isSubSidebarOpen);
		}
	}

	return (
		<div className={`subsidebar-collapsible ${isSubSidebarOpen ? '' : 'close'}`}>
			<div className="subsidebar-collapsible-header">
				<div className="subsidebar-collapsible-header-title">
					<ActionIconButton
						customclasses=""
						icon={<ArrowBackIcon className="color-white" style={{ width: '18px', marginBottom: '0.5px' }} />}
						onClick={handleSubSidebarOnClick}
					/>
					<TextView fontsize="xm" fontweight="bold">
						{primaryOptions?.find((primOpt) => primOpt.value === selectedPrimaryValue)?.title}
					</TextView>
				</div>
			</div>

			{(isSubSidebarOpen && isSubsidebarLoading) && (
				<>
					<Skeleton width="100%" height="64px"/>
					<Box className="subsidebar-wide-options mb-2" sx={{rowGap: 0}}>
						<Skeleton width="100%" height="45px" />
						<Skeleton width="100%" height="45px" />
						<Skeleton width="100%" height="45px" />
					</Box>
					<Skeleton width="100%" height="64px"/>
					<Box className="subsidebar-wide-options" sx={{rowGap: 0}}>
						<Skeleton width="100%" height="45px" />
						<Skeleton width="100%" height="45px" />
						<Skeleton width="100%" height="45px" />
					</Box>
				</>
			)}

			{!isEmpty(primaryOptions?.find((primOpt) => primOpt.value === selectedPrimaryValue)?.sub_sidebar_options) &&
			primaryOptions?.find((primOpt) => primOpt.value === selectedPrimaryValue)?.sub_sidebar_options?.map((cluster, i) => {
				return (
					<div className="subsidebar-collapsible-clusters" key={i}>
						{cluster?.items && (
							<>
								{
									!isEmpty(cluster.title) && (<>
										<TextView
											fontsize="primary"
											fontweight="semibold"
											className={`subsidebar-collapsible-title ${cluster.interactive && 'interactive'} ${(cluster.value === uniqSelectedNavValue) && 'active'}`}
											onClick={(e) => {
												console.log('Click detected');
												if (!cluster.interactive) {
													return;
												}
												onSubsidebarClusterClick(cluster.value);
											}}
										>
											{cluster.title}
										</TextView>
										<Box className="subsidebar-collapsible-title-divider" />
									</>)
								}
								<List className="subsidebar-wide-options">
									{cluster?.items?.map((item, y) => (
										<Tooltip
											placement="right"
											arrow
											title={item?.tooltip}
											disableHoverListener={!item?.disabled}
										>
											<ListItemText
												key={y}
												className={
													`subsidebar-wide-options-item
													library-details-sidebar
													${(selectedSubSidebarClusterValue === cluster.value && selectedSubSidebarItemValue === item.value) ? 'active' : ''} ${item.disabled && 'disabled'}`
												}
												onClick={() => {
													if (item?.disabled) {
														return;
													}
	
													onSubsidebarItemClick(cluster?.value, item?.value);
	
													if(item?.onClick) {
														item.onClick();
													}
												}}
											>
												<TextView
													fontsize="xm"
													fontweight="regular"
													customclasses={
														`subsidebar-wide-options-item-text
														library-details-sidebar
														${(selectedSubSidebarClusterValue === cluster.value && selectedSubSidebarItemValue === item.value) ? 'active' : ''}`
													}
												>
													{item.title}
												</TextView>
											</ListItemText>
										</Tooltip>
									))}
								</List>
							</>
						)}
					</div>
				)
			})}
		</div>
	);
}

export default SubSidebar;